import { Injectable } from '@angular/core';
import { forkJoin, Observable } from '../../../../node_modules/rxjs';
import { GlobalURL } from '../../globalModule/globalUrl';
import { HttpClient, HttpResponse } from '../../../../node_modules/@angular/common/http';
import { Config } from '../../../../node_modules/protractor';
import { map } from '../../../../node_modules/rxjs/operators';
import { HistoryModel } from './component-detail/historyModel';
import { Replace } from './product-detail/add-edit-component/serviceintervention-view/replace.model';
import { Qualitytag } from './advanced-search/search-result/searchModels/qualitytag.model';

import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/globalModule/shared.service';
import { AuthenticationService } from 'src/app/globalModule/authentication/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class HomeActionService {

  constructor(private http: HttpClient, private sharedSrvc: SharedService, private sharedService: SharedService,
    private authService: AuthenticationService) {
  }

  getConnectionInfo(): Observable<SignalRConnectionInfo> {
    const url = environment.signlarUrl;
    const requestUrl = `${url}`;
    return this.http.get<SignalRConnectionInfo>(requestUrl);
  }

  searchOrderDetailsByCCRPId(ccrpId) {
    const url: string = GlobalURL.GET_SERIALNUMBERS_BASED_ON_CCRPNO + '?ccrpNumber=' + ccrpId;
    return this.http.get<Config>(url, { observe: 'response' });
  }

  searchOrderDetails(articleNumber, supplierGuidOrFactoryId, serialOrBatchNumber, typebool?,
    oldarticle?, oldfactory?, oldserial?, isBN?, productionTreeSeqId?, orderLineId?): Observable<HttpResponse<Config>> {

    let encodeAN = '';
    if (articleNumber !== 'empty' && articleNumber !== '') {
      encodeAN = this.sharedService.encodeData(articleNumber);
    } else {
      encodeAN = 'empty';
    }
    let encodeSN = '';
    if (serialOrBatchNumber) {
      encodeSN = this.sharedService.encodeData(serialOrBatchNumber);
    } else {
      encodeSN = '';
    }

    let encodeSupId = '';
    if (supplierGuidOrFactoryId !== 'empty' && supplierGuidOrFactoryId !== '') {
      encodeSupId = this.sharedService.encodeData(supplierGuidOrFactoryId);
    } else {
      encodeSupId = 'empty';
    }
    let ptSeqId = '';
    if (productionTreeSeqId) {
      ptSeqId = '&Productiontreeseqid=' + productionTreeSeqId;
    }

    const encodeOldSN = this.sharedService.encodeData(oldserial);
    const encodeOldAN = this.sharedService.encodeData(oldarticle);
    const encodeOldSupId = this.sharedService.encodeData(oldfactory);
    let url: string;
    const pkFactoryId = this.authService.getUsrInfo().pkFactoryId;

    if (isBN === 1) {
      url = GlobalURL.SEARCH_ORDER_DETAILS + encodeSN + '/articles/' + encodeAN + '/' +
        encodeSupId + '?culture=' + this.sharedSrvc.getCurrentLocale() +
        '&BatchNo=' + encodeSN + '&Productiontreeseqid=' + productionTreeSeqId;
    } else if (typebool === undefined || typebool === null) {
      url = GlobalURL.SEARCH_ORDER_DETAILS + encodeSN + '/articles/' + encodeAN + '/' +
        encodeSupId + '?culture=' + this.sharedSrvc.getCurrentLocale() + ptSeqId;
    } else if (typebool === true) {
      url = GlobalURL.SEARCH_ORDER_DETAILS + encodeSN + '/articles/' + encodeAN + '/' +
        encodeSupId + '?culture=' + this.sharedSrvc.getCurrentLocale() + '&isSaveNewComponent=' + typebool +
        '&oldArticleNo=' + encodeOldAN + '&oldFactoryOrSupplier=' + encodeOldSupId +
        '&oldSerialNo=' + encodeOldSN + ptSeqId;
    } else if (typebool === false && orderLineId !== null && orderLineId !== undefined && orderLineId !== 0
      && orderLineId !== '') {
      url = GlobalURL.SEARCH_ORDER_DETAILS + encodeSN + '/articles/' + encodeAN + '/' +
        encodeSupId + '?culture=' + this.sharedSrvc.getCurrentLocale() + '&isSaveNewComponent=' + typebool +
        '&orderLineId=' + orderLineId + ptSeqId;
    } else if (typebool === false) {
      url = GlobalURL.SEARCH_ORDER_DETAILS + encodeSN + '/articles/' + encodeAN + '/' +
        encodeSupId + '?culture=' + this.sharedSrvc.getCurrentLocale() +
        '&isSaveNewComponent=' + typebool + ptSeqId;
    }
    url += '&factoryId=' + (pkFactoryId === undefined ? 0 : pkFactoryId);
    return this.http.get<Config>(url, { observe: 'response' });
  }

  getOrderDetails(requestParams): Observable<any> {
    let ptSeqId = '';
    if (requestParams.prodTreeSeqID) {
      ptSeqId = '&Ptseqid=' + requestParams.prodTreeSeqID;
    }
    const url: string = GlobalURL.ORDER_DETAIL + this.sharedService.encodeData(requestParams.orderLineNumber) +
      '/supplier/' + this.sharedService.encodeData(requestParams.supplierId) + '?culture=' + this.sharedSrvc.getCurrentLocale()
      + '&serialNumber=' + this.sharedService.encodeData(requestParams.serialNumber) + ptSeqId;
    return this.http.get(url);
  }

  getDocumentListDetails(requestParams): Observable<any> {
    const encodeSN = this.sharedService.encodeData(requestParams.serialNumber);
    let url: string = GlobalURL.DOCUMENT_LIST_DETAILS + requestParams.orderLineId + '?serialNumber=' + encodeSN +
      '&articleNumber=' + this.sharedService.encodeData(requestParams.articleNumber) + '&factoryGisNo=' +
      this.sharedService.encodeData(requestParams.supplierId);
    if (requestParams.isBN === 1) {
      url = url + '&Productiontreeseqid=' + requestParams.productionTreeSeqId;
    }
    return this.http.get(url);
  }

  getHistoryDetails(requestParams, condition): Observable<any> {
    let fcNum = '', batchNumber = '', supplierId = '', orderLineId = '', salesOrderId = '',
      prodTreeSeqID = '';
    const encodeBN = this.sharedService.encodeData(requestParams.batchNumber);
    if (requestParams.factoryGisNo !== '') {
      fcNum = 'factoryGISNo=' + this.sharedService.encodeData(requestParams.factoryGisNo) + '&';
    }
    if (encodeBN !== '') {
      batchNumber = 'batchNumber=' + encodeBN + '&';
    }
    if (requestParams.supplierId !== '') {
      supplierId = 'supplierId=' + this.sharedService.encodeData(requestParams.supplierId) + '&';
    }
    if (requestParams.orderLineId !== '') {
      orderLineId = 'orderLineId=' + requestParams.orderLineId + '&';
    }
    if (requestParams.salesOrderId !== '') {
      salesOrderId = 'salesOrderId=' + requestParams.salesOrderId + '&';
    }
    if (condition) {
      if (requestParams.factoryGisNo !== '') {
        fcNum = 'factoryGisNo=' + this.sharedService.encodeData(requestParams.factoryGisNo) + '&';
      }
      if (requestParams.salesOrderId !== '') {
        salesOrderId = 'salesOrderId=' + requestParams.salesOrderId + '&';
      }
      let encodeSN = this.sharedService.encodeData(requestParams.serialNumber);
      if (requestParams.serialNumber === '' && requestParams.batchNumber !== '') {
        encodeSN = this.sharedService.encodeData(requestParams.batchNumber);
      }
      if (requestParams.isBN === 1) {
        prodTreeSeqID = 'Productiontreeseqeid=' + requestParams.prodTreeSeqID;
      }
      const url: string = GlobalURL.CUSTOMER_HISTORY_DETAILS + this.sharedService.encodeData(requestParams.articleNumber) +
        '/ordercomponents/' + encodeSN + '?' + fcNum + batchNumber + supplierId
        + orderLineId + salesOrderId + prodTreeSeqID;
      return this.http.get<any>(url).pipe(
        map(res => {
          return res.map((item) => new HistoryModel(item));
        })
      );
    } else {
      let encodeSN = this.sharedService.encodeData(requestParams.serialNumber);
      if (requestParams.serialNumber === '' && requestParams.batchNumber !== '') {
        encodeSN = this.sharedService.encodeData(requestParams.batchNumber);
      }
      if (requestParams.isBN === 1) {
        prodTreeSeqID = 'Productiontreeseqeid=' + requestParams.prodTreeSeqID;
      }
      const url: string = GlobalURL.HISTORY_DETAILS + this.sharedService.encodeData(requestParams.articleNumber) +
        '/ordercomponents/' + encodeSN + '?' + fcNum + batchNumber + supplierId
        + orderLineId + salesOrderId + prodTreeSeqID;
      return this.http.get<any>(url).pipe(
        map(res => {
          return res.map((item) => new HistoryModel(item));
        })
      );
    }
  }

  addNote(articleNumber, factorySupplierId, orderProductId, serialNumber, data, batchNumber): Observable<any> {
    const encodeSN = this.sharedService.encodeData(serialNumber);
    let url: string = GlobalURL.ADD_NOTES + this.sharedService.encodeData(articleNumber) + '/factories/'
      + this.sharedService.encodeData(factorySupplierId) + '/products/' + orderProductId + '/ordercomponents' + '?serialNumber=' + encodeSN;
    if (batchNumber) {
      const encodeBN = this.sharedService.encodeData(batchNumber);
      url = url + '&batchNumber=' + encodeBN
        + '&culture=' + this.sharedSrvc.getCurrentLocale();
    } else {
      url = url + '&culture=' + this.sharedSrvc.getCurrentLocale();
    }
    return this.http.post(url, data, { observe: 'response' });
  }

  getBarcodeImage(data): Observable<Blob> {
    const url: string = GlobalURL.GET_BARCODE_IMAGE;
    return this.http.post(url, data, { responseType: 'blob' });
  }

  getArticleNumberList(params): Observable<any> {
    const url: string = GlobalURL.GET_ARTICLENUMBER_LIST + this.sharedService.encodeData(params.factoryId) +
      '?culture=' + this.sharedSrvc.getCurrentLocale();
    return this.http.get(url);
  }

  getSerialNumber(requestParams): Observable<any> {
    const url: string = GlobalURL.GET_SERIALNUMBER + this.sharedService.encodeData(requestParams.salesOrderNumber)
      + '/factory/' + requestParams.factoryId;
    return this.http.get(url);
  }

  // CR 180, SAP Production Order, 3-Aug-23, added isSAP property
  confirmProduct(params, data, serialNumbers, count, beginingSerialNumber, userCompanyNameAllowed,
    companyName, isSAP): Observable<HttpResponse<Config>> {
    const encodeArticleNo = this.sharedService.encodeData(params.articleNumber);
    const encodeArticleName = this.sharedService.encodeData(params.articleName);
    let url = '';
    if (serialNumbers !== undefined && serialNumbers !== null && serialNumbers.length > 0) {
      url = GlobalURL.CONFIRM_PRODUCT + encodeArticleNo
        + '/' + encodeArticleName + '/' + this.sharedService.encodeData(params.revisionLevel) +
        '/factory/' + params.factoryId + '?culture=' + this.sharedSrvc.getCurrentLocale() + '&count=' + serialNumbers.length +
        '&isSAP=' + isSAP;
    } else {
      url = GlobalURL.CONFIRM_PRODUCT + encodeArticleNo + '/' +
        encodeArticleName + '/' + this.sharedService.encodeData(params.revisionLevel) +
        '/factory/' + params.factoryId + '?culture=' + this.sharedSrvc.getCurrentLocale() + '&count=' + count +
        '&isSAP=' + isSAP;
    }

    if (params.configurationId) {
      url = url + '&configurationId=' + params.configurationId;
    }

    if (params.productionOrder !== null && params.productionOrder.length > 0) {
      url = url + '&productionNumber=' + this.sharedService.encodeData(params.productionOrder);
    }
    if (beginingSerialNumber !== null && beginingSerialNumber !== '') {
      url = url + '&SerialNoRange=' + this.sharedService.encodeData(beginingSerialNumber);
    }

    if (userCompanyNameAllowed) {
      url = url + '&productionFor=' + this.sharedService.encodeData(companyName);
    }

    if (params.salesOrder) {
      url = url + '&salesOrder=' + this.sharedService.encodeData(params.salesOrder);
    }

    if (params.sdPosition) {
      url = url + '&sdPosition=' + this.sharedService.encodeData(params.sdPosition);
    }

    if (serialNumbers !== undefined && serialNumbers !== null && serialNumbers.length > 0) {
      return this.http.post<Config>(url, serialNumbers, { observe: 'response' });
    } else {
      return this.http.post<Config>(url, data, { observe: 'response' });
    }
  }

  PrintTrackingCard(data): Observable<any> {
    const url: string = GlobalURL.PRINT_TRACKING_CARD;
    return this.http.post(url, data);
  }

  // reprint tracking barcode
  ReprintTrackingBarcode(item): Observable<any> {
    const url: string = GlobalURL.REPRINT_TRACKING_BARCODE;
    return this.http.post(url, item);
  }

  // get production step
  getProcessDetails(requestParams, pkFactoryId, isSAP?): Observable<any> {
    const encodeSN = this.sharedService.encodeData(requestParams.serialNumber);
    const encodeAN = this.sharedService.encodeData(requestParams.articleNumber);
    let url: string = GlobalURL.GET_PROCESS_DETAILS +
      encodeSN + '/articles/' + encodeAN
      //  requestParams.articleNumber
      + '/factory/' + this.sharedService.encodeData(requestParams.factoryId) + '?userFactoryId='
      + (pkFactoryId === undefined ? 0 : pkFactoryId);
    if (isSAP) {
      url += '&isSAP=' + true;
    }
    return this.http.get<Config>(url, { observe: 'response' });
  }

  // get production step
  getproductionOrderFromSN(serialNumber: string): Observable<any> {
    const encodeSN = this.sharedService.encodeData(serialNumber);
    const url: string = GlobalURL.GET_PO_FROM_SN +
      encodeSN;
    return this.http.get<Config>(url, { observe: 'response' });
  }
  // get production step new
  getProcessDetailsNew(requestParams, pkFactoryId): Observable<any> {
    let queryParams = '';
    if (requestParams.serialNumber) {
      queryParams += 'serialNumber=' + this.sharedService.encodeData(requestParams.serialNumber);
    }
    if (requestParams.batchNumber) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'batchNumber=' + this.sharedService.encodeData(requestParams.batchNumber);
    }
    if (queryParams) {
      queryParams = '?' + queryParams + '&userFactoryId='
        + (pkFactoryId === undefined ? 0 : pkFactoryId);
    }
    const encodeAN = this.sharedService.encodeData(requestParams.articleNumber);
    const url: string = GlobalURL.GET_PROCESS_DETAILS_NEW + encodeAN
      + '/factory/' + this.sharedService.encodeData(requestParams.factoryId) + queryParams;
    return this.http.get<Config>(url, { observe: 'response' });
  }

  // Save production step
  saveProduction(params, data): Observable<any> {
    const encodeSN = this.sharedService.encodeData(params.productSerialNumber);
    // const encodeLastUpdated = this.sharedService.encodeData(params.lastUpdated.split('/').join('-'));
    const encodeLastUpdated = this.sharedService.encodeData(params.lastUpdated);

    const url: string = GlobalURL.SAVE_PRODUCTION + params.factoryId + '/articles/' +
      this.sharedService.encodeData(params.articleNumber) + '/product/' + encodeSN + '/steps/' +
      this.sharedService.encodeData(params.stepsName) + '?lastUpdated=' + encodeLastUpdated;
    return this.http.post(url, data);
  }

  // prodcution sales service method
  getSalesOrderList(id, startPage, pageSize, filterId, search, sort, articleNumber?: string): Observable<any> {
    let searchName, sortName, filter;
    if (search.length > 0) {
      searchName = search + '&';
    } else {
      searchName = '';
    }
    if (sort.length > 0) {
      sortName = sort + '&';
    } else {
      sortName = '';
    }
    if (filterId.length > 0) {
      filter = filterId.substring(0, filterId.length - 1);
    } else {
      filter = '';
    }

    if (articleNumber) {
      const url = GlobalURL.GET_SALES_ORDERS + id + '/' + startPage
        + '/' + pageSize + '/order?' + searchName + sortName + filter + 'articleNumber=' + articleNumber;
      return this.http.get(url);
    } else {
      const url = GlobalURL.GET_SALES_ORDERS + id + '/' + startPage
        + '/' + pageSize + '/order?' + searchName + sortName + filter;
      return this.http.get(url);
    }
  }

  // save serial number
  saveSerialNumber(params, data): Observable<any> {
    const encodeLastUpdated = this.sharedService.encodeData(params.lastUpdated);
    const url: string = GlobalURL.SAVE_SERIAL_NUMBER +
      'orders/' + this.sharedService.encodeData(params.orderLineNumber) + '/articles/' +
      this.sharedService.encodeData(params.articleNumber) + '/' + this.sharedService.encodeData(params.articleName)
      + '/factory/' + params.factoryId + '?lastUpdated=' + encodeLastUpdated;
    return this.http.post(url, data);
  }

  // submit serial number
  submitSerialNumber(params, data): Observable<any> {
    const encodeLastUpdated = this.sharedService.encodeData(params.lastUpdated);
    const url: string = GlobalURL.SUBMIT_SERIAL_NUMBER +
      'orders/' + this.sharedService.encodeData(params.orderLineNumber) + '/articles/' +
      this.sharedService.encodeData(params.articleNumber) + '/' + this.sharedService.encodeData(params.articleName)
      + '/factory/' + params.factoryId + '?lastUpdated=' + encodeLastUpdated;
    return this.http.post(url, data);
  }

  // print order listing
  PrintOrderListing(params, data): Observable<any> {
    const url: string = GlobalURL.PRINT_ORDER_LISTING +
      this.sharedService.encodeData(params.orderLineNumber) + '/articles/' + this.sharedService.encodeData(params.articleNumber)
      + '/factory/' + this.sharedService.encodeData(params.factoryofOrigin) + '/' + params.quantity;
    return this.http.post(url, data);
  }

  // show barcode
  showBarcode(requestParams): Observable<any> {
    const encodeSN = this.sharedService.encodeData(requestParams.serialNumber);
    let revisionLevel;
    if (requestParams.revisionLevel !== '') {
      revisionLevel = '?revisionLevel=' + this.sharedService.encodeData(requestParams.revisionLevel);
    } else {
      revisionLevel = '';
    }
    const url: string = GlobalURL.SHOW_BARCODE_REGISTER_PRODUCT +
      this.sharedService.encodeData(requestParams.factoryId) + '/article/' + this.sharedService.encodeData(requestParams.articleNumber)
      + '/' + encodeSN + revisionLevel + '&culture=' + this.sharedSrvc.getCurrentLocale();
    return this.http.get<Config>(url, { observe: 'response' });
  }

  // assembly tree
  getAssemblyTree(serialNum, articleNum, parentId, factroyGis, factoryId): Observable<any> {
    const encodeSN = this.sharedService.encodeData(serialNum);
    const encodeAN = this.sharedService.encodeData(articleNum);
    const encodeFact = this.sharedService.encodeData(factroyGis);
    let params;
    if (parentId === null || parentId === '') {
      params = 'serialNumber=' + encodeSN + '&articleNumber=' + encodeAN + '&factoryGisNo=' + encodeFact;
    } else {
      params = 'parentId=' + parentId;
    }
    params += '&factoryId=' + (factoryId === undefined ? 0 : factoryId);
    const url = GlobalURL.PRODUCT_ASSEMBLY_TREE + params;
    return this.http.get(url);
  }

  // show production step info
  getProductionDetails(requestParams): Observable<any> {
    const encodeSN = this.sharedService.encodeData(requestParams.serialNumber);
    const encodeAN = this.sharedService.encodeData(requestParams.articleNumber);
    const encodeFN = this.sharedService.encodeData(requestParams.factoryId);
    const factoryId = this.authService.getUsrInfo().pkFactoryId;
    const url: string = GlobalURL.PRODUCTION_VIEW + encodeAN +
      '/ordercomponents/' + encodeSN + '/factory/' + encodeFN + '?factoryId=' + factoryId;
    return this.http.get<Config>(url, { observe: 'response' });
  }

  // get product documents
  getDocumentsListAssembly(condition, serialNumber?, articleNumber?, factoryId?): Observable<any> {
    const encodeSN = this.sharedService.encodeData(serialNumber);
    const encodeAN = this.sharedService.encodeData(articleNumber);
    const encodeFN = this.sharedService.encodeData(factoryId);
    if (condition) {
      const url = GlobalURL.CUSTOMER_DOCUMENT + 0 + '?serialNumber=' + encodeSN +
        '&articleNumber=' + encodeAN + '&factoryGisNo=' + encodeFN;
      return this.http.get(url);
    } else {
      const url = GlobalURL.DOCUMENT_LIST_DETAILS + 0 + '?serialNumber=' + encodeSN +
        '&articleNumber=' + encodeAN + '&factoryGisNo=' + encodeFN;
      return this.http.get(url);
    }
  }

  // Field installation - get header details
  getInstallationHeaderDetails(articlNum, serialNum, factoryGISNum): Observable<any> {
    const encodeSN = this.sharedService.encodeData(serialNum);
    const url = GlobalURL.FIELD_INSTALLATION + '/article/' + this.sharedService.encodeData(articlNum) + '/orders/' +
      encodeSN + '/factory/' + this.sharedService.encodeData(factoryGISNum);
    return this.http.get(url);
  }

  // Get register assembly details
  getRegisterAssemblyDetails(articlNum, serialNum, orderLineNumber): Observable<any> {
    const encodeSN = this.sharedService.encodeData(serialNum);
    const url = GlobalURL.GET_REGISTERED_ASSEMBLY + '/article/' + this.sharedService.encodeData(articlNum) + '/orders/'
      + encodeSN + '/' + this.sharedService.encodeData(orderLineNumber);
    return this.http.get(url);
  }

  // addd/replace component details
  getAddReplaceComponent(serialNumber, articleNumber, factoryGIS, oldSerial?, oldArticle?, oldFactory?,
    revNum?): Observable<HttpResponse<Config>> {
    const encodeSN = this.sharedService.encodeData(serialNumber);
    const encodeoldSerial = this.sharedService.encodeData(oldSerial);
    let oldArticleNumber = '', oldSerialNumber = '', oldFactoryGisNo = '', revNumber = '';
    if (oldSerial) {
      oldSerialNumber = 'oldSerialNumber=' + encodeoldSerial + '&';
    }
    if (oldArticle) {
      oldArticleNumber = 'oldArticleNumber=' + this.sharedService.encodeData(oldArticle) + '&';
    }
    if (oldFactory) {
      oldFactoryGisNo = 'oldFactoryGisNo=' + this.sharedService.encodeData(oldFactory);
    }
    if (revNum) {
      revNumber = '&UnregrevisionNumber=' + this.sharedService.encodeData(revNum);
    }
    const url = GlobalURL.ADD_REPLACE_COMPONENT_DETAILS + encodeSN + '/article/' + this.sharedService.encodeData(articleNumber)
      + '/factory/' + this.sharedService.encodeData(factoryGIS) + '?' + oldSerialNumber + oldArticleNumber + oldFactoryGisNo
      + revNumber + '&culture=' + this.sharedSrvc.getCurrentLocale();
    return this.http.get(url, { observe: 'response' });
  }

  // Mark as installation / save as draft
  submitInstallation(data): Observable<any> {
    const url: string = GlobalURL.MARK_AS_INSTALLED;
    return this.http.post(url, data);
  }

  // Get data for saved as draft
  getDraftInstallationData(articleNumber, serialNumber, factoryId, pageType): Observable<any> {
    const encodeSN = this.sharedService.encodeData(serialNumber);
    const param = this.sharedService.encodeData(articleNumber) + '/serial/' + encodeSN + '/factory/' +
      (factoryId) + '/page/' + this.sharedService.encodeData(pageType);
    const url: string = GlobalURL.GET_DRAFT_VALUE + param;
    return this.http.get(url);
  }

  getArticleData(articleNumber, serialNumber, factoryGIS): Observable<any> {
    const encodeSN = this.sharedService.encodeData(serialNumber);
    const param = this.sharedService.encodeData(articleNumber) + '/orders/' + encodeSN + '/factory/' +
      this.sharedService.encodeData(factoryGIS);
    const url: string = GlobalURL.GET_ARTICLE_DATA + param;
    return this.http.get(url);
  }

  markCommisson(data): Observable<any> {
    const url: string = GlobalURL.MARK_COMMISSION + '?culture=' + this.sharedSrvc.getCurrentLocale();
    return this.http.post(url, data);
  }

  markDecommisson(data): Observable<any> {
    const url: string = GlobalURL.MARK_DECOMMISSION;
    return this.http.post(url, data);

  }

  saveAddReplaceComponent(data, newArticle, newSerial, newFactoryId, newbatch): Observable<HttpResponse<Config>> {
    let encodeBN;
    let encodeSN;
    if (newSerial !== '' && newSerial !== null) {
      encodeSN = this.sharedService.encodeData(newSerial);
    }
    if (newbatch !== '' && newbatch !== null) {
      encodeBN = this.sharedService.encodeData(newbatch);
    }

    // replacementjuly
    if (encodeBN !== undefined && encodeSN !== undefined) {
      const url: string = GlobalURL.SAVE_ADD_REPLACE_COMPONENT + this.sharedService.encodeData(newArticle)
        + '/factory/' + this.sharedService.encodeData(newFactoryId) + '?serialNumber=' + encodeSN + '&batchNumber=' + encodeBN;
      return this.http.post<Config>(url, data, { observe: 'response' });
    } else if (encodeBN === undefined && encodeSN !== undefined) {
      const url: string = GlobalURL.SAVE_ADD_REPLACE_COMPONENT + this.sharedService.encodeData(newArticle)
        + '/factory/' + this.sharedService.encodeData(newFactoryId) + '?serialNumber=' + encodeSN;
      return this.http.post<Config>(url, data, { observe: 'response' });
    } else if (encodeBN !== undefined && encodeSN === undefined) {
      const url: string = GlobalURL.SAVE_ADD_REPLACE_COMPONENT + this.sharedService.encodeData(newArticle)
        + '/factory/' + this.sharedService.encodeData(newFactoryId) + '?batchNumber=' + encodeBN;
      return this.http.post<Config>(url, data, { observe: 'response' });
    }

  }

  getServiceInterventionData(id): Observable<HttpResponse<Config>> {
    const url = GlobalURL.SERVICE_INTERVENTION_ADD + id;
    return this.http.get(url, { observe: 'response' });
  }

  getServiceInterventionReplace(id): Observable<HttpResponse<Config>> {
    const url = GlobalURL.SERVICE_INTERVENTION_REPLACE + id;
    return this.http.get(url, { observe: 'response' });
  }

  getMilestone(id, type): Observable<any> {
    const url = GlobalURL.COMMISSION_MILESTONE_READ + id + '/' + this.sharedService.encodeData(type);
    return this.http.get(url);
  }

  editProductionData(data): Observable<any> {
    const url: string = GlobalURL.EDIT_PRODUCTION;
    return this.http.post(url, data);
  }
  editInstallation(data): Observable<any> {
    const url: string = GlobalURL.EDIT_INSTALLATION;
    return this.http.put(url, data);
  }

  editAddComp(data, MilestoneSeqId, lastUpdated): Observable<any> {
    const encodeLastUpdated = this.sharedService.encodeData(lastUpdated);
    const url: string = GlobalURL.EDIT_ADDCOMP + MilestoneSeqId + '?lastUpdated=' + encodeLastUpdated;
    return this.http.put(url, data);
  }

  editReplaceComp(data: Replace, MilestoneSeqId): Observable<any> {
    const url: string = GlobalURL.EDIT_REPLACECOMP;
    return this.http.post(url, data);
  }

  addQltTag(data: Qualitytag): Observable<any> {
    const url: string = GlobalURL.ADD_QLTTAG + '?culture=' + this.sharedSrvc.getCurrentLocale();
    return this.http.post(url, data);
  }

  // article search
  searchArticleNumber(id): Observable<HttpResponse<Config>> {
    const url: string = GlobalURL.SEARCH_ARTICLE_NUMBER + this.sharedService.encodeData(id);
    return this.http.get(url, { observe: 'response' });
  }
  // advance factory list
  getAdvanceFactoryList(factoryId): Observable<any> {
    const url: string = GlobalURL.SEARCH_FACTORY_LIST;
    return this.http.get(url);
  }

  // advanced search
  advancedSearchAzure(data, type): Observable<HttpResponse<Config>> {
    let url = '';
    if (type === 'Product') {
      url = GlobalURL.AZURE_ADVANCED_SEARCH;
    } else {
      url = GlobalURL.AZURE_SEARCH_COMPONENT;
    }
    return this.http.post(url, data, { observe: 'response' });
  }
  advancedSearchAzureMonthTest(data, type): Observable<HttpResponse<Config>> {
    let url = '';
    if (type === 'Product') {
      url = GlobalURL.AZURE_ONEMONTHDATA;
    } else {
      url = GlobalURL.AZURE_ONEMONTH_COMPONENT;
    }
    return this.http.post(url, data, { observe: 'response' });
  }
  advancedSearchAzureQuaterTest(data, type): Observable<HttpResponse<Config>> {
    let url = '';
    if (type === 'Product') {
      url = GlobalURL.AZURE_QUARTERDATA;
    } else {
      url = GlobalURL.AZURE_QUARTER_COMPONENT;
    }
    return this.http.post(url, data, { observe: 'response' });
  }
  advancedSearchAzureSixMonthTest(data, type): Observable<HttpResponse<Config>> {
    let url = '';
    if (type === 'Product') {
      url = GlobalURL.AZURE_SIXMONTHDATA;
    } else {
      url = GlobalURL.AZURE_SIXMONTH_COMPONENT;
    }
    return this.http.post(url, data, { observe: 'response' });
  }
  advancedSearchAzureYearTest(data, type): Observable<HttpResponse<Config>> {
    let url = '';
    if (type === 'Product') {
      url = GlobalURL.AZURE_YEARDATA;
    } else {
      url = GlobalURL.AZURE_YEAR_COMPONENT;
    }
    return this.http.post(url, data, { observe: 'response' });
  }
  advancedSearchAzureGTYearTest(data, type): Observable<HttpResponse<Config>> {
    let url = '';
    if (type === 'Product') {
      url = GlobalURL.AZURE_GTYEARDATA;
    } else {
      url = GlobalURL.AZURE_GTYEAR_COMPONENT;
    }
    return this.http.post(url, data, { observe: 'response' });
  }

  advancedSearchResult(data): Observable<any> {
    const url: string = GlobalURL.AZURE_ADVANCED_SEARCH;
    return this.http.post(url, data);
  }

  getQualityResponse(id): Observable<any> {
    const url = GlobalURL.GET_QUALITY_RESPONSE + id;
    return this.http.get(url);
  }

  respondQualityResponse(data): Observable<any> {
    const url: string = GlobalURL.SET_QUALITY_RESPONSE;
    return this.http.post(url, data);
  }
  // delete note
  deleteNote(id): Observable<any> {
    const url: string = GlobalURL.DELETE_NOTE + id;
    return this.http.delete(url);
  }

  blobDownload(data, attachmentName): Observable<any> {
    const obj = {
      blob: decodeURI(data.split(environment.blobContainer)[1].slice(1, data.split(environment.blobContainer)[1].length)),
      displayName: attachmentName
    };

    const url = GlobalURL.BLOB_DOWNLOAD;

    return this.http.post(url, obj);
  }

  getFontFile(): Observable<any> {
    return this.http.get('../../../assets/fonts/Arial.ttf', { responseType: 'blob' });
  }

  getControlCard(articlNum, serialNum, supplierFacId, isSlCC, isFromReplacement): Observable<any> {
    const encodeSN = this.sharedService.encodeData(serialNum);
    const encodeAN = this.sharedService.encodeData(articlNum);
    const encodeSupId = this.sharedService.encodeData(supplierFacId);
    let params;
    const factoryId = this.authService.getUsrInfo().pkFactoryId;
    params = encodeAN + '/ordercomponents/' + encodeSN + '/factory/' + encodeSupId;
    let url = GlobalURL.CONTROLCARD_DOWNLOAD + params + (isSlCC ? '?isSLCC=true' : '')
      + (isSlCC ? '&isFromReplacement=' : '?isFromReplacement=' + isFromReplacement);
    url += '&factoryId=' + (factoryId === undefined ? 0 : factoryId);
    let resType = {};
    if (isSlCC) {
      resType = { observe: 'response', responseType: 'arraybuffer' };
    }
    return this.http.post(url, null, resType);
  }

  getProductionFilteredTree(): Observable<any> {
    const url = GlobalURL.FILTERED_PRODUCT_TREE;
    return this.http.get(url);
  }
  getProductClassificationMappingId(data): Observable<any> {
    const url = GlobalURL.PRODUCT_MAPPING_ID;
    return this.http.post(url, data);
  }
  getStampImage(): Observable<any> {
    return this.http.get('../../../assets/img/QualityCard.png', { responseType: 'blob' });

  }

  getXECMDocument(data): any {
    const url = GlobalURL.XECM_DOCUMENT;
    return this.http.post(url, data);
  }

  jayshuklas(): any {
    return this.http.get('../../../assets/adminProduct.json');
  }
  searchIQCOrderDetails(articleNumber, supplierGuidOrFactoryId
    , serialOrBatchNumber, milestoneSeqId, orderlineId): Observable<HttpResponse<Config>> {
    const encodeSN = this.sharedService.encodeData(serialOrBatchNumber);
    const encodeAN = this.sharedService.encodeData(articleNumber);
    const encodeSupId = this.sharedService.encodeData(supplierGuidOrFactoryId);
    let extra = '';
    let url: string;
    if (milestoneSeqId !== 'null' && milestoneSeqId !== null && milestoneSeqId !== '0') {
      extra = '&milestoneSeqId=' + milestoneSeqId;
    } else if (orderlineId !== 0 && orderlineId !== null) {
      extra = '&orderLineId=' + orderlineId;
    } else {
      extra = '';
    }
    url = GlobalURL.SEARCH_IQC_ORDER_DETAILS + encodeAN + '/ordercomponents/' + encodeSN + '/supplier/' +
      encodeSupId + '?culture=' + this.sharedSrvc.getCurrentLocale() + extra;
    return this.http.get<Config>(url, { observe: 'response' });
  }

  searchUpcomingDetails(articleNumber, supplierGuidOrFactoryId
    , serialOrBatchNumber, milestoneSeqId, orderlineId): Observable<HttpResponse<Config>> {
    const encodeAN = this.sharedService.encodeData(articleNumber);
    const encodeSN = this.sharedService.encodeData(serialOrBatchNumber);
    const encodeSupId = this.sharedService.encodeData(supplierGuidOrFactoryId);
    let extra = '';
    let url: string;
    if (milestoneSeqId !== 'null') {
      extra = '&milestoneSeqId=' + milestoneSeqId;
    } else if (orderlineId !== 0 && orderlineId !== null) {
      extra = '&orderLineId=' + orderlineId;
    } else {
      extra = '';
    }
    url = GlobalURL.SEARCH_IQC_ORDER_DETAILS + encodeAN + '/ordercomponents/' + encodeSN + '/supplier/' +
      encodeSupId + '?culture=' + this.sharedSrvc.getCurrentLocale() + extra;

    return this.http.get<Config>(url, { observe: 'response' });
  }
  searchPreviousDetails(milestoneSeqId, orderlineId): Observable<HttpResponse<Config>> {
    let extra = '';
    let url: string;
    if (milestoneSeqId !== 'null' && milestoneSeqId !== null) {
      extra = '?componentMilestoneSeqId=' + milestoneSeqId;
    }
    url = GlobalURL.GET_PREVIOUS_INSPECTION_SEARCH + '/orderlines/' + orderlineId + extra;
    return this.http.get<Config>(url, { observe: 'response' });
  }

  saveInspectionProfileOrder(data): Observable<HttpResponse<Config>> {
    const url = GlobalURL.SAVE_IQC_INSPECTION_ORDER;
    return this.http.post(url, data, { observe: 'response' });
  }

  getInspectionDetailOld(orderLineId, ordersToComponentId, componentMilestoneSeqId): Observable<any> {
    let params = '';
    let optional = '';
    if (componentMilestoneSeqId !== 'null' && componentMilestoneSeqId !== null) {
      optional = '?componentMilestoneSeqId=' + componentMilestoneSeqId;
    } else {
      optional = '';
    }
    params = orderLineId + '/ordercomponents/' + ordersToComponentId + optional;
    const url = GlobalURL.IQC_INSPECTION_DETAIL + params;
    return this.http.get(url);
  }

  getInspectionDetail(orderLineId, ordersToComponentId, componentMilestoneSeqId, orderStatus, article, inspectionLotId): Observable<any> {
    let params = '';
    let params1 = '';
    let optional = '';
    if (componentMilestoneSeqId !== '' && componentMilestoneSeqId !== 'null' && componentMilestoneSeqId !== null) {
      optional = '?componentMilestoneSeqId=' + componentMilestoneSeqId;
    } else {
      optional = '';
    }
    if (orderStatus === 'Open') {
      optional = '?isOpen=' + true;
    } else {
      optional = '?isOpen=' + false;
    }

    if (inspectionLotId) {
      optional = optional + '&InspectionLotId=' + inspectionLotId;
    }
    params = orderLineId + optional;
    params1 = orderLineId + '/articleNo/' + this.sharedService.encodeData(article) + optional;
    let url;

    if (orderStatus !== 'Open') {
      url = GlobalURL.IQC_INSPECTION_PROFILE + params;
    } else {
      url = GlobalURL.IQC_INSPECTION_PROFILE_OPEN + params1;
    }
    return this.http.get(url);
  }

  saveInspectionIQC(data): Observable<HttpResponse<Config>> {
    const url = GlobalURL.IQC_INSPECTION_SAVE;
    return this.http.post(url, data, { observe: 'response' });
  }

  getSupplerInfoIQC(orderLineId, ordertoComponentId): Observable<any> {
    const url = GlobalURL.IQC_SUPPLIER_DETAIL + orderLineId + '/ordertoComponentId/'
      + ordertoComponentId;
    return this.http.get(url);
  }
  getSupplerInfoIQCNew(orderLineId): Observable<any> {
    const url = GlobalURL.IQC_SUPPLIER_DETAIL + orderLineId;
    return this.http.get(url);
  }

  getQT(startPage, pageSize, search, sort): Observable<any> {
    const url = GlobalURL.Qt + startPage + '/' + pageSize + '?' + this.sharedService.encodeData(sort) +
      this.sharedService.encodeData(search);
    return this.http.get(url);
  }

  getQTDetail(qtCode, respondFilter): Observable<any> {
    const url = GlobalURL.QUALITY_DETAIL + qtCode + '/' + respondFilter;
    return this.http.get(url);
  }

  getFeederFcatoryList() {
    const url = GlobalURL.FEEDER_FACTORY_LIST;
    return this.http.get(url);
  }

  validateSeriallNo(data, id) {
    const url = GlobalURL.VALIDATE_SERIALFF + id;
    return this.http.post(url, data);
  }

  submitIssueResolution(data) {
    const url = GlobalURL.SAVE_ISSUE_RESOLUTION;
    return this.http.post(url, data);
  }

  saveRaiseIssue(data) {
    const url = GlobalURL.SAVE_RAISE_ISSUE;
    return this.http.post(url, data);
  }

  getIssueDetails(refno, refId) {
    // ISSUEREFRNCENO . REFRENCEid
    const url = GlobalURL.FETCH_ISSUE_DETAILS + this.sharedService.encodeData(refno) + '/' + refId;
    return this.http.get(url);
  }


  feederSearch(data): Observable<any> {
    // ISSUEREFRNCENO . REFRENCEid
    const url = GlobalURL.FEEDERSEARCH;
    return this.http.post(url, data, { observe: 'response' });
  }
  getCustomReportOptions(): Observable<any> {
    const url = GlobalURL.CUSTOM_REPORT_OPTIONS;
    return this.http.get(url);
  }

  getIntermediateOptions(prTreeSeqId): Observable<any> {
    // { productionTreeSeqId }
    const url = GlobalURL.INTERMEDIATE_OPTIONS + prTreeSeqId;
    return this.http.get(url);
  }

  getCustomReportData(data, factoryId): Observable<any> {
    // { productionTreeSeqId }
    const url = GlobalURL.GET_CUSTOM_REPORT_DATA + '?factoryId=' + factoryId;
    return this.http.post(url, data);
  }

  getCustomReportProductFamilyFilter(): Observable<any> {
    const url = GlobalURL.CR_FAMILY_FILTER;
    return this.http.get(url);
  }

  getCustomReportTemplate(startPage: number, pageSize: number, sort: string, famliyFilter): Observable<any> {
    let param: string;
    let body = {};
    if (famliyFilter.length > 0) {
      body = {
        'familyList': famliyFilter
      };
    }
    if (sort) {
      sort = '?sortOrder=' + this.sharedService.encodeData(sort);
    }
    param = startPage + '/' + pageSize + sort;
    const url = GlobalURL.GET_CUSTOM_REPORT_TEMPLATE + param;
    return this.http.post<Config>(url, body, { observe: 'response' });
  }
  saveCustomReportData(data): Observable<any> {
    // { productionTreeSeqId }
    const url = GlobalURL.SAVE_CUSTOM_REPORT_DATA;
    return this.http.post(url, data);
  }

  downloadCustomReportData(data): Observable<any> {
    // { productionTreeSeqId }
    const url = GlobalURL.DOWNLOAD_CUSTOM_REPORT_DATA;
    return this.http.post(url, data);
  }

  getSelectedTemplatedetails(customReportTemplateId: number, productionTreeSeqId: number,
    currentProductProductionTreeSeqId: number, currentProductSerialnumber: string): Observable<any> {
    let param: string;
    const encodeSN = this.sharedService.encodeData(currentProductSerialnumber);
    param = customReportTemplateId + '/' + productionTreeSeqId + '/' + currentProductProductionTreeSeqId
      + '/' + encodeSN;
    const url = GlobalURL.GET_SELECTED_TEMPLATE + param;
    return this.http.get(url);
  }

  saveTemplateName(data, templateName, isConfirmed): Observable<any> {
    let param;
    if (isConfirmed) {
      param = '?newTemplateName=' + this.sharedService.encodeData(templateName) + '&isConfirmed=' + isConfirmed;
    } else {
      param = '?newTemplateName=' + this.sharedService.encodeData(templateName);
    }
    const url = GlobalURL.SAVE_CUSTOM_REPORT_DATA + param;
    return this.http.post(url, data);
  }

  getCustomReportCard(productSeqId): Observable<any> {

    const url = GlobalURL.CustomReport_DOWNLOAD + productSeqId;
    return this.http.get(url);
  }

  exportCustomReport(data): Observable<any> {
    const url = GlobalURL.ExportCustomReport;
    return this.http.post(url, data);

  }
  blobDownloadCustomReport(data): Observable<any> {
    const obj = {
      blob: decodeURI(data.split(environment.blobContainerCustomReport)[1]
        .slice(1, data.split(environment.blobContainerCustomReport)[1].length))
    };
    const url = GlobalURL.BLOB_DOWNLOAD_CUSTOM_REPORT;
    return this.http.post(url, obj);
  }
  // Delete template
  deleteTemplate(id): Observable<any> {
    const url: string = GlobalURL.DELETE_TEMPLATE + id;
    return this.http.delete(url);
  }

  searchDocumentDownload(data, factoryId): Observable<any> {
    const url = GlobalURL.SearchDocument + '?factoryId=' + factoryId;
    return this.http.post(url, data, { observe: 'response' });
  }
  getDocumentDownload(): Observable<any> {
    const url = GlobalURL.GetDocumentDownload;
    return this.http.get(url);
  }
  getdocumentTree() {
    return this.http.get('../../../assets/adminProduct.json');
  }

  getDownloadZip(data): Observable<any> {
    const url = environment.downloadZip;
    return this.http.post(url, data, { observe: 'response' });
  }

  GetStepDetailByStepName(params, pkFactoryId, isSAP?): Observable<any> {
    const encodeSN = this.sharedService.encodeData(params.productSerialNumber);
    const encodeStep = this.sharedService.encodeData(params.stepsName);
    let url: string = GlobalURL.GET_STEP_DETAIL_BY_STEPNAME +
      encodeSN + '/articles/' + this.sharedService.encodeData(params.articleNumber)
      + '/factory/' + this.sharedService.encodeData(params.factoryGisNumber) + '/stepName/' + encodeStep
      + '/stepReset/' + params.IsReset + '?userFactoryId='
      + (pkFactoryId === undefined ? 0 : pkFactoryId);

    if (isSAP) {
      url += '&isSAP=' + true;
    }
    return this.http.get(url);
  }

  getUpcomingInspection(startPage: number, pageSize: number, sortOrder?: string, orderLineNumberOrArticleNumber?: string): Observable<any> {
    sortOrder = sortOrder !== undefined && sortOrder !== '' ? ('?' + sortOrder) : '';
    const searchOrderLineNoORarticleNumber = orderLineNumberOrArticleNumber !== undefined && orderLineNumberOrArticleNumber !== '' ?
      ('?searchOrderLineNoORarticleNumber=' + this.sharedService.encodeData(orderLineNumberOrArticleNumber)) : '';
    const url = GlobalURL.GET_UPCOMING_INSPECTION + '/' + startPage + '/' + pageSize + sortOrder + searchOrderLineNoORarticleNumber;
    return this.http.get(url);
  }

  getPreviousInspection(startPage: number, pageSize: number, sortOrder?: string): Observable<any> {
    sortOrder = sortOrder !== undefined && sortOrder !== '' ? ('?' + sortOrder) : '';
    const url = GlobalURL.GET_PREVIOUS_INSPECTION + '/' + startPage + '/' + pageSize + sortOrder;
    return this.http.get(url);
  }

  getInspectionNotification(dataObj, factoryId): Observable<HttpResponse<Config>> {
    const url = GlobalURL.GET_INSPECTED_NOTIFICATION + '?factoryId=' + factoryId;
    return this.http.post<Config>(url, dataObj, { observe: 'response' });
  }

  getDrawing(articleNumber: string): Observable<any> {
    const url = GlobalURL.GET_DRAWING + this.sharedService.encodeData(articleNumber);
    return this.http.get(url);
  }

  saveCCRPDetails(data) {
    const url = GlobalURL.SAVE_CCRP_DETAILS;
    return this.http.post(url, data);
  }

  fetchCCRPBySerialNumber(serialNumber): Observable<any> {
    const url = GlobalURL.FETCH_CCRP_BY_SERIALNUMBER + '?serialNumber=' + serialNumber;
    return this.http.get(url);
  }

  saveServIsDetails(data) {
    const url = GlobalURL.SAVE_SERVIS_DETAILS;
    return this.http.post(url, data);
  }

  dataGovernanceManagement() {
    const url = GlobalURL.SEARCH_DATA_GOVERNANCE_DASHBOARDCOUNT;
    return this.http.get(url);
  }
  getCertsReviewData(data, factoryId) {
    let url;
    url = GlobalURL.CERT_REVIEW_DATA + '/' + data.startPage + '/' + data.pageSize +
      '?sortOrder=' + data.sortOrder + '&factoryfilter=' + data.factoryfilter +
      '&supplierGuid=' + data.supplierfilter + '&articleNumber=' + data.articleNumberfilter +
      '&supplierName=' + data.supplierNamefilter + '&articleName=' + data.articleNamefilter +
      '&orderLineNumber=' + data.orderlineNumberfilter + '&searchOrderLineNumber=' +
      this.sharedService.encodeData(data.srchOrders) +
      '&factoryId=' + (factoryId === undefined ? 0 : factoryId);
    return this.http.get(url);
  }

  getPendingCertsFilters(factoryId) {
    const url = GlobalURL.PENDING_CERTS_FILTERS + '/1'
      + '?factoryId=' + (factoryId === undefined ? 0 : factoryId);
    return this.http.get(url);
  }

  getPendingCertsData(data, factoryId) {
    const payload = {
      'Factoryfilter': data.factoryfilter,
      'ArticleNumber': data.articleNumberfilter,
      'SupplierGuid': data.supplierfilter,
      'SupplierName': data.supplierNamefilter,
      'ArticleName': data.articleNamefilter,
      'OrderLineNumber': data.orderlineNumberfilter,
      'SearchOrderLineNumber': this.sharedService.encodeData(data.srchOrders),
      'IsPending': true
    };
    let url = '';
    url = GlobalURL.PENDING_CERTS__DATA + '/' + data.startPage + '/' + data.pageSize +
      '?sortOrder=' + data.sortOrder + '&factoryId='
      + (factoryId === undefined ? 0 : factoryId);
    return this.http.post(url, payload);
  }

  getCertsDocumentList(orderLineId, categoryId, documentStatus) {
    const url = GlobalURL.CERT_DOCUMENT_LIST + '?orderLineId=' + orderLineId + '&categoryId=' + categoryId
      + '&documentStatus=' + documentStatus;
    return this.http.get(url);
  }

  saveSupplierDocumentInspectionStatus(data, factoryId) {
    const url = GlobalURL.SAVE_SUPPLIER_DOCUMENT_INSPECTION_STATUS + '?factoryId=' + factoryId;
    return this.http.post(url, data);
  }

  getReferenceDocuments(orderlineId, categoryId) {
    const url = GlobalURL.REFERENCE_DOCUMENT_LIST + orderlineId + '/' + true + '?isRefDoc=' + true +
      '&isDocPreview=' + true + '&categoryid=' + categoryId;
    return this.http.get(url);
  }

  GetProductionNotStartedSerialNumberCounts(userFactoryId, fromDate, toDate) {
    const url = GlobalURL.PRODUCTION_NOT_STARTED_COUNT + '?' + 'factoryId=' + userFactoryId + '&FromDate=' + fromDate + '&ToDate=' + toDate;
    return this.http.get(url);
  }

  GetProductionNotStartedSerialNumberListByDateRange(userFactoryId, articleNumber, fromDate, toDate) {
    const url = GlobalURL.PRODUCTION_NOT_STARTED_SERIALNUMBER + '?' + 'factoryId='
      + userFactoryId + '&ArticleNumber=' + this.sharedService.encodeData(articleNumber) + '&FromDate=' + fromDate + '&ToDate=' + toDate;
    return this.http.get(url);
  }

  GetWIPSerialNumberList(userFactoryId, fromDate, toDate) {
    const url = GlobalURL.PRODUCTION_WIP_SERIALNUMBER_COUNT +
      '?' + 'factoryId=' + userFactoryId + '&fromDate=' + fromDate + '&toDate=' + toDate;
    return this.http.get(url);
  }

  getPredefinedPageData(articleNumber, serialNumber, factoryId, RedbookConfigSeqId, customRebookSeqId, isReset) {
    const customRedbookSeqId = customRebookSeqId === null || customRebookSeqId === 'null'
      || customRebookSeqId === 'undefined' || customRebookSeqId === undefined ? 0 : customRebookSeqId;
    const url = GlobalURL.REDBOOK_PREDEFINED_PAGES + this.sharedService.encodeData(articleNumber) +
      '/serialnumber/' + this.sharedService.encodeData(serialNumber) +
      '/factory/' + this.sharedService.encodeData(factoryId) + '?CustomRedbookDetailsSeqID=' + customRedbookSeqId +
      '&RedbookConfigSeqID=' + RedbookConfigSeqId
      + '&isAuto=' + true + '&isReset=' + isReset;
    return this.http.get(url);
  }
  getPageData(articleNumber, serialNumber, factoryId, redbookConfigSeqId, isAuto) {
    const url = GlobalURL.REDBOOK_PAGES + this.sharedService.encodeData(articleNumber) +
      '/serialnumber/' + this.sharedService.encodeData(serialNumber) +
      '/factory/' + this.sharedService.encodeData(factoryId) + '?redbookConfigId=' + redbookConfigSeqId
      + '&isAuto=' + isAuto;
    return this.http.get(url);
  }

  getRedBookCoverPageDetails(serialNumber, factoryId, customRedbookDetailsSeqID) {
    const url = GlobalURL.REDBOOK_GET_COVER_SHEET_DETAILS + '?serialNumber=' +
      this.sharedService.encodeData(serialNumber) + '&factoryId=' +
      this.sharedService.encodeData(factoryId) + '&CustomRedbookDetailsSeqID=' + customRedbookDetailsSeqID;
    return this.http.get(url);
  }

  deleteRedBookPage(RedbookPageDetailsSeqId) {
    const url = GlobalURL.DELETE_REDBOOK_PAGE + '?RedbookPageDetailsSeqId=' + RedbookPageDetailsSeqId;
    return this.http.post(url, RedbookPageDetailsSeqId);
  }
  getRedbookProductionTestDataDetails(serialNumber, articleNumber, factoryId,
    RedbookPageDetailsSeqId, pageSeqId, pageName) {

    RedbookPageDetailsSeqId = RedbookPageDetailsSeqId === null ? 0 : RedbookPageDetailsSeqId;

    const url = GlobalURL.REDBOOK_PRODUCTION_TEST_DATA + this.sharedService.encodeData(articleNumber) +
      '/serialnumber/' + this.sharedService.encodeData(serialNumber) +
      '/factory/' + this.sharedService.encodeData(factoryId) + '?RedbookPageDetailsSeqId=' + RedbookPageDetailsSeqId +
      '&pageSeqId=' + pageSeqId + '&pageName=' + pageName;
    return this.http.get(url);
  }

  saveRedbookPage(data, redbookPageDetailsSeqId) {
    const RedbookPageDetailsSeqId = redbookPageDetailsSeqId === null ? 0 : redbookPageDetailsSeqId;
    const url = GlobalURL.SAVE_FINSH_REDBOOK_PAGE + '?RedbookPageDetailsSeqId=' + RedbookPageDetailsSeqId;
    return this.http.post(url, data);
  }

  saveRedbookProductionTestDataDocList(data) {
    const url = GlobalURL.REDBOOK_PRODUCTION_TEST_DATA_SAVE_DOC_LIST;
    return this.http.post(url, data);
  }

  GetWIPStepwiseSerialNumberCounts(userFactoryId, articleNumber, fromDate, toDate) {
    const url = GlobalURL.PRODUCTION_WIP_STEPWISE_SERIALNUMBER_COUNT +
      '?' + 'factoryId=' + userFactoryId + '&ArticleNumber=' + this.sharedService.encodeData(articleNumber) +
      '&fromDate=' + fromDate + '&toDate=' + toDate;
    return this.http.get(url);
  }

  GetWIPStepwiseSerialNumberList(userFactoryId, articleNumber, stepName, fromDate, toDate) {
    const url = GlobalURL.PRODUCTION_WIP_STEPWISE_SERIALNUMBER_LIST +
      '?' + 'factoryId=' + userFactoryId + '&articleNumber=' + this.sharedService.encodeData(articleNumber) +
      '&fromDate=' + fromDate + '&toDate=' + toDate + '&stepName=' + this.sharedService.encodeData(stepName);
    return this.http.get(url);
  }

  // 20JULPO1, DiscardSerialNumber not working for large SerialNumbers, 17-Jul-23,
  // discard sn method changed from delete to post and updated url
  serialNumbersDiscard(data) {
    const url = GlobalURL.PRODUCTION_NOT_STARTED_COUNT_SERIALNUMBER_DISCARD;
    return this.http.post(url, data);
  }

  ProductionNotStartedSerialNumberExport(userFactoryId, fromDate, toDate) {
    const url = GlobalURL.PRODUCTION_NOT_STARTED_SERIALNUMBER_EXPORT + '?' +
      'factoryId=' + userFactoryId + '&FromDate=' + fromDate + '&ToDate=' + toDate;
    return this.http.get(url);
  }

  GetProductSerialNumbersWithoutSOAssociation(userFactoryId) {
    const url = GlobalURL.UNASSOCIATED_SO + '?' + 'factoryId=' + userFactoryId;
    return this.http.get(url);
  }

  getRedbookComponentTracebilityData(articleNumber, serialNumber, factoryId,
    RedbookPageDetailsSeqId, pageSeqId, pageName) {

    let url = GlobalURL.REDBOOK_GET_COMPONENT_TRACEBILITY_DATA
      + '?articleNumber=' + this.sharedService.encodeData(articleNumber) +
      '&serialnumber=' + this.sharedService.encodeData(serialNumber) +
      '&factoryId=' + this.sharedService.encodeData(factoryId);

    if (RedbookPageDetailsSeqId !== null) {
      url = url + '&RedbookPageDetailsSeqId=' + RedbookPageDetailsSeqId;
    }

    url = url + '&pageSeqId=' + pageSeqId + '&pageName=' + pageName;
    return this.http.get(url);
  }

  saveComponentTraceabilityData(data) {
    const url = GlobalURL.REDBOOK_SAVE_COMPONENT_TRACEABILITY_DATA;
    return this.http.post(url, data);
  }

  getRedbookNewBlankPageData(redbookPageDetailsSeqId) {
    let url = GlobalURL.REDBOOK_GET_NEW_BLANK_PAGE_DATA;
    const RedbookPageDetailsSeqId = redbookPageDetailsSeqId === null ? 0 : redbookPageDetailsSeqId;
    url = url + '?RedbookPageDetailsSeqId=' + RedbookPageDetailsSeqId;
    return this.http.get(url);
  }

  getTestReportCertificateData(articleNumber, serialNumber, factoryId, redbookPageDetailsSeqId, pageSeqId, pageName) {
    const RedbookPageDetailsSeqId = redbookPageDetailsSeqId === null ? 0 : redbookPageDetailsSeqId;

    const url = GlobalURL.REDBOOK_GET_TEST_REPORT_DATA + this.sharedService.encodeData(articleNumber) +
      '/serialNumber/' + this.sharedService.encodeData(serialNumber) + '/factory/' +
      this.sharedService.encodeData(factoryId) + '?RedbookPageDetailsSeqId=' + RedbookPageDetailsSeqId +
      '&pageSeqId=' + pageSeqId + '&pageName=' + pageName + '&isTestReport=' + false;
    return this.http.get(url);
  }

  saveRedbookAttachments(data) {
    const url = GlobalURL.REDBOOK_SAVE_ATTACHMENTS;
    return this.http.post(url, data);
  }

  redbookReviewPublish(data) {
    let newData: any = [];
    newData = data.data === undefined ? [] : data.data;
    const customRedbookDetailsSeqID = data.customRedbookDetailsSeqID === undefined ? 0 : data.customRedbookDetailsSeqID;
    const url = GlobalURL.REDBOOK_REVIEW_PUBLISH + this.sharedService.encodeData(data.articleNumber) +
      '/serialNumber/' + this.sharedService.encodeData(data.serialNumber) + '/factory/' +
      this.sharedService.encodeData(data.factoryId) + '?CustomRedbookPageDetailsSeqID='
      + customRedbookDetailsSeqID + '&redbookConfigId=' + data.RedbookConfigSeqId + '&isAuto=' + data.isAuto;
    return this.http.post(url, newData);
  }

  getRedbookCertificateCategory() {
    const url = GlobalURL.REDBOOK_GET_CERTIFICATE_CATEGORY;
    return this.http.get(url);
  }

  getConsumptionDetails(customRedbookDetailsSeqID, redbookPageDetailsSeqId, documentID) {
    const url = GlobalURL.REDBOOK_GET_CONSUMPTION_DETAILS + documentID + '?customRedbookDetailsSeqID=' +
      customRedbookDetailsSeqID + '&redbookPageDetailsSeqId=' + redbookPageDetailsSeqId;
    return this.http.get(url);
  }

  getProductSerialNumberRange(serialNumber, range, salesOrderNumber, articleNumber, factoryId) {
    const url = GlobalURL.GET_PRODUCT_SERIAL_NUMBER_RANGE + this.sharedService.encodeData(serialNumber) +
      '/Count/' + range + '/SalesOrderNumber/' + this.sharedService.encodeData(salesOrderNumber) +
      '/ArticleNumber/' + this.sharedService.encodeData(articleNumber) + '/FactoryId/' + factoryId;
    return this.http.get(url);
  }

  addFinalTestingDocument(articleNumber, factoryId, beginingSerialNumber, serialNumRange,
    articlename, salesOrderNumber, uploadtype, formData, pkFactoryId) {
    const count = serialNumRange === undefined ? 0 : serialNumRange;
    articlename = articlename !== '' ? articlename : 'empty';
    salesOrderNumber = salesOrderNumber !== '' ? salesOrderNumber : 'empty';
    const beginingSerialNum = beginingSerialNumber === '' ? 'empty' : this.sharedService.encodeData(beginingSerialNumber);
    const url = GlobalURL.ADD_FINAL_TESTING_DOCUMENT + this.sharedService.encodeData(articleNumber) + '/articlename/' +
      this.sharedService.encodeData(articlename) + /salesordernumber/ + this.sharedService.encodeData(salesOrderNumber) +
      '/uploadtype/' + this.sharedService.encodeData(uploadtype) +
      '/factory/' + factoryId + '/serialno/' + beginingSerialNum + '/count/' + count + '?userFactoryId='
      + (pkFactoryId === undefined ? 0 : pkFactoryId);
    return this.http.post(url, formData);
  }

  getIQCSummaryDetails(orderlineId): Observable<any> {
    const url: string = GlobalURL.GET_IQC_SUMMARY_DATA + orderlineId;
    return this.http.get(url);
  }

  getIQCsummery(orderLineId) {
    const url: string = GlobalURL.IQC_SUMMERY + 'orderLineId' + '/' + orderLineId;
    return this.http.get(url);
  }

  validateUnfinshedSubAssembly(data) {
    const url: string = GlobalURL.VALIDATE_UNFINSHED_SUB_ASSEMBLY;
    return this.http.post(url, data);
  }

  getProductionCompleteDetails(articleNumber, serialNumber, factoryId) {
    const url = GlobalURL.GET_PRODUCTION_COMPLETE_DETAILS + this.sharedService.encodeData(serialNumber) +
      '/articleNumber/' + this.sharedService.encodeData(articleNumber) + '/factoryGisno/'
      + this.sharedService.encodeData(factoryId);
    return this.http.get(url);
  }

  validateMassProductionData(data) {
    const url = GlobalURL.VALIDATE_MASS_PRODUCTION_DATA;
    return this.http.post(url, data);
  }

  createMassProduction(data, pkFactoryId) {
    const url = GlobalURL.CREATE_MASS_PRODUCTION + '?factoryId='
      + (pkFactoryId === undefined ? 0 : pkFactoryId);

    return this.http.post(url, data);
  }

  getOldQcCard(articleNumber, serialNumber, factoryGISNo): Observable<any> {
    const encodeAN = this.sharedService.encodeData(articleNumber);
    const encodeSN = this.sharedService.encodeData(serialNumber);
    const encodeFGISN = this.sharedService.encodeData(factoryGISNo);
    const url = GlobalURL.GET_OLD_QC_CARD + encodeAN +
      '/SerialNumber/' + encodeSN + '/FactoryGisNo/' + encodeFGISN;
    return this.http.get(url, { observe: 'response', responseType: 'blob' });
  }
  getProductHierarchy(data): Observable<any> {
    const url = environment.productHierarchy;
    return this.http.post(url, data, { observe: 'response', responseType: 'text' });
  }

  getAdvanceSearchNotificationMessage(): Observable<any> {
    const url = GlobalURL.NOTIFICATION_MESSAGE_AD_SEARCH;
    console.log(url);
    return this.http.get(url);
  }

  productWithdraw(reason, articleNumber, serialNumber, factoryGISNo, factoryId): Observable<any> {
    const encodeAN = this.sharedService.encodeData(articleNumber);
    const encodeSN = this.sharedService.encodeData(serialNumber);
    const encodeFGISN = this.sharedService.encodeData(factoryGISNo);
    const obj = {
      'reason': reason,
      'serialNumber': encodeSN,
      'articleNumber': encodeAN,
      'supplierId': encodeFGISN

    };
    const url = GlobalURL.PRODUCT_WIHTDRAW + '?factoryId=' + factoryId;
    return this.http.post(url, obj);
  }

  getQcRestructringData(serialNumber, articleNumber, factoryGISNo) {
    const encodeAN = this.sharedService.encodeData(articleNumber);
    const encodeSN = this.sharedService.encodeData(serialNumber);
    const encodeFGISN = this.sharedService.encodeData(factoryGISNo);
    let queryParams = '';
    let pkFactoryId = this.authService.getUsrInfo().pkFactoryId;
    pkFactoryId = pkFactoryId === undefined ? 0 : pkFactoryId;

    if (pkFactoryId > 0) {
      queryParams += '?userFactoryId=' + pkFactoryId;
    }

    const url = GlobalURL.QC_RESTRUCTING + encodeSN + '/articles/' + encodeAN + '/factory/' + encodeFGISN + queryParams;
    return this.http.get(url);
  }

  getSectionDetails(serialNo, articleNo, factoryGISNo, isAHIT, isViewMore): Observable<any> {
    const obj = {
      'SerialNumber': serialNo,
      'ArticleNumber': articleNo,
      'FactoryGisno': factoryGISNo,
      'FilterSection': 'All',
      'IsAhitFactory': false,
      'IsViewMore': isViewMore
    };
    const url = GlobalURL.GET_SECTION_DETAILS;

    return this.http.post(url, obj);

  }


  getRestructuredQualityCard(data) {
    const url = GlobalURL.RESTRUCTURED_CONTROL_CARD;
    return this.http.post(url, data);
  }

  getSelectedDocumentSerialNumberData(data): Observable<any> {
    const url = GlobalURL.SERIAL_NUMBER_SALES_ORDER;
    return this.http.post(url, data, { observe: 'response' });
  }

  // P - purcase , S - sales
  getTypeOfSearchedPurcaseorSaleOrder(data): Observable<any> {
    const url = GlobalURL.GET_TYPE_OF_SEARCH_REQUEST;
    return this.http.post(url, data, { observe: 'response' });
  }

  getTypeAsSalesOrderOnly(data): Observable<any> {
    let url = GlobalURL.GET_TYPE_OF_SEARCH_REQUEST;
    url = `${url}?onlysaleorder=true`;
    return this.http.post(url, data, { observe: 'response' });
  }

  generateActasXml(data): Observable<any> {
    const url = GlobalURL.ACTAS_GENERATE_XML;
    return this.http.post(url, data, { observe: 'response', responseType: 'blob' });
  }

  getSalesOrderByFactory(factory): Observable<any> {
    const url = GlobalURL.GET_SALES_ORDERS_BY_FACTORY + factory;
    return this.http.get(url);
  }

  getSerialNumbersBySalesOrders(salesOrderSeqID, supplierGUID): Observable<any> {
    const encodeSGUID = this.sharedService.encodeData(supplierGUID);
    const url = GlobalURL.GET_SERIAL_NUMBERS_BY_SALES_ORDERS + salesOrderSeqID + '/Supplier/' + encodeSGUID;
    return this.http.get(url);
  }

  getFinalTestDocumentBySerialNumbers(articleNumber, supplierId, serialNumberList): Observable<any> {
    const encodeAN = this.sharedService.encodeData(articleNumber);
    const encodeSupId = this.sharedService.encodeData(supplierId);
    const url = GlobalURL.GET_FINAL_TEST_DOCUMENT_BY_SERIAL_NUMBERS + encodeAN + '/supplierid/' + encodeSupId;
    return this.http.post(url, serialNumberList);
  }

  generateTestReport(articleNumber, serialNumber, factoryGisNo): Observable<any> {
    const encodeAN = this.sharedService.encodeData(articleNumber);
    const encodeSN = this.sharedService.encodeData(serialNumber);
    const encodeFGISNo = this.sharedService.encodeData(factoryGisNo);
    const url = GlobalURL.GENERATE_TEST_REPORT + encodeAN + '/serial/' + encodeSN + '/factory/' + encodeFGISNo;
    return this.http.get(url);
  }

  getProductionAlert() {
    const url = GlobalURL.PRODUCTION_DEPLOYMENT_ALERT;
    return this.http.get(url);
  }

  saveProductionTimeCapturedDetails(data): Observable<any> {
    const url = GlobalURL.SAVE_PRODUCTION_TIME_CAPTURED_DETAILS;
    return this.http.post(url, data, { observe: 'response' });
  }

  getRecordedProductionTimeArticleWise(params): Observable<any> {
    let queryParams = '?recevingFactory=' + this.sharedService.encodeData(params.receivingFactory) +
      '&pageIndex=' + params.pageIndex + '&isGridData=' + params.isGridData +
      '&isExportData=' + params.isExportData;
    if (params.serialNumber) {
      queryParams += '&serialNumber=' + this.sharedService.encodeData(params.serialNumber);
      if (params.isGridData) {
        queryParams += '&articleNumber=' + this.sharedService.encodeData(params.articleNumber);
      }
    } else {
      queryParams += '&articleNumber=' + this.sharedService.encodeData(params.articleNumber);
      queryParams += '&fromDate=' + params.fromDate;
      queryParams += '&toDate=' + params.toDate;
    }
    const url = GlobalURL.GET_RECORDED_PRODUCTION_TIME_ARTICLE_WISE + queryParams;
    return this.http.get(url);
  }

  getProductionTimeCapturedDetails(pageIndex, isGridData, localTimeZone, data): Observable<any> {
    const url = GlobalURL.GET_RECORDED_PRODUCTION_TIME_DETAILS +
      '?pageIndex=' + pageIndex + '&isGridData=' + isGridData + '&localTimeZone=' + localTimeZone;
    return this.http.post(url, data, { observe: 'response' });
  }

  generateSNReportForProductionTimeCaptureDetails(data): Observable<any> {
    const url = GlobalURL.GENERATE_SERIAL_NUMBER_REPORT_FOR_PTC;
    return this.http.post(url, data, { observe: 'response', responseType: 'arraybuffer' });
  }

  generateAllReportForProductionTimeCaptureDetails(data): Observable<any> {
    const url = environment.generateReportProductionTimeCaptured;
    return this.http.post(url, data, { observe: 'response', responseType: 'text' });
  }

  getProductionOrdersDetails(data) {
    const url = GlobalURL.GET_PRODUCTION_ORDER_DETAILS;
    return this.http.post(url, data);
  }
  // Start CR 200, Need to introduce Hold Status in DPS for Quality Controller for DPS as MES type factories, 11 Aug 2023
  productOnHoldDetails(reason, articleNumber, serialNumber, factoryGISNo): Observable<any> {
    const encodeAN = this.sharedService.encodeData(articleNumber);
    const encodeSN = this.sharedService.encodeData(serialNumber);
    const encodeFGISN = this.sharedService.encodeData(factoryGISNo);
    const obj = {
      'Reason': reason,
      'SerialNumber': encodeSN,
      'ArticleNumber': encodeAN,
      'FactoryGisNo': encodeFGISN

    };
    const url = GlobalURL.ONHOLD_PRODUCT;
    return this.http.post(url, obj, { observe: 'response' });
  }
  releasedProductDetails(articleNumber, serialNumber, factoryGISNo): Observable<any> {
    const encodeAN = this.sharedService.encodeData(articleNumber);
    const encodeSN = this.sharedService.encodeData(serialNumber);
    const encodeFGISN = this.sharedService.encodeData(factoryGISNo);
    const obj = {
      'SerialNumber': encodeSN,
      'ArticleNumber': encodeAN,
      'FactoryGisNo': encodeFGISN

    };
    const url = GlobalURL.RELEASED_PRODUCT;
    return this.http.post(url, obj, { observe: 'response' });
  }
  // End CR 200, Need to introduce Hold Status in DPS for Quality Controller for DPS as MES type factories, 11 Aug 2023

  // Start CR 199, Need for provisioning to replace uploaded certificates for finished and ongoing products, 14 Aug 2023
  getDocumentCountList(articleNumber, serialNumber, factoryId): Observable<any> {
    const encodeAN = this.sharedService.encodeData(articleNumber);
    const encodeSN = this.sharedService.encodeData(serialNumber);
    const url = GlobalURL.GET_DOCUMENT_COUNT_LIST + encodeAN +
      '/serialNumber/' + encodeSN + '/factoryId/' + factoryId;
    return this.http.get(url);
  }

  replaceProductionDocument(data): Observable<any> {
    const url = GlobalURL.REPLACE_PRODUCTION_DOCUMENT;
    return this.http.post(url, data, { observe: 'response' });
  }
  // End CR 199, Need for provisioning to replace uploaded certificates for finished and ongoing products, 14 Aug 2023

  // Start 154 (c), Recording production incidents, 5 Oct 2023
  getIncidentCount(params): Observable<any> {
    let queryParams = '';
    if (params.articleNumber) {
      queryParams += 'ArticleNumber=' + this.sharedService.encodeData(params.articleNumber);
    }
    if (params.serialNumber) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'SerialNumber=' + this.sharedService.encodeData(params.serialNumber);
    }
    if (params.factoryGisNumber) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'FactoryGisNo=' + this.sharedService.encodeData(params.factoryGisNumber);
    }

    const pkFactoryId = this.authService.getUsrInfo().pkFactoryId;
    queryParams += '&userFactoryId=' + (pkFactoryId === undefined ? 0 : pkFactoryId);

    if (queryParams) {
      queryParams = '?' + queryParams;
    }

    const url = GlobalURL.GET_INCIDENT_COUNT + queryParams;
    return this.http.get(url);
  }

  raiseIncident(data, factoryId): Observable<any> {
    const url = GlobalURL.RAISE_INCIDENT + '?factoryId=' + (factoryId === undefined ? 0 : factoryId);
    return this.http.post(url, data);
  }

  // fetch all incidents and corressponding details
  getAllIncident(params): Observable<any> {
    let queryParams = '';
    if (params.articleNumber) {
      queryParams += 'ArticleNumber=' + this.sharedService.encodeData(params.articleNumber);
    }
    if (params.serialNumber) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'SerialNumber=' + this.sharedService.encodeData(params.serialNumber);
    }
    if (params.factoryGisNumber) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'FactoryGisNo=' + this.sharedService.encodeData(params.factoryGisNumber);
    }
    if (params.incidentNumber) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'IncidentNumber=' + this.sharedService.encodeData(params.incidentNumber);
    }

    const pkFactoryId = this.authService.getUsrInfo().pkFactoryId;
    queryParams += '&userFactoryId=' + (pkFactoryId === undefined ? 0 : pkFactoryId);

    if (queryParams) {
      queryParams = '?' + queryParams;
    }

    const url: string = GlobalURL.GET_ALL_INCIDENTS + queryParams;
    return this.http.get<Config>(url);
  }

  // fetch detail of one particular incident
  getIncidentDetails(params) {
    let queryParams = '';
    if (params.articleNumber) {
      queryParams += 'ArticleNumber=' + this.sharedService.encodeData(params.articleNumber);
    }
    if (params.serialNumber) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'SerialNumber=' + this.sharedService.encodeData(params.serialNumber);
    }
    if (params.factoryGisNumber) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'FactoryGisNo=' + this.sharedService.encodeData(params.factoryGisNumber);
    }
    if (params.incidentNumber) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'IncidentNumber=' + this.sharedService.encodeData(params.incidentNumber);
    }
    if (queryParams) {
      queryParams = '?' + queryParams;
    }
    const url: string = GlobalURL.GET_INCIDENT_DETAILS + queryParams;
    return this.http.get<Config>(url);
  }

  getStatusDropdownForIncident(pkFactoryId) {
    const url: string = GlobalURL.GET_ALL_INCIDENT_STATUS_DROPDOWN + '?factoryId=' + (pkFactoryId === undefined ? 0 : pkFactoryId);
    return this.http.get<Config>(url);
  }
  // End 154 (c), Recording production incidents, 5 Oct 2023

  // Start 154 (d), Reporting production incidents, 5 Oct 2023
  getIncidentLogData(params): Observable<any> {
    let queryParams = '';
    if (params.articleNumber) {
      queryParams += 'articleNumber=' + this.sharedService.encodeData(params.articleNumber);
    }
    if (params.serialNumber) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'serialNumber=' + this.sharedService.encodeData(params.serialNumber);
    }
    if (params.incidentNumber) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'IncidentNumber=' + this.sharedService.encodeData(params.incidentNumber);
    }
    if (params.toDate) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'toDate=' + params.toDate;
    }
    if (params.fromDate) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'fromDate=' + params.fromDate;
    }
    if (params.pageIndex) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'pageIndex=' + params.pageIndex;
    }
    queryParams += '&factoryId=' + (params.factoryId === undefined ? 0 : params.factoryId);
    if (queryParams) {
      queryParams = '?' + queryParams;
    }
    const url = GlobalURL.GET_INCIDENT_LOG_DATA + queryParams;
    return this.http.get(url);
  }

  generateIncidentLogDataReport(params): Observable<any> {
    let queryParams = '';
    if (params.articleNumber) {
      queryParams += 'articleNumber=' + this.sharedService.encodeData(params.articleNumber);
    }
    if (params.serialNumber) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'serialNumber=' + this.sharedService.encodeData(params.serialNumber);
    }
    if (params.incidentNumber) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'IncidentNumber=' + this.sharedService.encodeData(params.incidentNumber);
    }
    if (params.toDate) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'toDate=' + params.toDate;
    }
    if (params.fromDate) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'fromDate=' + params.fromDate;
    }
    if (params.factoryId) {
      if (queryParams) {
        queryParams += '&';
      }
      queryParams += 'factoryId=' + params.factoryId;
    }
    if (queryParams) {
      queryParams = '?' + queryParams;
    }
    const url = GlobalURL.GENERATE_INCIDENT_LOG_DATA_REPORT + queryParams;
    return this.http.get(url, { observe: 'response', responseType: 'blob' });
  }

  getIncidentDescriptionAttachment(incidentID, incidentStatusTypeID): Observable<any> {
    const encodeIncId = this.sharedService.encodeData(incidentID);
    const encodeIncStatusTypeId = this.sharedService.encodeData(incidentStatusTypeID);
    const url = GlobalURL.GET_INCIDENT_DESCRIPTION_ATTACHMENT + encodeIncId + '/IncientstatustypeID/' + encodeIncStatusTypeId;
    return this.http.get(url);
  }
  // End 154 (d), Reporting production incidents, 5 Oct 2023


  getProductionOrderDocumentDetails(productionOrderNumber) {
    const url = GlobalURL.GET_PRODUCTION_ORDER_DOCUMENT_DETAILS + productionOrderNumber;
    return this.http.get(url);
  }

  // Start CH38-Hierarchical BOM view with traceable components flagged
  getBOMDetails(productionOrderNumber, articleNumber) {
    const url = GlobalURL.GET_BOM_DETAILS + this.sharedService.encodeData(productionOrderNumber) + '/'
      + this.sharedService.encodeData(articleNumber);
    return this.http.get(url);
  }
  // End CH38-Hierarchical BOM view with traceable components flagged

  lumadaProductsDownload(fromDate, toDate, factoryOfOrigin) {
    const url = GlobalURL.LUMADA_PRODUCTS_DOWNLOAD + '?fromDate=' + fromDate + '&toDate=' + toDate + '&factoryGISNo=' + factoryOfOrigin;
    return this.http.get(url);
  }

  lumadaUploadFile(data) {
    const url = GlobalURL.LUMADA_UPLOAD_FILE;
    return this.http.post(url, data, { responseType: 'blob' });
  }

  // Start 212(b), Digital Factory Integrate BOM in DPS Production - US DTB, 02-April-2024
  fetchSAPProductionOrderData(data: any): Observable<any> {
    const url = GlobalURL.FETCH_SAP_PRODUCTION_ORDER_DATA + data.FactoryId;
    return this.http.post(url, data);
  }

  getDigitalComponentsConfiguration(data: any): Observable<any> {
    const url = GlobalURL.GET_DIGITAL_CONFIGURATION + this.sharedService.encodeData(data.productionOrder) +
      '/' + this.sharedService.encodeData(data.articleNumber);
    return this.http.get(url);
  }

  editDigitalComponentsConfiguration(data: any): Observable<any> {
    const url = GlobalURL.EDIT_DIGITAL_CONFIGURATION;
    return this.http.put(url, data);
  }

  checkProductionOrder(data) {
    const url = GlobalURL.CHECK_SAP_PRODUCTION_ORDER_DATA +
      this.sharedService.encodeData(data.ProductionOrder) + '/' + data.FactoryId;
    return this.http.get(url);
  }

  sendSAPAcknowledgement(data) {
    const url = GlobalURL.SEND_SAP_ACKNOWLEDGEMENT + '?productionorders=' + this.sharedService.encodeData(data);
    return this.http.post(url, {});
  }
  // End 212(b), Digital Factory Integrate BOM in DPS Production - US DTB, 02-April-2024

  // Start CR CH3	Multiple Selected documents download with single click, 25-Jan-2024
  getDownloadQCZip(data): Observable<any> {
    const url = environment.downloadQCZip;
    return this.http.post(url, data);
  }

  // End CR CH3	Multiple Selected documents download with single click, 25-Jan-2024

  // Start CH4, Notification Management
  getCategoryList(factoryGisNO: any) {
    const url = GlobalURL.CATEGORY_LIST +
      this.sharedService.encodeData(factoryGisNO);
    return this.http.get(url);
  }

  getUserList(factoryId, categoryId) {
    const url = GlobalURL.USER_LIST + 'factoryId/' +
      this.sharedService.encodeData(factoryId) + '/notificationCategoryId/' + categoryId;
    return this.http.get(url);
  }

  submitNotification(data) {
    const url = GlobalURL.SUBMIT_NOTIFICATION;
    return this.http.post(url, data);
  }

  // End Start CH4, Notification Management

  searchBOMData(array, searchString) {
    return array.filter(item => {
      //
      return Object.keys(item).some(key => {
        if (typeof item[key] === 'string'
          && item[key].toLowerCase().includes(searchString.toLowerCase())) {
          return true;
        }
        //
        if (Array.isArray(item[key])) {
          return item[key].some((element: any) => {

            const values = Object.values(element).map((val) =>
              String(val).toLowerCase(),
            );
            if (
              values.some((val) => val.includes(searchString.toLowerCase()))
            ) {
              return true;
            } else {
              return false;
            }
          });
        }
        return false;

      });
    });
  }

  filterBOMCategoryList(dataSource: any) {
    // let tempData: any[] = [];
    const tempData = dataSource;
    const allBOMChild = tempData.flatMap(item =>
      item.POBOMChildren || []);
    const uniqueChild = dataSource.map(item => `${item.BOMItemCategory} - ${item.BOMItemCategoryDescription}`);
    const uniqueChild2 = allBOMChild.map((item: { BOMItemCategory: any, BOMItemCategoryDescription: string }) =>
      `${item.BOMItemCategory} - ${item.BOMItemCategoryDescription}`);

    const a = [...uniqueChild, ...uniqueChild2];
    const unique = a.filter((value, index, array) => array.indexOf(value) === index);
    // console.log(unique);

    const parseItems = (arr: string[]) => {
      return arr.map(item => {
        const [code, description] = item.split(' - ');
        return { 'ItemCategoryCode': code, 'ItemCategoryDescription': description };
      });
    };

    // return unique;
    return parseItems(unique);
  }

  filterByBOMCategory(array, category) {
    return array.reduce((filteredArray, item) => {

      if (category.includes(item.BOMItemCategory)) {
        filteredArray.push(item);
      }

      if (item.POBOMChildren && item.POBOMChildren.length > 0) {
        const filteredChildren = this.filterByBOMCategory(item.POBOMChildren, category);
        if (category.includes(item.BOMItemCategory)) {
          const newItem = { ...item, POBOMChildren: filteredChildren };
          const index = filteredArray.indexOf(item);
          if (index > -1) {
            filteredArray.splice(index, 1);
          }
          filteredArray.push(newItem);
        }

      }
      return filteredArray;
    },

      []);
  }

}

export class SignalRConnectionInfo {
  url: string;
  accessToken: string;

}

