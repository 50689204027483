import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy' })

export class OrderByQltPipe implements PipeTransform {

    transform(records: Array<any>, args?: any): any {
        return records.sort(function (a, b) {
            if (a[args.property] !== null && b[args.property] !== null) {
                if (a[args.property].toUpperCase() < b[args.property].toUpperCase()) {
                    return -1 * args.direction;
                } else if (a[args.property].toUpperCase() > b[args.property].toUpperCase()) {
                    return 1 * args.direction;
                } else {
                    return 0;
                }
            } else if (a[args.property] == null || b[args.property] == null) {
                return -1 * args.direction;
            }
        });
    }

}
