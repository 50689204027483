import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { MsalService } from '../../../../node_modules/@azure/msal-angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalMsg } from '../globalMsg';
import { HeaderActionService } from './header-action.service';
import { environment } from 'src/environments/environment';
import { AutosaveService } from '../autosave/autosave.service';
import { HomeActionService } from 'src/app/featureModules/home/home-action.service';
import { SignalRService } from '../signalR.service';
import { SharedService } from '../shared.service';
import { CookieService } from 'ngx-cookie-service';
import { DPS_Roles } from '../user-info';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { IncidentDataSharingService } from 'src/app/featureModules/home/incident-data-sharing.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('zoomOnHover', [
      state('initial', style({
        transform: 'scale(1)' // Initial scale
      })),
      state('zoomed', style({
        transform: 'scale(1.08)' // Zoomed scale
      })),
      transition('initial => zoomed', animate('200ms ease-in')), // Animation transition
      transition('zoomed => initial', animate('200ms ease-out')) // Reverse animation transition
    ]),
    trigger('fade', [
      transition('void=>*', [
        style({ opacity: 0 }),
        animate('800ms')
      ])
    ]),
    // trigger('fadeOutOnClick', [
    //   state('default', style({ opacity: 1 })),
    //   state('fadeOut', style({ opacity: 0 })),
    //   transition('default => fadeOut', animate('500ms ease-out'))
    // ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  dpsRolesEnum = DPS_Roles;
  @Output() changeFactory = new EventEmitter<void>();
  @Output() showScreen = new EventEmitter<string>();
  @ViewChild('collapseFactoryDropdown') collapseFactoryDropdown;

  userInfo: any;
  navShow = true;
  userName: string;
  lastLoginDate: any;
  showLoader = false;
  title: string;
  supplierNotificationCount = 0;
  supplierNotificationMessages: any = [];
  supplierNotificationMessagesZeroIDs: any = [];
  role: any;
  advanceSearchNotificationCount: number;
  advanceSearchNotification: any;
  advanceSearchNotificationMsg: any;
  advSearchNotification = false;
  selectedFactoryInfo = '';
  custReportCheckList: { roleName: string; screenNames: string[]; }[];
  factoryArr: string[] = [];

  constructor(
    private router: Router,
    public authService: AuthenticationService,
    private msalService: MsalService,
    private headerActionService: HeaderActionService,
    private route: ActivatedRoute,
    private autoSaveService: AutosaveService,
    private homeService: HomeActionService,
    private signalRService: SignalRService,
    private sharedService: SharedService,
    private cookieService: CookieService,
    private dataSharing: IncidentDataSharingService,
    private activatedRoute: ActivatedRoute,
  ) { }

  // Impersonate
  isImpersonate = false;
  userRole = [{ 'roleName': 'Supplier', 'roleId': 6, 'userGroupId': 2, 'userGroupName': 'Supplier' }];
  userType = [{ 'userTypeId': 4, 'userType': 'Contributor' }, { 'userTypeId': 3, 'userType': 'Reader' }];
  impersonateDetailForm: FormGroup = new FormGroup({});
  userIdFail = GlobalMsg.USER_ID_REQUIRED;
  userIdInvalid = GlobalMsg.USER_ID_INVALID;
  userNameFail = GlobalMsg.USER_NAME_REQUIRED;
  userRoleFail = GlobalMsg.USER_ROLE_REQUIRED;
  userTypeFail = GlobalMsg.USER_TYPE_REQUIRED;
  supplierGuidFail = GlobalMsg.SUPPLIER_GUID_REQUIRED;
  supplierGuidInvalid = GlobalMsg.SUPPLIER_GUID_INVALID;
  supplierGuidNotFound = false;
  isSupplierOrderDetail = false;
  errorMsg;
  showErrorMsg;
  userFactoryDetails = [];
  selectedFactory;
  roleNames = '';
  factoryDetails = '';
  @Input() showFactoryDropdown;
  @Input() hideMenuBar;
  viewFactorySelected = false;
  screenList: { roleName: string; screenNames: string[]; }[] = [];
  screenDetails = [];
  zoomState = 'initial';
  hoveredIndex = -1;
  fadeOut = false;
  factGisNo: string;
  pkFactoryId: string;
  isAdvancedSearch = false;
  sfactoryId = 0;

  ngOnInit() {
    if (this.router.url.includes('advancedsearch')) {
      this.isAdvancedSearch = true;
    } else {
      this.isAdvancedSearch = false;
    }
    this.sfactoryId = parseInt(this.activatedRoute.snapshot.queryParamMap.get('pFactoryID'), 10);
    this.userInfo = this.authService.getUsrInfo();
    this.factGisNo = this.userInfo.factoryId;
    this.pkFactoryId = this.userInfo.pkFactoryId;
    if (environment.title !== '') {
      document.title = environment.title;
    }
    this.title = environment.title;
    if (this.userInfo.lastLoginDate.slice(-1) === 'Z') {
      this.lastLoginDate = new Date(this.userInfo.lastLoginDate);
    } else {
      this.lastLoginDate = new Date(this.userInfo.lastLoginDate + 'Z');
    }

    // impersonate user check
    this.initiateForm();
    this.roleNames = this.sharedService.getRoleNames(this.userInfo).join('\n');
    if (this.userInfo.impersonate === true) {
      if (this.userInfo.isImpersonated === true) {
        this.roleNames = 'Supplier';
        this.isImpersonate = true;
        this.userInfo.isImpersonated = true;
      } else {
        this.isImpersonate = false;
        this.userInfo.isImpersonated = false;
      }
      sessionStorage.setItem('userData', JSON.stringify(this.userInfo));
    }

    if (this.autoSaveService.subVar2 === undefined) {
      this.autoSaveService.subVar2 = this.autoSaveService.logoutFunction2
        .subscribe(() => {
          this.msalService.logout();
          sessionStorage.clear();
        });
    }
    if (this.authService.isRolePresent(DPS_Roles.Supplier) || this.userInfo.isImpersonated) {
      this.getSupplierNotificationCount();
      if (this.route.snapshot.routeConfig.path ===
        'order-list/order-details/:orderStatus/:id/:supplierId/:isEnforce/:isInspection/:revisionNumber') {
        this.isSupplierOrderDetail = true;
      } else {
        this.isSupplierOrderDetail = false;
      }
    } else if (this.authService.isRolePresent(DPS_Roles.System_Configurator, DPS_Roles.Quality_Controller
      , DPS_Roles.Factory_Configurator, 'Field Operator')) {
      this.advSearchNotification = true;
      this.getAdvanceSearchNotificationMessage();
    }
    this.getUserFactoryDetails();
    this.viewFactorySelected = !this.showFactoryDropdown;
    this.setTiles(this.userInfo);
  }

  getUserFactoryDetails() {
    if (this.showFactoryDropdown && !this.authService.isRolePresent(DPS_Roles.System_Configurator)) {
      this.userFactoryDetails = JSON.parse(sessionStorage.getItem('userData')).FactoryDetail;
      this.userFactoryDetails = this.userFactoryDetails.sort(function (a, b) {
        const textA = a.FactoryName.toUpperCase();
        const textB = b.FactoryName.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      this.factoryDetails = this.userFactoryDetails.map(ele => ele.FactoryName).join(',');
      if (this.factoryDetails) {
        this.factoryArr = this.factoryDetails.split(',');
        this.factoryArr = (this.factoryArr[0]) ? this.factoryArr : [];
      } else {
        this.factoryArr = [];
      }

      this.selectedFactory = this.userFactoryDetails[0];
      let userData = null;
      userData = JSON.parse(sessionStorage.getItem('userData'));
      if (userData.factoryId || userData.pkFactoryId || userData.factoryName) {
        this.selectedFactory = {};
        this.selectedFactory.FactoryId = userData.pkFactoryId;
        this.selectedFactory.FactoryName = userData.factoryName;
        this.selectedFactory.FactoryGISNo = userData.factoryId;
        this.changeFactory.emit(userData);
        // CR 212(b), Digital Factory Integrate BOM in DPS Production - US DTB, 02-April-2024
        userData['IsSAPSN'] = this.selectedFactory.IsSAPSN;
      } else {
        userData.factoryId = this.selectedFactory.FactoryGISNo;
        userData.factoryName = this.selectedFactory.FactoryName;
        userData.pkFactoryId = this.selectedFactory.FactoryId;
        // CR 212(b), Digital Factory Integrate BOM in DPS Production - US DTB, 02-April-2024
        userData['IsSAPSN'] = this.selectedFactory.IsSAPSN;
        setTimeout(() => {
          sessionStorage.setItem('userData', JSON.stringify(userData));
        }, 1000);
      }
      // userData = JSON.parse(localStorage.getItem('userData'));
      // userData.factoryId = this.selectedFactory.FactoryGISNo;
      // userData.factoryName = this.selectedFactory.FactoryName;
      // userData.pkFactoryId = this.selectedFactory.FactoryId;
      // CR 212(b), Digital Factory Integrate BOM in DPS Production - US DTB, 02-April-2024
      // // userData['IsSAPSN'] = this.selectedFactory.IsSAPSN;
      this.factGisNo = this.selectedFactory.FactoryGISNo;
      this.pkFactoryId = this.selectedFactory.FactoryId;
      // setTimeout(() => {
      //   localStorage.setItem('userData', JSON.stringify(userData));
      // }, 10);
    } else {
      // Changes for undefined issue during incident log and CCRP redirection
      if (!isNaN(this.sfactoryId) && this.sfactoryId !== 0) {
        this.userFactoryDetails = JSON.parse(sessionStorage.getItem('userData')).FactoryDetail;
        let filteredFactory = null;
        filteredFactory = this.userFactoryDetails.filter(e => e.FactoryId === this.sfactoryId);
        this.selectedFactory = {
          'FactoryId': filteredFactory[0].FactoryId,
          'FactoryName': filteredFactory[0].FactoryName,
          'FactoryGISNo': filteredFactory[0].FactoryGISNo
        };

        this.factoryArr = filteredFactory[0].FactoryName.split(',');
        this.factoryArr = (this.factoryArr[0]) ? this.factoryArr : [];
        this.factoryChange(filteredFactory[0]);
      } else {
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        this.factoryDetails = userData.factoryName;
        if (this.factoryDetails) {
          this.factoryArr = this.factoryDetails.split(',');
          this.factoryArr = (this.factoryArr[0]) ? this.factoryArr : [];
          this.factoryArr = this.factoryArr.sort(function (a, b) {
            const textA = a.toUpperCase();
            const textB = b.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
        } else {
          this.factoryArr = [];
        }

        this.selectedFactory = {
          'FactoryId': userData.pkFactoryId,
          'FactoryName': userData.factoryName,
          'FactoryGISNo': userData.factoryId
        };
      }

    }
    this.selectedFactoryInfo = this.selectedFactory.FactoryName + '-' + this.selectedFactory.FactoryGISNo;
  }

  factoryChange(factory) {
    this.selectedFactory = factory;

    let userData = null;
    userData = JSON.parse(sessionStorage.getItem('userData'));
    userData.factoryId = this.selectedFactory.FactoryGISNo;
    userData.factoryName = this.selectedFactory.FactoryName;
    userData.pkFactoryId = this.selectedFactory.FactoryId;
    // CR 212(b), Digital Factory Integrate BOM in DPS Production - US DTB, 02-April-2024
    userData['IsSAPSN'] = this.selectedFactory.IsSAPSN;
    setTimeout(() => {
      sessionStorage.setItem('userData', JSON.stringify(userData));
    }, 1000);

    // userData = JSON.parse(localStorage.getItem('userData'));
    // userData.factoryId = this.selectedFactory.FactoryGISNo;
    // userData.factoryName = this.selectedFactory.FactoryName;
    // userData.pkFactoryId = this.selectedFactory.FactoryId;
    // // CR 212(b), Digital Factory Integrate BOM in DPS Production - US DTB, 02-April-2024
    // userData['IsSAPSN '] = this.selectedFactory.IsSAPSN;
    // setTimeout(() => {
    //   localStorage.setItem('userData', JSON.stringify(userData));
    // }, 10);
    this.factGisNo = this.selectedFactory.FactoryGISNo;
    this.pkFactoryId = this.selectedFactory.FactoryId;
    this.selectedFactoryInfo = this.selectedFactory.FactoryName + '-' + this.selectedFactory.FactoryGISNo;
    // console.log('header', this.pkFactoryId);
    this.changeFactory.emit(userData);
  }

  logoNav() {
    localStorage.removeItem('appliedFilters');
    localStorage.removeItem('reportingProductionTimeCaptureSearchCriteria');
    if (this.userInfo.impersonate && this.isImpersonate) {
      this.router.navigateByUrl('/' + 'orders');
    } else {
      this.router.navigateByUrl('/' + this.authService.setInitialUsrInfo('', ''));
    }
  }

  logout() {
    localStorage.removeItem('appliedFilters');
    localStorage.removeItem('reportingProductionTimeCaptureSearchCriteria');
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.deleteAll();
    this.autoSaveService.isLoggedOut = true;
    if (this.route.snapshot.routeConfig.path === 'processdetail/:an/:sn/:fid') {
      this.autoSaveService.onLogoutFunction();
    } else {
      this.msalService.logout();
      sessionStorage.clear();
      console.log('logout');
    }
  }

  // Initiate impersonate form
  initiateForm() {
    this.impersonateDetailForm = new FormGroup({
      user_email_id: new FormControl(null, [
        Validators.pattern('[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*'),
        Validators.required]),
      user_name: new FormControl(null, Validators.required),
      user_role: new FormControl(null, Validators.required),
      user_type: new FormControl(null, Validators.required),
      supplier_guid: new FormControl(null, [Validators.required, Validators.minLength(3),
      Validators.maxLength(20),
      ])
    });
  }
  hideError() {
    this.supplierGuidNotFound = false;
  }

  // impersonate user on send click
  impersonateUser() {
    this.showLoader = true;
    this.advSearchNotification = false;
    sessionStorage.setItem('userType', this.impersonateDetailForm.value.user_type);
    this.headerActionService.impersonateUser(this.impersonateDetailForm.value)
      .subscribe((data) => {
        if (data.supplierId === null) {
          this.showLoader = false;

          this.supplierGuidNotFound = true;
          return false;
        }
        this.supplierGuidNotFound = false;
        // store old user data in session storage
        sessionStorage.setItem('userDataTemp', JSON.stringify(this.userInfo));
        this.userInfo.isImpersonated = false;
        this.userInfo.supplierId = data.supplierId;
        this.userInfo.supplierName = data.supplierName;
        this.userInfo.userType = data.userType;
        this.userInfo.pkSupplierId = data.pkSupplierId;
        this.userInfo.roleId = data.roleId;
        this.userInfo.roleName = data.roleName;
        this.userInfo.firstName = this.impersonateDetailForm.value.user_name;
        this.userInfo.lastName = '';
        this.isImpersonate = true;
        this.userInfo.isImpersonated = true;
        sessionStorage.setItem('userData', JSON.stringify(this.userInfo));
        localStorage.setItem('supplierId', data.supplierId);
        localStorage.removeItem('appliedFilters');
        localStorage.removeItem('reportingProductionTimeCaptureSearchCriteria');
        $('#impersonateModal').modal('hide');
        this.showLoader = false;
        this.router.navigate(['./orders']);
      },
        (error) => {
          console.log(error);
          this.showLoader = false;
          $('#impersonateModal').modal('hide');
          this.showErrorMsg = true;
          // this.errorMsg = error.error;
          this.errorMsg = this.sharedService.getErrorMessage(error);
        });
  }

  // Stop impersonate user
  exitImpersonate() {
    this.showLoader = true;
    this.isImpersonate = false;
    const userInfoTemp = JSON.parse(sessionStorage.getItem('userDataTemp'));
    userInfoTemp.isImpersonated = false;
    sessionStorage.removeItem('userDataTemp');
    sessionStorage.setItem('userData', JSON.stringify(userInfoTemp));
    localStorage.removeItem('appliedFilters');
    this.router.navigate(['home']);
    this.advSearchNotification = true;
    this.showLoader = false;
  }

  getSupplierNotificationCount() {
    this.headerActionService.getSupplierNotificationCount(this.userInfo.supplierId).subscribe(
      (Response) => {
        this.supplierNotificationCount = Response;
      },
      (error) => {

      }
    );
  }

  getSupplierNotificationMessage() {
    if (this.supplierNotificationCount <= 0) {
      return;
    }
    this.supplierNotificationMessages = [];
    this.supplierNotificationMessagesZeroIDs = [];
    this.headerActionService.getSupplierNotificationMessage(this.userInfo.supplierId).subscribe(
      (Response) => {
        this.supplierNotificationMessages = Response.sort((d1, d2) =>
          (d1.notificationId > d2.notificationId ? -1 : 1));
        if (this.supplierNotificationMessages === '') {
          this.supplierNotificationMessages = [];
        }
        for (const message of this.supplierNotificationMessages) {
          const index = message.notificationMessage.indexOf(message.orderLineNumber);
          const orderLineLength = message.orderLineNumber.length;
          message['prefix'] = message.notificationMessage.substring(0, index);
          message['suffix'] = message.notificationMessage.substring(index + orderLineLength);
          if (message.notificationId === 0) {
            this.supplierNotificationMessagesZeroIDs.push(message);
          }
        }
        this.supplierNotificationMessages = this.supplierNotificationMessages.filter(
          (message) => {
            return message.notificationId > 0;
          }
        );
      },
      (error) => {

      }
    );
  }

  navToDetail(data) {
    if (data.isSplit) {
      localStorage.setItem('isSplit', data.orderLineNumber);
      const fullPath = window.location.href;
      const val = fullPath.split(environment.redirectURi + '/#')[1].slice
        (1, fullPath.split(environment.redirectURi + '/#')[1].length);
      if ('orders/order-list' === val) {
        location.reload();
      } else {
        this.router.navigateByUrl('/orders/order-list');
      }
    } else {
      let groupingStatus = '';
      if (data.groupingStatus) {
        groupingStatus = data.groupingStatus;
      } else {
        groupingStatus = 'false';
      }
      this.router.navigate(['./orders/order-list/order-details',
        data.orderStatus, data.orderLineNumber.trim(), data.supplierGUID.trim(),
        data.isEnforce, data.isInspection, data.revisionNumber.trim()],
        {
          queryParams: {
            'groupingStatus': groupingStatus
          }
        });
    }
  }


  clearNotification(data) {
    const notificationIDs = [];
    if (data === 'All') {
      for (const message of this.supplierNotificationMessages) {
        notificationIDs.push(message.notificationId);
      }
    } else {
      notificationIDs.push(data.notificationId);
    }
    this.headerActionService.clearSupplierNotification({ 'NotificationIDs': notificationIDs }).subscribe(
      (response) => {
        this.getSupplierNotificationCount();
        this.getSupplierNotificationMessage();
      }
    );
  }

  preventNotificationPopupClosing() {
    setTimeout(() => {
      $('.mat-badge.mat-badge-overlap.mat-badge-above.mat-badge-after.mat-badge-medium')[0].classList.add('open');
    }, 0);
  }
  getAdvanceSearchNotificationMessage() {
    this.subscribeToEvents();
    this.headerActionService.getAdvanceSearchNotificationMessage().subscribe(
      (Response) => {
        // console.log(Response);
        this.showLoader = false;
        if (Response.Notifications !== null) {
          this.advanceSearchNotificationCount = Response.Notifications.length;
          this.advanceSearchNotificationMsg = Response.Notifications;
        } else {
          this.advanceSearchNotificationCount = 0;
        }
      },
      (error) => {
        console.log(error);
      });
  }
  clearAdvSearchNotification(id) {
    this.showLoader = true;
    let obj = {};
    if (id === 'All') {
      // console.log(this.advanceSearchNotificationMsg);
      obj = {
        'notificationId': (this.advanceSearchNotificationMsg.map(item => item.NotificationId)).join(',')
      };
    } else {
      obj = {
        'notificationId': id
      };
    }
    this.headerActionService.clearAdvanceSearchNotification(obj).subscribe(
      (Response) => {
        this.getAdvanceSearchNotificationMessage();
      }, (error) => {
        console.log(error);
      });
  }
  subscribeToEvents(): void {
    this.signalRService.messageReceived.subscribe((message: any) => {
      const data = [] = JSON.parse(JSON.parse(message));
      this.advanceSearchNotificationCount = 0;
      this.advanceSearchNotificationMsg = [];
      this.advanceSearchNotificationCount = data.Notifications.length;
      this.advanceSearchNotificationMsg = data.Notifications;
    });
  }
  downloadBlob(url, attachmentName) {
    this.showLoader = true;
    this.homeService.blobDownload(url, attachmentName).subscribe((Response) => {
      this.showLoader = false;
      const fileName = attachmentName.substring(0, attachmentName.lastIndexOf('.'));
      const fileType = attachmentName.substring(attachmentName.lastIndexOf('.') + 1);
      this.sharedService.downloadDocument(fileName, fileType, Response);
    });
  }


  setTiles(userInfo) {
    if (userInfo.Admin) {
      this.screenDetails = userInfo.SystemConfiguratorScreenDetails;
    } else {
      this.screenDetails = userInfo.UserScreenDetails;
    }
    const transformedData: { [key: string]: { roleName: string; screenNames: string[] } } = {};
    this.screenDetails.forEach(obj => {
      if (transformedData[obj.RoleName]) {
        transformedData[obj.RoleName].screenNames.push(obj.ScreenName);
      } else {
        transformedData[obj.RoleName] = { roleName: obj.RoleName, screenNames: [obj.ScreenName] };
      }
    });
    this.screenList = Object.values(transformedData);
    this.custReportCheckList = JSON.parse(JSON.stringify(Object.values(transformedData)));
    if (userInfo.factoryId !== '9AAV120121' && userInfo.userGroupName !== 'AHIT') {
      // this.showConfigIncTile = true;

      this.screenList.forEach(z => {
        z.screenNames = z.screenNames.filter(y => {
          return y !== 'Configure Incident' && y !== 'Incident Log';
        });

      });
    }


    // Stuff like this needs refactoring later, as all of this data is completely dependent on session storage
    if (userInfo.factoryId === '9AAV104547') {
      this.custReportCheckList.forEach(z => {
        const custrep = z.screenNames.filter(screenName => {
          return screenName.toLowerCase() === 'Customer Report'.toLowerCase();
        });
        if (custrep.length > 0) {
          sessionStorage.setItem('customerReportFlag', 'showCustRep');
        }

      });
    } else {
      sessionStorage.removeItem('customerReportFlag');
    }

    this.screenList.forEach(z => {
      z.screenNames = z.screenNames.filter(y => {
        return y !== 'Customer Report';
      });

    });

  }

  onDivClick() {
    this.fadeOut = true;
  }


  openScreen(screenName: string) {

    // For production operator
    if (screenName === 'Register new product'.toLowerCase()) {
      this.router.navigateByUrl('/home/register');
    }
    if (screenName === 'Process production steps'.toLowerCase()) {
      this.router.navigateByUrl('/home/process');
    }
    if (screenName === 'Unfinished products'.toLowerCase()) {
      this.router.navigateByUrl('/home/unfinished-SN');
    }
    if (screenName === 'Unstarted products'.toLowerCase()) {
      this.router.navigateByUrl('/home/unstarted-product');
    }
    if (screenName === 'Final testing certificate upload'.toLowerCase()) {
      this.router.navigateByUrl('/home/final-testing-certificate');
    }

    if (screenName === 'Product without SO'.toLowerCase()) {
      this.router.navigateByUrl('/home/product-without-SO');
    }

    if (screenName === 'Associate products to customer order'.toLowerCase()) {
      this.router.navigateByUrl('/home/associate');
    }

    if (screenName === 'Replicate production data'.toLowerCase()) {
      this.router.navigateByUrl('/home/replicate-production-data');
    }

    // For Quality Controller , Need to add route
    if (screenName === 'Product document download'.toLowerCase()) {
      this.router.navigateByUrl('/home/document-download');
    }
    if (screenName === 'Supplier certs review'.toLowerCase()) {
      this.router.navigateByUrl('/home/certs-review');
    }
    if (screenName === 'Non conformity'.toLowerCase()) {
      this.router.navigateByUrl('/home/withdraw-products');
    }
    // For factory configurator
    if (screenName === 'Onboard/Edit Component'.toLowerCase()) {
      this.router.navigateByUrl('/admin/component');
    }

    if (screenName === 'Onboard Supplier'.toLowerCase()) {
      this.router.navigateByUrl('/admin/supplier');
    }
    if (screenName === 'Onboard Customer'.toLowerCase()) {
      this.router.navigateByUrl('/admin/customer');
    }

    if (screenName === 'View User'.toLowerCase()) {
      this.router.navigateByUrl('/admin/user');
    }

    if (screenName === 'View Factory'.toLowerCase()) {
      this.router.navigateByUrl('/admin/factory');
    }

    // For System COnfigurator

    if (screenName === 'Onboard/Edit User'.toLowerCase()) {
      this.router.navigateByUrl('/admin/user');
    }

    if (screenName === 'Onboard/Edit Factory'.toLowerCase()) {
      if (this.screenList.some(item => item.roleName === DPS_Roles.System_Configurator)) {
        this.router.navigateByUrl('/admin/factory');
      } else {
        // this.router.navigateByUrl('/admin/factory/add-editfactory/' + this.factGisNo + '/' + this.pkFactoryId);
        this.router.navigateByUrl('/admin/factory/add-editfactory');
      }
    }
    if (screenName === 'Onboard/Edit Product'.toLowerCase()) {
      this.router.navigateByUrl('/admin/product');
    }

    if (screenName === 'Configure Product'.toLowerCase()) {
      this.dataSharing.sendData(true);
      sessionStorage.setItem('showScreen', 'Product');
      this.showScreen.emit(sessionStorage.getItem('showScreen'));
      this.router.navigateByUrl('/admin/factory/add-editfactory/' + this.factGisNo + '/' + this.pkFactoryId);
    }

    if (screenName === 'Onboard Supplier'.toLowerCase()) {
      this.router.navigateByUrl('/admin/supplier');
    }

    if (screenName === 'Onboard Customer'.toLowerCase()) {
      this.router.navigateByUrl('/admin/customer');
    }

    if (screenName === 'Configure incident'.toLowerCase()) {
      sessionStorage.setItem('showScreen', 'Incident');
      this.showScreen.emit(sessionStorage.getItem('showScreen'));
      this.router.navigateByUrl('/admin/factory/add-editfactory/' + this.factGisNo + '/' + this.pkFactoryId);
    }

    if (screenName === 'Notification Management'.toLowerCase()) {
      this.router.navigateByUrl('/home/notification-management');
    }

    if (screenName === 'Incident log'.toLowerCase()) {
      this.router.navigateByUrl('/home/incident-log');
    }
    if (screenName === 'Data Quality governance'.toLowerCase()) {
      this.router.navigateByUrl('/admin/dataGovernanceSearch');
    }
    if (screenName === 'Incoming quality control'.toLowerCase()) {
      this.router.navigateByUrl('/home/incomingqc');
    }
    if (screenName === 'Lumada reports'.toLowerCase()) {
      this.router.navigateByUrl('/home/lumadaReport');
    }

    if (screenName === 'Production time report'.toLowerCase()) {
      this.router.navigateByUrl('/home/recorded-production-time');
    }

    if (screenName === 'RTI Dashboard'.toLowerCase()) {
      this.router.navigateByUrl('/admin/rti-search-dashboard');
    }


    if (screenName === 'Order List'.toLowerCase()) {
      this.router.navigateByUrl('/orders/order-list');
    }
    if (screenName === 'Barcode utility'.toLowerCase()) {
      this.router.navigateByUrl('/orders/generate-barcode');
    }

    if (screenName === 'Order performance history'.toLowerCase()) {
      this.router.navigateByUrl('/orders/order-performance-dashboard');
    }

    if (screenName === 'Documents'.toLowerCase()) {
      this.router.navigateByUrl('/orders/search-documents');
    }

  }

  ngOnDestroy() {
    $('#dashboardOptionModal').modal('hide');
  }

  offClickHandler(e) {
    if (this.collapseFactoryDropdown !== undefined && !this.collapseFactoryDropdown.nativeElement.contains(e.target)) {
      $('#collapseFactoryDropdown').removeClass('in');
    }

  }
}
