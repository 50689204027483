import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MsalService } from '../../../node_modules/@azure/msal-angular';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/mergeMap';
import { AuthenticationService } from './authentication/authentication.service';
import { SharedService } from 'src/app/globalModule/shared.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceInterceptor implements HttpInterceptor {
  constructor(private MSAL: MsalService, private authenticationService: AuthenticationService, private sharedService: SharedService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    let scope = [environment.scopes[1]];
    if (request.url.includes('graph.microsoft.com/v1.0/users')) {
      scope = ['User.ReadBasic.All'];
    } else if (request.url.includes('graph.microsoft.com')) {
      scope = ['User.Read'];
    }
    // // var tokenStored = this.MSAL.getCachedTokenInternal(scope);
    const currentUser = this.authenticationService.getUsrInfo();
    const id = currentUser.userId;
    if (!request.url.includes('graph.microsoft.com')) {
      request = request.clone({
        setHeaders: {
          'Ocp-Apim-Subscription-Key': environment.subscription
        }
      });
      if (currentUser) {
        request = request.clone({
          setHeaders: {
            UserId: id
          }
        });
      }
    }
    if (request.url.includes('configureuseredit') || request.url.includes('configureusersave') ||
      request.url.includes('configureedit') || request.url.includes('configuresave') ||
      request.url.includes('RemoveConfiguration') || request.url.includes('RemoveProduct') ||
      request.url.includes('AddProductsToFactory') || request.url.includes('EditConfiguration') ||
      request.url.includes('ShareConfiguration') || request.url.includes('DeleteProductStep') ||
      request.url.includes('AddProduct') || request.url.includes('UpdateLastLoginDateForUser') ||
      request.url.includes('order-management/orders/upload') ||
      request.url.includes('translation/globalMsg') ||
      request.url.includes('actas/AddActasTemplate') || request.url.includes('actas/UpdateActasTemplate/templateId')) {
      request = request.clone({
        responseType: 'text',
        headers: request.headers.set('Content-Type', 'application/json')
      });
    } else if (request.url.includes('barcode-management/barcode') ||
      request.url.includes('production-management/barcodeimage') ||
      request.url.includes('quality/DownloadIQC') ||
      request.url.includes('printproductionconfiguration/products') ||
      request.url.includes('quality-controller-management/quality/order') || request.url.includes('dummyApi/multiCC/articles')) {
      request = request.clone({
        responseType: 'blob',
        headers: request.headers.set('Content-Type', 'application/json')
      });
    } else if (request.url.includes('notes/documents/articles') ||
      request.url.includes('documents/order/orderlines') ||
      request.url.includes('installation-management/savecomponent/orders/')) {
      request = request.clone({
        headers: request.headers.set('MachineIP', 'abcd')
      });

    } else if (request.url.includes('advancesearch-endproduct-index')
      || request.url.includes('advance-search-subassembly-index')
      || request.url.includes('advancesearch-components-index')
      || request.url.includes('advancesearch-componentbn-index')
      || request.url.includes('advancesearch-product-index')
      || request.url.includes('productonemonth-index')
      || request.url.includes('product0to3month-index')
      || request.url.includes('product3to6month-index')
      || request.url.includes('product6to12month-index')
      || request.url.includes('productgt1y-index')
      || request.url.includes('componentonemonth-index')
      || request.url.includes('component0to3month-index')
      || request.url.includes('component3to6month-index')
      || request.url.includes('component6to12month-index')
      || request.url.includes('componentgt1y-index')
      || request.url.includes('advancesearch-component1-index')
      || request.url.includes('advancesearch-componentbn-index')
      || request.url.includes('product-index')
      || request.url.includes('component-index')
    ) {

      const headers = new HttpHeaders({
        'api-key': environment.azureSearchKey,
        // 'Ocp-Apim-Subscription-Key': environment.subscription
      });

      request = request.clone({ headers });
    } else if (request.url.includes('common-management/downloadblob')
      || request.url.includes('common-management/downloadblob/customreport')) {
      request = request.clone({
        responseType: 'arraybuffer'
      });
    } else if (request.url.includes('barcode-management/barcode/order')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'text/plain')
      });
    } else if (request.url.includes('pdfdownload')) {
      request = request.clone({
        responseType: 'blob',
        headers: request.headers.set('Accept', 'application/pdf')
      });
    } else if (request.url.includes('api/negotiate')) {
      request = request.clone({
        setHeaders: {
          'x-my-custom-header': localStorage.getItem('xECMHeader'),
        }
      });
      localStorage.removeItem('xECMHeader');
    } else if (request.url.includes('api/zip')) {
      request = request.clone({
        setHeaders: {
          // 'x-my-custom-header': localStorage.getItem('xECMHeader'),
        }
      });
    }
    return Observable.fromPromise(this.MSAL.acquireTokenSilent(scope).then(token => {
      localStorage.setItem('msal.idtoken', token);
      // console.log(token);
      const JWT = `Bearer ${token}`;
      request = request.clone({
        setHeaders: {
          Authorization: JWT,
        },
      });
      if (request.url.includes('graph.microsoft.com')) {
        console.log('headers-', request.headers);
      }
      return request;
      /* tslint:disable */
    })).mergeMap(request => next.handle(request).do(event => { }, err => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        this.MSAL.acquireTokenPopup(scope).then(token => {
          localStorage.setItem('msal.idtoken', token);
          const JWT = `Bearer ${token}`;
          return request.clone({
            setHeaders: {
              Authorization: JWT,
            },
          });
        });
      }
    }));
  }

}
