import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalURL } from '../globalUrl';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { environment } from 'src/environments/environment';
import { Session } from 'protractor';
import { SharedService } from '../shared.service';
import { DPS_Roles } from '../user-info';


@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService) { }

  // roleArray = [
  //   { 'System Configurator': ['admin', 'home'] },
  //   { 'Factory Configurator': ['admin', 'home'] },
  //   { Admin: ['admin', 'home', 'orders'] },
  //   { Supplier: ['orders'] },
  //   { 'Quality Controller': ['home', 'orders', 'admin'] },
  //   { 'Production Operator': ['home'] },
  //   { 'Field Operator': ['home'] },
  //   { Customer: ['customer', 'home'] },
  //   { 'Dispatch Supervisor': ['home'] },
  //   { 'Data Controller': ['/admin/dataGovernanceSearch'] },
  //   { 'IQCAdmin': ['admin'] },
  //   { 'Sub-contractor': ['orders'] }
  // ];

  roleArray = {
    [DPS_Roles.System_Configurator]: ['home', 'admin'],
    [DPS_Roles.Factory_Configurator]: ['home', 'admin'],
    'Admin': ['admin', 'home', 'orders'],
    [DPS_Roles.Supplier]: ['orders'],
    [DPS_Roles.Quality_Controller]: ['home', 'orders', 'admin'],
    [DPS_Roles.Production_Operator]: ['home'],
    'Field Operator': ['home'],
    Customer: ['customer', 'home'],
    'Dispatch Supervisor': ['home'],
    'Data Controller': ['/admin/dataGovernanceSearch'],
    'IQCAdmin': ['admin'],
    'Sub-contractor': ['orders']
  };
  // for Data governanceId restriction
  idsArray = [
    'pankaj.gupta@ch.abb.com',
    'abhijeet.sawant@ch.abb.com',
    'ragini.daphale@in.abb.com',
    'amit.kumar3@ch.abb.com',
    'mishra.ankita@ch.abb.com',
    'sanjay.vadhel@in.abb.com',
    'aatish.mistry@in.abb.com',
    'rasiyaraj.subramaniyan@in.abb.com',
    'arun.j1@in.abb.com',
    'anthony.ranjith@in.abb.com',
    'rajiv.vijayan@in.abb.com',
    'kunal.samant@in.abb.com',
    'sanjay.vadhel@in.abb.com',
    'aatish.mistry@in.abb.com',
    'manh.tran-van@vn.abb.com',
    'giang.nguyen-bang@vn.abb.com',
    'aayushi.gupta1@in.abb.com',
    'umang.saxena@in.abb.com',
    'divya.sahu@in.abb.com',
    'nikhil.goplani@in.abb.com',
    'sampada.pandurangi@in.abb.com',
    'samiksha.desai@in.abb.com',
    'sampurna-nand.srivastav@in.abb.com',
    'dipesh.k.joshi@hitachienergy.com',
    'prashant.mali@hitachienergy.com',
    'dharmendra.kumbhar@hitachienergy.com',
    'tho.nguyen-truong@vn.abb.com',
    'vo.doan-tuan@vn.abb.com',
    'henrik.dahlgren@hitachienergy.com',
    'nils.weiss@hitachienergy.com',
    'akshay.shrivastava@in.abb.com',
    'durgesh.gupta1@in.abb.com',
    'deepak.sharma2@in.abb.com',
    'Arun.jeevanandam@in.abb.com',
    'mishra.ankita@in.abb.com',
    'prachi.gupta2@in.abb.com',
    'gururaj.v.kulkarni@in.abb.com',
    'vaibhav.kulkarni@in.abb.com',
    'ikramulla.khan@in.abb.com'
  ];

  qualityCotrollerOrFactoryAdminUsersForDQG = [
    'aatish.mistry@hitachienergy.com',
    'ketan.pethe@hitachienergy.com',
    'vaibhav.salvi@hitachienergy.com',
    'tushar.patel@hitachienergy.com',
    'ankit.varia@hitachienergy.com',
    'sanjay.vadhel@hitachienergy.com',
    'arun.j1@in.abb.com',
    'kunal.samant@in.abb.com',
    'mishra.ankita@ch.abb.com',
    'aatish.mistry@in.abb.com',
    'ketan.pethe@in.abb.com',
    'vaibhav.salvi@in.abb.com',
    'tushar.patel@in.abb.com',
    'ankit.varia@in.abb.com',
    'sanjay.vadhel@in.abb.com',
    'omprakash.gawande@in.abb.com',
    'vedprakash.rai@in.abb.com',
    'jasmin.m.desai@in.abb.com',
    'manh.tran-van@vn.abb.com',
    'quan.nguyenlong@vn.abb.com',
    'tho.nguyen-truong@vn.abb.com',
    'divya.sahu@in.abb.com',
    'nikhil.goplani@in.abb.com',
    'umang.saxena@in.abb.com',
    'sampada.pandurangi@in.abb.com',
    'samiksha.desai@in.abb.com',
    'sampurna-nand.srivastav@in.abb.com',
    'dipesh.k.joshi@hitachienergy.com',
    'prashant.mali@hitachienergy.com',
    'dharmendra.kumbhar@hitachienergy.com',
    'aayushi.gupta1@in.abb.com',
    'vo.doan-tuan@vn.abb.com',
    'ragini.daphale@in.abb.com',
    'anthony.ranjith@in.abb.com',
    'henrik.dahlgren@hitachienergy.com',
    'nils.weiss@hitachienergy.com',
    'akshay.shrivastava@in.abb.com',
    'durgesh.gupta1@in.abb.com',
    'deepak.sharma2@in.abb.com',
    'Arun.jeevanandam@in.abb.com',
    'mishra.ankita@in.abb.com',
    'prachi.gupta2@in.abb.com',
    'gururaj.v.kulkarni@in.abb.com',
    'vaibhav.kulkarni@in.abb.com',
    'ikramulla.khan@in.abb.com'
  ];

  // updated ids
  adminUsersForProductDocumentDownload = [
    'ragini.daphale@hitachienergy.com',
    'anthony.ranjith@hitachienergy.com',
    'sampada.pandurangi@hitachienergy.com',
    'aayushi.gupta1@hitachienergy.com',
    'Arun.jeevanandam@hitachienergy.com'
  ];
  // Factory list for Dispaly QualityCard
  factoryForDisplayQualityCard = [
    '9AAV101500',
    '9AAV101299',
    '9AAV104620',
    '9AAV100294',
    '9AAV104547',
    '9AAV104348',
    '9AAV104617',
    '9AAV104620',
    '9AAV101034',
    '9AAV104217'
  ];

  allowedFactoriesForAcceptableQCChecksOptions = [
    '9AAV101299',
    '9AAV101500'
  ];

  // Start 154 (c) 154 (d), Recording/Reporting production incidents, 5 Oct 2023
  allowedFactoriesForIncidentManagement = [
    '9AAVH1216',
    '9AAV120121'
  ];
  // End 154 (c) 154 (d), Recording/Reporting production incidents, 5 Oct 2023

  // Start CR 241, Sales order with SD position, 1 Nov 2023
  allowedFactoriesForSDPosition = [
    '9AAV104620',
    '9AAV104621'
  ];
  // End CR 241, Sales order with SD position, 1 Nov 2023

  // Factory for DQG component search
  allowedFactoriesForDQGComponentSearch = [
    '9AAV104617'
  ];

  getUserDetails(email): Observable<any> {
    const encodeEmail = this.sharedService.encodeData(email);
    const url: string = GlobalURL.LOGIN + '/' + encodeEmail;
    return this.http.get(url);
  }

  getUserInfoFromGraph(): Observable<any> {
    const url = 'https://graph.microsoft.com/v1.0/me';
    return this.http.get(url);
  }

  getCCRPDetails() {
    // let clientId, clientSecret, isCCRP;
    let isCCRP1 = 'no';
    this.activatedRoute.queryParams.subscribe(params => {
      const params1 = {};
      for (const i of Object.keys(params)) {
        const temp = params[i];
        params1[i.toLowerCase()] = temp;
      }
      params = params1;
      if (Object.keys(params).length !== 0) {
        if (params.installationid !== undefined && params.appid !== undefined) {
          if (params.installationid === '') {
            sessionStorage.setItem('Error', 'Installation Id is not valid');
          } else if (params.appid !== environment.ServIsAppId) {
            sessionStorage.setItem('Error', 'App Id is not valid');
          } else {
            sessionStorage.setItem('ServIsInstallationId', JSON.stringify(params.installationid));
            sessionStorage.setItem('ServIsSerialNo', JSON.stringify(params.serialno));
            sessionStorage.setItem('ServIsAppId', JSON.stringify(params.appid));
          }
        } else {
          if (params.appid === undefined || atob(params.appid) !== environment.CCRPAppId) {
            if (params.appid === undefined) {
              sessionStorage.setItem('Error', 'Please provide App Id');
            } else {
              sessionStorage.setItem('Error', 'App Id is not valid');
            }
          } else {
            sessionStorage.setItem('CCRPAppId', JSON.stringify(atob(params.appid)));
            sessionStorage.setItem('isCCRP', JSON.stringify('yes'));
            if (params.serialnumber === undefined && params.ccrpnumber === undefined
              && params.emailid === undefined) {
              sessionStorage.setItem('Error', 'Please provide Serial Number, CCRPNumber and Email ID ');
            } else if (params.serialnumber === undefined && params.ccrpnumber === undefined
              && params.emailid !== undefined) {
              sessionStorage.setItem('Error', 'Please provide Serial Number and CCRPNumber');
            } else if (params.serialnumber !== undefined && params.ccrpnumber === undefined
              && params.emailid === undefined) {
              sessionStorage.setItem('Error', 'Please provide CCRPNumber and Email ID');
            } else if (params.serialnumber === undefined && params.ccrpnumber !== undefined
              && params.emailid === undefined) {
              sessionStorage.setItem('Error', 'Please provide Serial Number and Email ID ');
            } else if (params.serialnumber === undefined && params.ccrpnumber !== undefined
              && params.emailid !== undefined) {
              sessionStorage.setItem('Error', 'Please provide Serial Number');
            } else if (params.serialnumber !== undefined && params.ccrpnumber === undefined
              && params.emailid !== undefined) {
              sessionStorage.setItem('Error', 'Please provide CCRPNumber');
            } else if (params.serialnumber !== undefined && params.ccrpnumber !== undefined
              && params.emailid === undefined) {
              sessionStorage.setItem('Error', 'Please provide Email ID');
            } else if (params.serialnumber !== undefined && (atob(params.serialnumber).length > 50)
              && params.ccrpnumber !== undefined && params.emailid !== undefined) {
              sessionStorage.setItem('Error', 'Please provide valid Serial Number');
            } else if (params.serialnumber !== undefined && params.ccrpnumber !== undefined &&
              (atob(params.ccrpnumber).length > 20) && params.emailid !== undefined) {
              sessionStorage.setItem('Error', 'Please provide valid CCRPNumber');
            } else if (params.serialnumber !== undefined && params.ccrpnumber !== undefined
              && params.emailid !== undefined && (atob(params.emailid).length > 100)) {
              sessionStorage.setItem('Error', 'Please provide valid Email Id');
            } else if (params.serialnumber !== undefined && params.ccrpnumber !== undefined
              && params.emailid !== undefined && params.articlenumber !== undefined &&
              (atob(params.articlenumber).length > 50)) {
              sessionStorage.setItem('Error', 'Please provide valid Article Number');
            } else if (params.serialnumber !== undefined && params.ccrpnumber !== undefined
              && params.emailid !== undefined && params.supplierguid !== undefined &&
              (atob(params.supplierguid).length > 30)) {
              sessionStorage.setItem('Error', 'Please provide valid Supplier GUID');
            } else if (params.serialnumber !== undefined && params.ccrpnumber !== undefined
              && params.emailid !== undefined && params.productgroup !== undefined &&
              (atob(params.productgroup).length > 50)) {
              sessionStorage.setItem('Error', 'Please provide valid Product Group');
            } else {
              sessionStorage.setItem('SerialNumber', JSON.stringify(atob(params.serialnumber)));
              sessionStorage.setItem('CCRPNumber', JSON.stringify(atob(params.ccrpnumber)));
              sessionStorage.setItem('EmailID', JSON.stringify(atob(params.emailid)));
            }
          }
          if (params.articlenumber === undefined) {
            sessionStorage.setItem('ArticleNumber', JSON.stringify((params.articlenumber)));
          } else {
            sessionStorage.setItem('ArticleNumber', JSON.stringify(atob(params.articlenumber)));

          }
          if (params.supplierguid === undefined) {
            sessionStorage.setItem('SupplierGuid', JSON.stringify((params.supplierguid)));
          } else {
            sessionStorage.setItem('SupplierGuid', JSON.stringify(atob(params.supplierguid)));

          }

          if (params.productgroup === undefined) {
            sessionStorage.setItem('ProductGroup', JSON.stringify((params.productgroup)));
          } else {
            sessionStorage.setItem('ProductGroup', JSON.stringify(atob(params.productgroup)));

          }
          isCCRP1 = params.isCCRP;

        }
      }
    });

    const data = JSON.parse(sessionStorage.getItem('isCCRP'));
    if (data) {
      isCCRP1 = data;
    }
    const obj = {
      clientId: environment.clientID,
      clientSecret: environment.clientSecret,
      isCCRP: isCCRP1
    };

    return obj;
  }


  updateLoginDateForUser(id): Observable<any> {
    const url: string = GlobalURL.UpdateLoginDate + id;
    return this.http.put(url, '');
  }

  setInitialUsrInfo(searchData, email) {
    const userInfo = this.getUsrInfo();
    console.log(userInfo);
    let urlToNavigate: string;
    if (searchData !== undefined && searchData !== ''
      // && email === 'pg-ch-dps-rediracc@abb.com'
    ) {
      if (searchData.itemType === 'Component') {
        this.router.navigate([
          '/home/componentdetail',
          searchData.orderLineID,
          searchData.supplierGUID,
          searchData.serialNumber,
          searchData.traceabilityID,
          searchData.articleNumber,
          '0', '0'
        ]);
      } else {
        this.router.navigate([
          '/home/productdetail',
          searchData.articleNumber,
          searchData.serialNumber,
          // data.serialOrBatchNumber,
          searchData.supplierGUID,
        ]);
      }
    }
    if (userInfo.isActive) {
      const roleNames = this.sharedService.getRoleNames(userInfo);
      if (roleNames.length > 0) {
        urlToNavigate = this.roleArray[roleNames[0]][0];
      }
    }

    if (urlToNavigate === undefined) {
      this.router.navigate(['login']);
    }

    if ((JSON.parse(sessionStorage.getItem('isCCRP')) === environment.isCCRP) ||
      (JSON.parse(sessionStorage.getItem('ServIsInstallationId')) !== null &&
        JSON.parse(sessionStorage.getItem('ServIsAppId')) !== null)) {
    } else {
      this.router.navigateByUrl('/' + urlToNavigate);
    }
    return urlToNavigate;
  }
  // Get loggged in user details
  getUsrInfo() {
    if (sessionStorage.getItem('userData')) {
      return JSON.parse(sessionStorage.getItem('userData'));
    }
    // if (localStorage.getItem('userData')) {
    //   return JSON.parse(localStorage.getItem('userData'));
    // }
    return false;
  }

  // CH7 streamline roles : To check if role is present in role list assigned to user
  isRolePresent(...roles: string[]) {
    let roleNames: any;
    const userInfo = JSON.parse(sessionStorage.getItem('userData'));
    roleNames = this.sharedService.getRoleNames(userInfo).join(',');
    const splitRoles = roleNames.split(',');

    // console.log(splitRoles);
    for (let i = 0; i < roles.length; i++) {
      if (splitRoles.includes(roles[i])) {
        return true;
      }
    }
    return false;
  }

  isNotRolePresent(role: string) {
    let roleNames: any;
    const userInfo = JSON.parse(sessionStorage.getItem('userData'));
    roleNames = this.sharedService.getRoleNames(userInfo).join(',');
    const splitRoles = roleNames.split(',');

    // console.log(splitRoles);

    if (splitRoles.includes(role)) {
      return false;
    }

    return true;
  }

  // Get Logged in User bearer token
  getToken() {
    let token;
    if (localStorage.getItem('usrData')) {
      token = this.getUsrInfo();
    }
    if (token) {
      return token.apistring;
    }
    return false;
  }

  logout() {
    let key;
    for (let i = 0; i < localStorage.length; i++) {
      key = localStorage.key(i);
      localStorage.removeItem(key);
    }
    localStorage.clear();
    console.log(333333);
    sessionStorage.clear();
    this.router.navigate(['login']);
    // this.isLoggednIn = false;
  }

  urlAuthenticator(path) {
    const userInfo = this.getUsrInfo();
    const roleNames = this.sharedService.getRoleNames(userInfo);
    for (const roleName of roleNames) {
      if (this.roleArray[roleName].includes(path)) {
        return true;
      }
    }
    return false;

    // let urlToNavigate;
    // for (let i = 0; i <= this.roleArray.length - 1; i++) {
    //   if (this.roleArray[i][userInfo.roleName]) {
    //     if (
    //       roleNames.has(DPS_Roles.System_Configurator) ||
    //       userInfo.roleName === DPS_Roles.Factory_Configurator ||
    //       userInfo.roleName === DPS_Roles.Quality_Controller ||
    //       userInfo.roleName === 'Customer' ||
    //       userInfo.roleName === 'Data Controller'
    //     ) {
    //       urlToNavigate = this.roleArray[i][userInfo.roleName].includes(path);
    //       break;
    //     } else {
    //       urlToNavigate = this.roleArray[i][userInfo.roleName];
    //       break;
    //     }
    //   }
    // }
    // if (
    //   userInfo.roleName === DPS_Roles.System_Configurator ||
    //   userInfo.roleName === DPS_Roles.Factory_Configurator ||
    //   userInfo.roleName === DPS_Roles.Quality_Controller ||
    //   userInfo.roleName === 'Customer' ||
    //   userInfo.roleName === 'Data Controller'

    // ) {
    //   return urlToNavigate;
    // } else {
    //   if (path === urlToNavigate) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
  }

  internalAuthentication(url) {
    const userInfo = this.getUsrInfo();
    // let componentToNavigate;
    const roleList = {
      [DPS_Roles.System_Configurator]: [
        'productdetail/:artNum/:sn/:suppId',
        'productdetail/fieldinstallation/:artNum',
        'productdetail/commissioned/:an/:sn/:fGIS/:vo/:seqid',
        'productdetail/decommissioned/:an/:sn/:fGIS/:vo/:seqid',
        'assembly/serviceintervention/view/:type/:id',
        'productiondata/:artNum/:sn/:suppId',
        'componentdetail/:id/:suppId/:snbn/:trid/:artNum/:isBN/:prodTreeSeqID',
        'orders/order-details/:orderStatus/:id',
        'iqc/orderdetail/:artNum/:sn/:suppId/:viewonly/:id',
        // CR-203 ,REWA IQC (QM),8-Aug-23
        'iqc/orderdetail-digital/:artNum/:sn/:suppId/:viewonly/:id',
        'iqc/inspectdetail/:artNum/:sn/:suppId/:ordrCompId/:ordrLnId/:viewonly/:lotId/:compMilSeID',
        'iqc/supplierdata/:artNum/:sn/:suppId/:ordrLnId/:ordrCompId/:viewonly/:lotId/:id',
        'ff/search',
        'ff/reporting',
        'ff/addIssue',
        'ff/resolution',
        'document-download',
        'document-download/detail',
        'document-download/selected-document-download',
        'incident-log',
        'assembly/replacecomponent',
      ],
      Customer: [
        'customer',
        'productdetail/:artNum/:sn/:suppId',
        'assembly/addcomponent',
        'assembly/replacecomponent',
        'productdetail/:artNum/:sn/:suppId',
        'productdetail/fieldinstallation/:artNum',
        'componentdetail/:id/:suppId/:snbn/:trid/:artNum/:isBN/:prodTreeSeqID',
        'productdetail/commissioned/:an/:sn/:fGIS/:vo/:seqid',
        'productdetail/decommissioned/:an/:sn/:fGIS/:vo/:seqid',
        'assembly/serviceintervention/view/:type/:id',
        'productiondata/:artNum/:sn/:suppId',
      ],
      [DPS_Roles.Supplier]: 'orders',
      [DPS_Roles.Factory_Configurator]: [
        'processdetail/:an/:sn/:fid',
        'process',
        'associate',
        'productdetail/:artNum/:sn/:suppId/:newcomp',
        'productdetail/:artNum/:sn/:suppId',
        'associatedetail/:id/:an/:artName/:q/:lu/:status',
        'register',
        'assembly/searchcomponent/:type',
        'componentdetail/:id/:suppId/:snbn/:trid/:artNum/:isBN/:prodTreeSeqID',
        'productiondata/:artNum/:sn/:suppId',
        'assembly/replacecomponent',
        'assembly/serviceintervention/view/:type/:id',
        'iqc/orderdetail/:artNum/:sn/:suppId/:viewonly/:id',
        // CR-203 ,REWA IQC (QM),8-Aug-23
        'iqc/orderdetail-digital/:artNum/:sn/:suppId/:viewonly/:id',
        'iqc/inspectdetail/:artNum/:sn/:suppId/:ordrCompId/:ordrLnId/:viewonly/:lotId/:compMilSeID',
        'iqc/supplierdata/:artNum/:sn/:suppId/:ordrLnId/:ordrCompId/:viewonly/:id',
        'unstarted-product',
        'unfinished-SN',
        'product-without-SO',
        'final-testing-certificate',
        'replicate-production-data',
        'recorded-production-time',
        'recorded-production-time/recorded-production-time-details/:productionMilestoneSeqId/:serialNumber',
        'incident-log',
        'processdetailnew/:an/:sn/:fid',
        'notification-management'
      ],
      [DPS_Roles.Quality_Controller]: [
        '',
        'componentdetail/:id/:suppId/:snbn/:trid/:artNum/:isBN/:prodTreeSeqID',
        'assembly/addcomponent',
        'assembly/replacecomponent',
        'productdetail/:artNum/:sn/:suppId',
        'productdetail/fieldinstallation/:artNum',
        'componentdetail/:id/:suppId/:snbn/:trid/:artNum/:isBN/:prodTreeSeqID',
        'productdetail/commissioned/:an/:sn/:fGIS/:vo/:seqid',
        'productdetail/decommissioned/:an/:sn/:fGIS/:vo/:seqid',
        'assembly/serviceintervention/view/:type/:id',
        'productiondata/:artNum/:sn/:suppId',
        'incomingqc',
        'iqc/orderdetail/:artNum/:sn/:suppId/:viewonly/:id/:orderlineId',
        'iqc/orderdetail/:artNum/:sn/:suppId/:viewonly/:id',
        // CR-203 ,REWA IQC (QM),8-Aug-23
        'iqc/orderdetail-digital/:artNum/:sn/:suppId/:viewonly/:id/:orderlineId',
        'iqc/orderdetail-digital/:artNum/:sn/:suppId/:viewonly/:id',
        'iqc/inspectdetail/:artNum/:sn/:suppId/:ordrCompId/:ordrLnId/:viewonly/:lotId/:compMilSeID',
        'iqc/supplierdata/:artNum/:sn/:suppId/:ordrLnId/:ordrCompId/:viewonly/:id',
        'qt/qtDashboard',
        'qt/qtDetail/:qualityTagCode/:productName',
        'document-download',
        'document-download/detail',
        'document-download/selected-document-download',
        'certs-review',
        'dataGovernanceSearch',
        'dataGovernanceSearch/dataSearchDetails/:orderLineNo/:categoryType/:dateOfDeliveryTo/' +
        ':recievingFactory/:an/:dateofDeliveryFrom/:categoryName/:dateCustomFilter' +
        '/:articleNumberEnabled',
        'withdraw-products',
        'incident-log',
        'lumadaReport'
      ],
      [DPS_Roles.Production_Operator]: [
        'processdetail/:an/:sn/:fid',
        'process',
        'associate',
        'productdetail/:artNum/:sn/:suppId/:newcomp',
        'productdetail/:artNum/:sn/:suppId',
        'associatedetail/:id/:an/:artName/:q/:lu/:status',
        'register',
        'assembly/searchcomponent/:type',
        'componentdetail/:id/:suppId/:snbn/:trid/:artNum/:isBN/:prodTreeSeqID',
        'productiondata/:artNum/:sn/:suppId',
        'assembly/replacecomponent',
        'assembly/serviceintervention/view/:type/:id',
        'iqc/orderdetail/:artNum/:sn/:suppId/:viewonly/:id/:orderlineId',
        'iqc/orderdetail/:artNum/:sn/:suppId/:viewonly/:id',
        // CR-203 ,REWA IQC (QM),8-Aug-23
        'iqc/orderdetail-digital/:artNum/:sn/:suppId/:viewonly/:id/:orderlineId',
        'iqc/orderdetail-digital/:artNum/:sn/:suppId/:viewonly/:id',
        'iqc/inspectdetail/:artNum/:sn/:suppId/:ordrCompId/:ordrLnId/:viewonly/:lotId/:compMilSeID',
        'iqc/supplierdata/:artNum/:sn/:suppId/:ordrLnId/:ordrCompId/:viewonly/:id',
        'unstarted-product',
        'unfinished-SN',
        'product-without-SO',
        'final-testing-certificate',
        'replicate-production-data',
        'incident-log',
        'processdetailnew/:an/:sn/:fid'
      ],
      'Field Operator': [
        '',
        'assembly/searchcomponent/:type',
        'productdetail/:artNum/:sn/:suppId/:newcomp',
        'productdetail/:artNum/:sn/:suppId',
        'productdetail/fieldinstallation/:artNum',
        'componentdetail/:id/:suppId/:snbn/:trid/:artNum/:isBN/:prodTreeSeqID',
        'productdetail/commissioned/:an/:sn/:fGIS',
        'productdetail/commissioned',
        'productdetail/decommissioned/:an/:sn/:fGIS',
        'productdetail/decommissioned',
        'productdetail/commissioned/:an/:sn/:fGIS/:vo/:seqid',
        'productdetail/decommissioned/:an/:sn/:fGIS/:vo/:seqid',
        'assembly/addcomponent',
        'assembly/replacecomponent',
        'assembly/serviceintervention/view/:type/:id',
        'iqc/orderdetail/:artNum/:sn/:suppId/:viewonly/:id/:orderlineId',
        'iqc/orderdetail/:artNum/:sn/:suppId/:viewonly/:id',
        // CR-203 ,REWA IQC (QM),8-Aug-23
        'iqc/orderdetail-digital/:artNum/:sn/:suppId/:viewonly/:id/:orderlineId',
        'iqc/orderdetail-digital/:artNum/:sn/:suppId/:viewonly/:id',
        'iqc/inspectdetail/:artNum/:sn/:suppId/:ordrCompId/:ordrLnId/:viewonly/:lotId/:compMilSeID',
        'iqc/supplierdata/:artNum/:sn/:suppId/:ordrLnId/:ordrCompId/:viewonly/:id',
      ],
      'Dispatch Supervisor': [
        'customize-redbook-report',
        'componentdetail/:id/:suppId/:snbn/:trid/:artNum/:isBN/:prodTreeSeqID',
        'productdetail/:artNum/:sn/:suppId/:newcomp',
        'productdetail/:artNum/:sn/:suppId'
      ],
      'Data Controller': [
        'dataGovernanceSearch',
        'dataGovernanceSearch/dataSearchDetails/:orderLineNo/:categoryType/:dateOfDeliveryTo/' +
        ':recievingFactory/:an/:dateofDeliveryFrom/:categoryName/:dateCustomFilter' +
        '/:articleNumberEnabled'
      ],
      'IQCAdmin': [
        'component'
      ],
      'Sub-contractor': 'orders'
    };

    const roleNames = this.sharedService.getRoleNames(userInfo);
    for (const roleName of roleNames) {
      for (const urls of roleList[roleName]) {
        if (url.path === urls) {
          return true;
        }
      }
    }
    return false;
    // for (let i = 0; i <= roleList.length - 1; i++) {
    //   if (roleList[i][userInfo.roleName]) {
    //     const array = roleList[i][userInfo.roleName];
    //     array.forEach((element) => {
    //       if (element === url.path) {
    //         componentToNavigate = true;
    //       }
    //     });
    //     break;
    //   }
    // }
    // if (componentToNavigate) {
    //   return true;
    // } else {
    //   return false;
    // }
  }

  // detect if browser is mobile or desktop
  detectMobile() {
    let check = false;
    /* tslint:disable */
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
  }
}
