import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AllLocales } from '../globalModule/locales';
import { LocationStrategy } from '@angular/common';
import { GlobalMsg } from './globalMsg';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  prefectchdData: any;
  addReplaceData: any;
  searchData: any;
  CRData: any;
  barcodeData: any;
  // iqcsearchData: any;
  // iqcInspectData: any;
  // iqcSupplierData: any;
  GlobalMsg: any = {};
  maxFileNameLength = 90;

  constructor(private http: HttpClient, private locationStrategy: LocationStrategy
  ) {
    this.prefectchdData = new BehaviorSubject('');
    this.addReplaceData = new BehaviorSubject('');
    this.searchData = new BehaviorSubject('');
    this.CRData = {};
    // this.iqcsearchData = new BehaviorSubject('');
    // this.iqcInspectData = new BehaviorSubject('');
    // this.iqcSupplierData = new BehaviorSubject('');
  }

  getData() {
    return this.prefectchdData.asObservable();
  }

  setData(Data: any) {
    this.prefectchdData.next(Data);
  }

  addReplaceSetData(data: any) {
    this.addReplaceData.next(data);
  }

  addReplaceSearchSetData(serchData: any) {
    this.searchData.next(serchData);
  }

  getAddReplaceSearchData() {
    return this.searchData.asObservable();
  }

  getAddReplaceData() {
    return this.addReplaceData.asObservable();
  }

  // addIQCSearchSetData(serchData: any) {
  //   this.iqcsearchData.next(serchData);
  // }
  // getAddIQCSearchData() {
  //   return this.iqcsearchData.asObservable();
  // }

  // addIQCInspectSetData(serchData: any) {
  //   this.iqcInspectData.next(serchData);
  // }
  // getIQCInspectData() {
  //   return this.iqcInspectData.asObservable();
  // }
  // addIQCSupplierSetData(serchData: any) {
  //   this.iqcSupplierData.next(serchData);
  // }
  // getIQCSupplierData() {
  //   return this.iqcSupplierData.asObservable();
  // }

  // get global msg as per locale
  getGlobalMsgLocale(): Observable<any> {
    let locale = window.navigator.language;
    if (AllLocales.ALL_LOCALE.search(locale) < 0) {
      locale = AllLocales.DEFAULT_LOCALE;
    }

    if (locale === 'en') {
      locale = 'en-US';
    }
    if (locale === 'sv') {
      locale = 'sv-SE';
    }
    // include global msg file as per locale
    return this.http.get('/assets/locale_translation/globalMsg/' + locale + '.json');
  }

  // get current locale
  getCurrentLocale(): string {
    let locale = window.navigator.language;
    if (AllLocales.ALL_LOCALE.search(locale) < 0) {
      locale = AllLocales.DEFAULT_LOCALE;
    }
    if (locale === 'sv') {
      locale = 'sv-SE';
    }
    // for testing
    // locale = 'sv-SE';

    // return current locale
    return locale;
  }

  setCustomReportData(val: object) {
    this.CRData = val;
  }
  getCustomReportData() {
    return this.CRData;
  }

  setBarcodeData(val) {
    this.barcodeData = val;
  }
  getBarcodeData() {
    return this.barcodeData;
  }
  encodeData(data): any {
    return btoa(btoa(unescape(encodeURIComponent(data))));
  }
  decodeData(data): any {
    return decodeURIComponent(escape(atob(atob(data))));
  }
  convertDateFormat(date) {
    const datearray = date.split('.');
    const newdate = datearray[1] + '-' + datearray[0] + '-' + datearray[2];
    return newdate;
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  downloadDocument(fileName, fileType, Response) {
    const type = this.documentFileType(fileType);

    const blob = new Blob([Response], { type: type });
    if (fileType.toLowerCase() === 'xlsm') {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = decodeURI(fileName + '.xlsm');
      link.click();
    } else {
      const link = document.createElement('a');
      link.setAttribute('id', fileName);
      link.href = window.URL.createObjectURL(blob);
      link.download = decodeURI(fileName);
      link.click();
      document.body.appendChild(link);
      document.body.removeChild(link);
    }
  }

  documentFileType(file) {
    let type;
    const fileType = file.toLowerCase();
    if (fileType === 'pdf') {
      type = 'application/pdf';
    } else if (fileType === 'png') {
      type = 'image/png';
    } else if (fileType === 'jpg') {
      type = 'image/jpeg';
    } else if (fileType === 'jpeg') {
      type = 'image/jpeg';
    } else if (fileType === 'xls') {
      type = 'application/vnd.ms-excel';
    } else if (fileType === 'xlsx') {
      type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else if (fileType === 'doc') {
      type = 'application/msword';
    } else if (fileType === 'docx') {
      type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (fileType === 'csv') {
      type = 'text/csv';
    } else if (fileType === 'mp3') {
      type = 'audio/mp3';
    } else if (fileType === 'mp4') {
      type = 'video/mp4';
    } else if (fileType === 'ods') {
      type = 'application/vnd.oasis.opendocument.spreadsheet';
    } else if (fileType === 'xml') {
      type = 'text/xml';
    } else if (fileType === 'txt') {
      type = 'text/plain';
    } else if (fileType === 'xlsm') {
      type = 'application/vnd.ms-excel.sheet.macroEnabled.12';
    } else if (fileType === 'zip') {
      type = 'application/zip';
    }
    return type;
  }

  dataEncodeURIComponent(data): any {
    return encodeURIComponent(data).replace(/[!~'()]/g, escape)
      .replace(/[-]/g, '%2D').replace(/[_]/g, '%5F')
      .replace(/[.]/g, '%2E')
      .replace(/[*]/g, '%2A');
  }

  getErrorMessage(error) {
    let errorMsg: any = '';
    if (error.error !== null && typeof (error.error) === 'string') {
      errorMsg = error.error;
    } else if (error.status === 500 && error.error !== null) {
      errorMsg = error.error.Message;
    } else if (error.status === 500) {
      errorMsg = GlobalMsg.serverError;
    } else if (error.status === 400) {
      errorMsg = GlobalMsg.BAD_REQUEST_ERROR;
    } else if (error.status === 401) {
      errorMsg = GlobalMsg.UNAUTHORIZED_ERROR;
    } else if (error.status === 403) {
      errorMsg = GlobalMsg.FORBIDDEN_ERROR;
    } else if (error.status === 408) {
      errorMsg = GlobalMsg.REQUEST_TIMEOUT_ERROR;
    } else if (error.status === 409) {
      errorMsg = GlobalMsg.CONFLICT_ERROR;
    } else if (error.status === 404) {
      errorMsg = GlobalMsg.NOT_FOUND_ERROR;
      if (error.errorMessage) {
        errorMsg = error.errorMessage;
      } else if (error.error !== null && typeof (error.error) === 'string') {
        errorMsg = error.error;
      } else if (error.error !== null && error.error.Message) {
        errorMsg = error.error.Message;
      }
    } else {
      errorMsg = GlobalMsg.serverError;
    }

    // INC0411626, During downtime, users who are using the system are greeted
    // with un friendly error messages, 6-Feb-24
    if (errorMsg.includes('web app')) {
      errorMsg = 'DPS application is under maintenance.';
    }
    return errorMsg;
  }


  checkRestrictedSplCharacter(data) {
    if (data !== null && data !== undefined) {
      data = data.trim();
      if (data.indexOf(',') !== -1 || data.indexOf('|') !== -1 || data.indexOf(' ') !== -1 ||
        data.indexOf('>') !== -1 || data.indexOf('<') !== -1) {
        return true;
      }
    }
    return false;
  }

  getLocalTimeZone() {
    let d = new Date().toString();
    d = d.substring(d.indexOf('(') + 1, d.indexOf(')'));
    return d;
  }

  getRoleNames(userInfo): any {
    const roleNames: any = new Set();
    if (userInfo.Admin) {
      userInfo.SystemConfiguratorScreenDetails.forEach(screenDetail => {
        roleNames.add(screenDetail.RoleName);
      });
    } else {
      userInfo.UserScreenDetails.forEach(screenDetail => {
        roleNames.add(screenDetail.RoleName);
      });
    }
    return Array.from(roleNames);
  }

}
