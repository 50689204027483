import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

declare var db: any;

@Injectable({
  providedIn: 'root'
})
export class AutosaveService {

  public storageName = 'formdetails';

  logoutFunction = new EventEmitter();
  logoutFunction2 = new EventEmitter();
  subVar: Subscription;
  subVar2: Subscription;
  isLoggedOut = false;

  constructor() { }

  onLogoutFunction() {
    this.logoutFunction.emit();
  }
  onLogoutFunction2() {
    this.logoutFunction2.emit();
  }

  addIndexedDB(formName, value) {
    return new Promise(async (resolve, reject) => {
      if (db !== undefined  && db !== null) {
        const request = await db.transaction([this.storageName], 'readwrite')
          .objectStore(this.storageName).put({ formName, value });
        request.onsuccess = await function (event) {
          if (event.target.result) {
            resolve(true);
          } else {
            console.log('error');
            resolve(false);
          }
        };
        request.onerror = await function (event) {
          console.log('error', event);
          resolve(false);
        };
      }
    });
  }

  getIndexedDB() {
    return new Promise(async (resolve, reject) => {
      if (db !== undefined && db !== null) {
        const request = await db.transaction([this.storageName], 'readwrite')
          .objectStore(this.storageName).getAll();
        request.onsuccess = await function (event) {
          if (event.target.result) {
            resolve(event.target.result);
          } else {
            console.log('error');
            resolve(false);
          }
        };
        request.onerror = await function (event) {
          console.log('error', event);
          resolve(false);
        };
      }
    });
  }

  getIndexedDBItems(keyname) {
    return new Promise(async (resolve, reject) => {
      if (db !== undefined && db !== null) {
        const request = await db.transaction([this.storageName], 'readwrite')
          .objectStore(this.storageName).get(keyname);
        request.onsuccess = await function (event) {
          if (event.target.result) {
            resolve(event.target.result);
          } else {
            console.log('error');
            resolve(false);
          }
        };
      }
    });
  }

  deleteIndexedDBItems(deleteform) {
    return new Promise(async (resolve, reject) => {
      if (db !== undefined && db !== null) {
        if (typeof (deleteform) === 'string') {
          const request = await db.transaction([this.storageName], 'readwrite')
            .objectStore(this.storageName).delete(deleteform);
          request.onsuccess = await function (event) {
            // console.log('deleted');
            resolve(true);
          };
          request.onerror = await function (event) {
            if (request.error) {
              console.log('error');
            }
          };
        }
      }
    });
  }

  deleteIndexedDB(deleteform) {
    return new Promise(async (resolve, reject) => {
      if (db !== undefined) {
        if (typeof (deleteform) === 'boolean') {
          if (deleteform) {
            const request = indexedDB.deleteDatabase(db.name);
            request.onsuccess = await function (event) {
              if (request.result) {
                resolve(true);
              } else {
                console.log('error');
                resolve(false);
              }
            };
            request.onerror = await function (event) {
              if (request.error) {
                console.log('error');
              }
            };

          }
        }
      }
    });
  }



}

