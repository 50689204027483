import { Component, OnInit } from '@angular/core';
import { Router } from '../../../../node_modules/@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

  constructor(private router: Router, private authService: AuthenticationService, ) { }

  ngOnInit() {
  }

  logoNav() {
    this.router.navigateByUrl('/' + this.authService.setInitialUsrInfo('', ''));
  }

}
