import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';


@Injectable({
    providedIn: 'root'
})
export class ActivateRouteGaurd implements CanActivate {

    constructor(private router: Router, private authSerivce: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        const currentUser = sessionStorage.getItem('userData');
        // console.log("authGuard:",sessionStorage);
        if (currentUser !== null) {
            if (this.authSerivce.urlAuthenticator(route.url[0].path)) {
                return true;
            } else {
                this.router.navigate(['/forbidden']);
                return false;
            }
        }

        this.router.navigate(['/login']);
        return false;
    }

}
