import { Injectable, EventEmitter } from '@angular/core';
import * as signalR from '@aspnet/signalr';


@Injectable({
    providedIn: 'root'
})


export class SignalRService {

    datas: any;
    public messageReceived = new EventEmitter();
    private hubConnection: signalR.HubConnection;

    startConnection(url: string, token: string) {
        console.log(`initializing SignalRService...`);
        const options = {
            accessTokenFactory: () => token
        };
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url, options)
            .configureLogging(signalR.LogLevel.Information)
            .build();
        this.hubConnection.start().catch(err => console.error(err));
        this.getbroadMessage();
    }

    getbroadMessage() {
        this.hubConnection.on('broadcastmessage', (data: any) => {
            this.messageReceived.emit(JSON.parse(data));
        });
    }

    terminateConnection() {
        if (this.hubConnection !== undefined && this.hubConnection !== null) {
            this.hubConnection.stop().catch(err => console.error(err));
        }
    }

}
