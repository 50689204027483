export enum DPS_Roles {
    System_Configurator = 'System Configurator',
    Factory_Configurator = 'Factory Configurator',
    Production_Operator = 'Production Operator',
    Quality_Controller = 'Quality Controller',
    Supplier = 'Supplier'
}

export enum DPS_CategoryName {
    Default = 'Default',
    Additional_Feature = 'Additional feature',
    Reports = 'Reports'
}
