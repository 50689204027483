export class HistoryModel {

    public milestoneName: string;
    public attachment: Array<any>;
    public captureDateTime: string;
    public location: string;
    public value: string;
    public key: string;
    public groupName: string;
    public milestoneSeqId: number;
    public isResponseDone: boolean;
    public qualityTagId: number;
    public qualityTagCode: number;
    public qualityTagQuestion: string;
    public isInspection: boolean;
    public isEnforce: boolean;
    public isDocValidated: boolean;


    constructor(response: any) {
        this.milestoneName = response.milestoneName;
        this.attachment = response.attachment;
        this.captureDateTime = response.captureDateTime;
        this.location = response.location;
        this.value = response.value;
        this.key = response.key;
        this.groupName = response.groupName;
        this.milestoneSeqId = response.milestoneSeqId;
        this.isResponseDone = response.isResponseDone;
        this.qualityTagId = response.qualityTagId;
        this.qualityTagCode = response.qualityTagCode;
        this.qualityTagQuestion = response.qualityTagQuestion;
        this.isEnforce = response.isEnforce;
        this.isInspection = response.isInspection;
        this.isDocValidated = response.isDocValidated;
    }
}
