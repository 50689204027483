import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'app-servertimeout',
  templateUrl: './servertimeout.component.html',
  styleUrls: ['./servertimeout.component.scss']
})
export class ServertimeoutComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthenticationService, ) { }


  ngOnInit() {
  }
  logoNav() {
    this.router.navigateByUrl('/' + this.authService.setInitialUsrInfo('', ''));
  }
}
