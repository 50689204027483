export const environment = {
    production: true,
    BASE_URL: 'https://webapi.digitalpassportsystem.hitachienergy.com:443/',
    appInsights: '1a5bd0bf-7e08-4cf5-95d3-4d4a7c74fef0',
    clientID: '569564d8-cfc6-4537-8266-71222f4ef393',
    scopes: ['User.ReadBasic.All', 'ecd05322-b73e-489c-bf7b-e4622a63c615/Access_dps_api', 'User.Read'],
    redirectURi: 'https://digitalpassportsystem.hitachienergy.com',
    subscription: '248118535bbd44d5aa5ab3959fcd647f',
    authority: 'https://login.microsoftonline.com/7831e6d9-dc6c-4cd1-9ec6-1dc2b4133195',
    blobContainer: 'pglz01-ne-stblob-dps-402',
    azureSearchKey: 'FrnvWL743rPtSaA9QOdnihAgeN9OMqVvlrZT2LAAlCAzSeBcghn0',
    azureSearchURL: 'https://search.digitalpassportsystem.hitachienergy.com',
    bingCredential: 'AhHGm49KKwySazCHIgNbmzE0l9hNR35wKyMGwJbhrKUmg_AIpLyjX-uqiBMGUdIf',
    azureMapKey: '_jPiRYHUxEXJX0_R4jV2Qlv-xvUYbGa9uA0yHG_MiHo',
    title: '',
    blobContainerCustomReport: 'pglz01-ne-stblob-dps-403',
    downloadZip: 'https://export.digitalpassportsystem.hitachienergy.com/api/zip?code=qRCpOOP0E8-GXCaW-ofrNizb_ir70pmGd0qFtsuYzsvkAzFuZE59hg==',
    CCRPUrl: 'https://ccrp.hitachienergy.com/en/complaint/',
    clientSecret: 'test',
    isCCRP: 'yes',
    CCRPAppId: '9AAG029588',
    ServIsAppId: '9AAG029556',
    organizationName: 'HE',
    signlarUrl: 'https://download.digitalpassportsystem.hitachienergy.com/api/negotiate?code=k1uMtya3YbUF_bZqdsXlhHs37fHCAXCLYj2-jyTzgOvDAzFuwrvQoQ==',
    productHierarchy: 'https://export.digitalpassportsystem.hitachienergy.com/api/ProductHierarchy?code=01lteTHKb9TiIEko8RgI9mkMMAqkf8ELQq-M2cFDUP95AzFu7bcRMg==',
    generateReportProductionTimeCaptured: 'https://export.digitalpassportsystem.hitachienergy.com/api/GenerateReportForProductionTimeCaptureDetails?code=b-Cw0vCuksab8RusbdR7VB1OmBZa8ZEktE_iLxF1XAGiAzFuJ5TUfA==',
    environment: 'HITACHI',
    downloadQCZip: 'https://export.digitalpassportsystem.hitachienergy.com/api/ProductMultipleDocumentsDownload?code=uVwt5LSXjfpfhxThyzqL-_lGY8Qsdc7l81Tyzbz6ycFPAzFusL51nw=='
};
