import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalURL } from '../../../globalModule/globalUrl';
import { Config } from '../../../../../node_modules/protractor';
import { SharedService } from 'src/app/globalModule/shared.service';
import { AuthenticationService } from 'src/app/globalModule/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserActionService {

  constructor(private http: HttpClient, private sharedService: SharedService, private authService: AuthenticationService) {

  }

  // get user info
  userInfo(searchVal, startPage, pageSize, userRole, userStatus, factoryId, factoryName): Observable<any> {

    let url: string;
    let userRoleParam = '';
    let searchBy = '';
    let status = '';
    let factoryIdFilter = '';
    let assignedFactory = '';
    if (searchVal.length > 0) {
      searchBy += 'searchBy=' + this.sharedService.encodeData(searchVal) + '&';
    } else {
      searchBy += '';
    }
    if (userRole.length > 0) {
      userRoleParam += 'userRoles=' + this.sharedService.encodeData(userRole) + '&';
    }
    if (userStatus.length > 0) {
      status += 'status=' + this.sharedService.encodeData(userStatus) + '&';
    }
    if (factoryName.length > 0) {
      assignedFactory += 'filterFactoryIds=' + this.sharedService.encodeData(factoryName) + '&';
    }
    factoryIdFilter += '&factoryId=' + (factoryId === undefined ? 0 : factoryId);
    url = GlobalURL.getUserList + startPage + '/' + pageSize + '/' +
      this.sharedService.encodeData('Created Date') + '?' + status + userRoleParam +
      assignedFactory + searchBy
      + factoryIdFilter;
    return this.http.get(url);
  }

  // get country list
  countryList(): Observable<any> {
    const url = GlobalURL.GET_COUNTRY_LIST + '?country=#';
    return this.http.get(url);
  }

  // get user detail info by id
  userDetailInfo(id): Observable<any> {
    const url = GlobalURL.userDetailInfo + id;
    return this.http.get(url);
  }

  // get user data from metadata
  searchUserMetadata(email: any): Observable<any> {
    const url = 'https://graph.microsoft.com/v1.0/users/' + email;
    return this.http.get(url);
  }

  // user group list
  userGroupList(): Observable<any> {
    const url = GlobalURL.userGroupList;
    return this.http.get(url);
  }

  // get user role list
  userRoleListAll(): Observable<any> {
    const url = GlobalURL.userRoleListAll;
    return this.http.get(url);
  }

  // get user role
  userRoleList(userGrp): Observable<any> {
    const url = GlobalURL.userRoleListByGrpName + this.sharedService.encodeData(userGrp) + '/roles/userrole';
    return this.http.get(url);
  }

  // get user type list
  userTypeListAll(): Observable<any> {
    const url = GlobalURL.userTypeListAll;
    return this.http.get(url);
  }

  // get user type
  userTypeList(userGroup, userRole): Observable<any> {
    const encodeUG = this.sharedService.encodeData(userGroup);
    const encodeUR = this.sharedService.encodeData(userRole);
    const url = GlobalURL.userTypeList + encodeUG + '/roles/' + encodeUR + '/usertypes';
    return this.http.get(url);
  }

  // get user flag
  userDetailFlag(userGroup, userRole): Observable<any> {
    const encodeUG = this.sharedService.encodeData(userGroup);
    const encodeUR = this.sharedService.encodeData(userRole);
    const url = GlobalURL.getUserAdminFlag + encodeUG + '/roles/' + encodeUR + '/flag';
    return this.http.get(url);
  }

  // get user status
  userChangeStatus(id): Observable<any> {
    const url = GlobalURL.userChangeStatus + id;
    return this.http.put(url, '');
  }

  // save user data
  saveUser(data): Observable<any> {
    const pkFactoryId = this.authService.getUsrInfo().pkFactoryId;
    const url = GlobalURL.saveUser + '?factoryId=' + (pkFactoryId === undefined ? 0 : pkFactoryId);
    return this.http.post(url, data);
  }

  // edit user data
  editUser(data, id): Observable<HttpResponse<Config>> {
    const pkFactoryId = this.authService.getUsrInfo().pkFactoryId;
    const url = GlobalURL.editUser + id + '?factoryId=' + (pkFactoryId === undefined ? 0 : pkFactoryId);
    return this.http.put(url, data, { observe: 'response' });
  }

  // use role api
  getCategoryScreenMasterdetail(): Observable<any> {
    const url = GlobalURL.USER_CATEGORY_SCREEN_MASTER_DETAIL;
    return this.http.get(url);
  }
  getAllFactoryList(data): Observable<any> {
    const url = GlobalURL.GET_ALL_FACTORYLIST;
    return this.http.post(url, data);
  }
  saveEditUser(formData, newUserEmailNotification): Observable<any> {
    let emailNotification = '';
    if (newUserEmailNotification) {
      emailNotification += '?emailNotification=' + newUserEmailNotification;
    }
    const url = GlobalURL.SAVE_EDIT_USER_DETAIL + emailNotification;
    return this.http.post(url, formData);
  }
  getUserByUserId(id) {
    const url = GlobalURL.USER_DETAIL_BY_USER_ID + id;
    return this.http.get(url);
  }
}

