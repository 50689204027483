export class GlobalMsg {

    // Global Msgs
    public static errorMsg = 'Some error occured';
    public static NO_RECORDS = 'No records found!';
    public static loadingMsg = 'Loading.. Please wait!';

    public static serverError = 'The system encountered a problem.' +
        ' Please contact the technical support team at support_dps@hitachienergy.com';

    // Map Msgs
    public static NO_LOCATION_ERROR = 'Geolocation is not supported by this browser.';
    public static LOCATION_FOUND_ERROR = 'Provide Correct Location';

    // Login msgs
    public static inactiveUser = 'User is inactive';
    public static unauthorizedAccess = 'User does not have valid permission';
    public static userNotFound = 'User not found';
    public static serverFault = 'We are facing issues during log in. Please try again after sometime';

    // Product Msgs
    public static invalidArticleNumber = 'Enter a valid Article number';
    public static invalidDisplayName = 'Enter a valid name';
    public static invalidProductName = 'Enter a valid Product name';
    public static addProductSuccess = 'Product successfully added!';
    public static editProductSuccess = 'Product successfully edited!';
    public static addProductFailure = 'Product cannot be added. Try again later!';
    public static editProductFailure = 'Product cannot be edited. Try again later!';

    // Component msgs
    public static updateComponentSuccess = 'Component successfully updated.';
    public static updateComponentFailure = 'Component cannot be updated. Try again later!';
    public static addComponentSuccess = 'Component successfully added.';
    public static addComponentFailure = 'Component cannot be added. Try again later!';
    public static componentNameFailure = 'Enter a valid component name';
    public static addSupplierExists = 'Supplier is already added';
    public static addFactoryExists = 'Factory is already added';
    public static supplierNotFound = 'Supplier GUID does not exist.';
    public static factoryNotFound = 'Factory ID does not exist.';

    // USer Msgs
    public static addUserFailure = 'Unable to add user';
    public static editUserFailure = 'Unable to edit user';
    public static editUserSuccess = 'User details are successfully updated';
    public static addUserSuccess = 'User successfully added';
    public static viewUserFailure = 'User not found';
    public static userAlreadyOnboard = 'User already onboarded';

    // Impersonate User msgs
    public static USER_ID_REQUIRED = 'Enter user email id';
    public static USER_ID_INVALID = 'Enter a valid user email id';
    public static USER_NAME_REQUIRED = 'Enter user name';
    public static USER_ROLE_REQUIRED = 'Select user role';
    public static USER_TYPE_REQUIRED = 'Select user type';
    public static SUPPLIER_GUID_REQUIRED = 'Enter supplier GUID';
    public static SUPPLIER_GUID_INVALID = 'Enter valid supplier/Factory GUID';
    public static ORDER_UPLOADED_VALID = 'Orders successfully uploaded';
    public static ORDER_UPLOADED_INVALID = 'Orders cannot be uploaded. Check file or try later.';

    // Customer Msgs
    public static saveCustomerSuccess = 'Customer successfuly added';
    public static addCustomerFailure = 'Unable to add customer';
    public static customerNotFound = 'Customer ID does not exist.';
    public static customerExists = 'Customer exists in DPS';

    // Supplier Msgs
    public static saveSupplierSuccess = 'Supplier successfuly added';
    public static addSupplierFailure = 'Unable to add supplier';
    public static supplierExists = 'Supplier exists in DPS';

    public static SUPPLIER_REQUIRED = 'Select supplier ID';
    public static FACTORY_REQUIRED = 'Select factory GUID';
    public static VALID_DATE = 'Select valid date';
    public static DATE_SELECT = 'Select A date';
    public static SUPPLIER_ALREADY_ADDED_AS_FACTORY = 'Supplier id is already onboarded as factory';

    // Factory Msgs
    public static ADDFACTORYFAILURE = 'Unable to add Factory';
    public static EDITFACTORYFAILURE = 'Unable to edit Factory';
    public static EDITFACTORYSUCCESS = 'Factory details are successfully updated';
    public static ADDFACTORYSUCCESS = 'Factory successfully added';
    public static VIEWFACTORYFAILURE = 'Factory not found';
    public static INVALIDFACTORYNAME = 'Invalid Factory Name';
    public static INVALIDFACTORYGISNO = 'Invalid FactoryGISNo ';
    public static INVALIDFACTORYCITY = 'Invalid Factory City';
    public static INVALIDFACTORYCOUNTRY = 'Invalid Factory Country';
    public static INVALIDFACTORYZIP = 'Invalid Factory Zip';
    public static INVALIDFACTORYADDRESS1 = 'Invalid Factory Address1';
    public static INVALIDFACTORYLATLANG = 'Invalid Factory Latitude/longitude';

    // production msg
    public static PRODUCTIONSTEPNOTFOUND = 'No production step is configured for this Serial number.';
    public static PRODUCTIONCOMPLETE = 'Production step is completed for this serial Number Kindly enter different serial Number.';
    public static INCORRECTBARCODE = 'Unrecognised barcode.' +
        ' Please  try with manual data entry  or contact technical support team  at support_dps@hitachienergy.com';
    public static DATANOTVALIDFORLOGGEDINUSER = 'Data is not valid for the logged in user';
    public static ConflictError = 'Multiple users submitting the same step data at same time, try again';


    // register product
    public static NOSERIALNUMBER = 'Unable to generate Serial Number because Factory ID ' +
        'prefix is not available for associated factory, please contact your admin.';
    public static ACTIONFAILED = 'The system encountered a problem.' +
        ' Please contact the technical support team at support_dps@hitachienergy.com';

    // Installation msg
    public static INFO_MSG = 'Successfully saved';
    public static HEADER_FAILED = 'Unable to load header data.';
    public static ASSEMBLY_FAILED = 'Unable to load assembly table data.';
    public static DRAFT_FAILED = 'Unable to load draft data.';
    public static DRAFT_SUCCESS = 'Product installation successfully saved as draft.';

    // SI error
    public static AssembledError = 'Product is already assembled.';

    // edit updated

    public static detailSuccess = 'Details updated successfully';

    // advance Search
    public static qltAddedSuccess = 'Quality Tag Added Successfully';
    public static qltAddedFailed = 'Quality Tag Add Failed';
    public static actionFailed = 'The system encountered a problem.' +
        ' Please contact the technical support team at support_dps@hitachienergy.com';

    public static mapLocationFail = 'Provide Correct Location';
    public static updateSuccess = 'Updated successfully';
    public static updatefailed = 'Updated failed';
    public static provideStepName = 'Please Provide StepName';
    public static addFailed = 'Add Failed';
    public static stepDeletedSuccess = 'Product Step Deleted Successfully';
    public static atLeastStepReq = 'Atleast one step is required';
    public static configShareSuccess = 'Configuration Shared Successfully';
    public static configRemoveSuccess = 'Configuration Removed Successfully';
    public static prodRemoveSuccess = 'Product Removed Successfully';
    public static invalidBarcodeMes = 'Incorrect barcode, please scan the correct barcode';

    // custom Report
    public static templateNameSuccessMsg = 'Template saved successfully';
    public static invalidTemplateName = 'Enter a valid template name';
    public static templateDeleteSuccessMsg = 'Template deleted successfully';
    public static templateNotExist = 'Template does not exist';


    // supplier monthly report
    public static messageSentSuccess = 'Message sent successfully';
    public static messageNotSent = 'Message not sent';

    public static Stop_Tracking_Success = 'Stop Tracking is Successful';

    // error messages for error code's 400's...
    public static BAD_REQUEST_ERROR = 'Please validate the data else contact technical support team  at ' +
        'support_dps@hitachienergy.com';
    public static UNAUTHORIZED_ERROR = 'You are unauthorized to perform this action.';
    public static FORBIDDEN_ERROR = 'You have insufficient rights to perform this activity.';
    public static NOT_FOUND_ERROR = 'The resource you are trying to access does not exist.';
    public static REQUEST_TIMEOUT_ERROR = 'A timeout has occurred for the current request.';
    public static CONFLICT_ERROR = 'The information you are viewing has been updated.' +
        ' Please refresh the page to get the updated information and try again'; // todo

    public static DOWNLOAD_TIMEOUT = 'We are unable to download the data due to huge number of records.';

    public static SERIAL_NUMBER_EXCEED_FOR_100_35 =
        '*Barcode human readable text exceeding 16 characters wil be trimmed when printing in 100*35 size.';

    // RTI Index Messages
    public static FILE_UPLOADED_VALID = 'File successfully uploaded';
    public static FILE_UPLOADED_INVALID = 'File cannot be uploaded. Check file or try later.';
    public static INDEX_ADDED_SUCCESSFULLY = 'Index added successfully';
    public static INDEX_EDITED_SUCCESSFULLY = 'Index edited successfully';
    public static INDEX_ARCHIVED_SUCCESSFULLY = 'Index archived successfully';
    public static INDEX_UNDO_ARCHIVED_SUCCESSFULLY = 'Index unarchived successfully';
    public static MEASUREMENT_ARCHIVED_SUCCESSFULLY = 'Measurement archived successfully';
    public static MEASUREMENT_UNDO_ARCHIVED_SUCCESSFULLY = 'Measurement unarchived successfully';
    public static MEASUREMENT_ADDED_SUCCESSFULLY = 'Measurement added successfully';
    public static MEASUREMENT_EDITED_SUCCESSFULLY = 'Measurement edited successfully';
    // Start CR 211 Digital RTI Dashboard to be developed within DPS to capture RTR , 18-March-2024
    public static SEQUENCE_TAG_ADDED_SUCCESSFULLY = 'Sequence tag added successfully';
    public static SEQUENCE_TAG_EDITED_SUCCESSFULLY = 'Sequence tag edited successfully';
    public static TAG_ARCHIVED_SUCCESSFULLY = 'Sequence tag archived successfully';
    public static TAG_UNDO_ARCHIVED_SUCCESSFULLY = 'Sequence tag unarchived successfully';
    // End CR 211 Digital RTI Dashboard to be developed within DPS to capture RTR , 18-March-2024

    // RTI Productlist Message added
    public static PRODUCT_ADDED_SUCCESSFULLY = 'Product added successfully ';
    public static PRODUCT_EDITED_SUCCESSFULLY = 'Product edited successfully ';
    public static PRODUCT_COPIED_SUCCESSFULLY = 'Product copied successfully ';
    public static PRODUCT_DELETE_SUCCESSFULLY = 'Product deleted successfully ';
    // CH 15 : Consume SAP PO in DPS,8-May-2024
    public static PRODUCTION_ORDER_DELETED = 'Error : Production Order Deleted from SAP';
    constructor() { }
}
