// 154 (c), Recording production incidents, 5 Oct 2023
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IncidentDataSharingService {

  dataSubject: any;

  incidentList: any = [];

  sidebarObj: any = {};
  time: any;


  // send incident list to sidebar
  sendIncidentList(list: any[]) {

    this.incidentList.next(list);
  }

  // get incident list for sidebar
  getIncidentList(): Observable<any[]> {

    return this.incidentList.asObservable();
  }

  // Method to send incident data
  sendData(data: any) {

    this.dataSubject.next(data);
  }


  // To get incident data
  getData() {
    return this.dataSubject.asObservable();
  }


  // send data From Sidebar
  sendDataFromSidebar(data: any) {
    this.sidebarObj.next(data);
  }

  // get data from sidebar
  getDataFromSidebar(): Observable<any> {
    return this.sidebarObj.asObservable();
  }

  // send time
  sendStartingTime(data: any) {
    this.time.next(data);
  }

  // get time
  getStartingTime(): Observable<any> {
    return this.time.asObservable();
  }


  constructor() {
    this.time = new BehaviorSubject<any>({});
    this.dataSubject = new BehaviorSubject<any>({});
    this.incidentList = new BehaviorSubject<any[]>([]);
    this.sidebarObj = new BehaviorSubject<any>({});
  }
}
