import { Component, OnDestroy, OnInit, Inject, HostListener, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MsalService, BroadcastService } from '../../node_modules/@azure/msal-angular';
import { interval, Subscription } from '../../node_modules/rxjs';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import { AppInsightService } from './globalModule/inSights/app-insights-service';
import { environment } from 'src/environments/environment';
import { GlobalInsights } from './globalModule/inSights/globalInsight';
import { AuthenticationService } from './globalModule/authentication/authentication.service';
import { SharedService } from 'src/app/globalModule/shared.service';
import { HomeActionService } from './featureModules/home/home-action.service';
import { SwUpdate } from '@angular/service-worker';
import { DPS_Roles } from './globalModule/user-info';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'abb-app';
  loggedIn: boolean;
  email = '';
  public userInfo: any = null;
  private subscription: Subscription;
  public isIframe: boolean;
  tokenSuccessSubscription: any;
  loggedUser: any;
  showLoader: boolean;
  showError: boolean;
  errorMsg: any;
  GlobalMsg: any = {};
  isUserAllowed = true;
  serialNumber = '';
  CCRPNumber = '';
  suplierGuid = '';
  articleNumber = '';
  isCCRP: any;
  EmailID: string;
  CCRPAppId: string;
  ProductGroup = '';
  loggedInUser: string;
  dataToDisplay: { clientId: any; clientSecret: any; isCCRP: any; };
  data;
  @ViewChild('versionModal') versionModal;
  constructor(@Inject(DOCUMENT) private doc: any,
    private appInsightService: AppInsightService,
    private router: Router, private broadcastService: BroadcastService,
    private http: HttpClient, private authService: MsalService,
    private authencticationService: AuthenticationService,
    private loginService: AuthenticationService, private sharedService: SharedService,
    private location: LocationStrategy, private homeService: HomeActionService,
    private swUpdate: SwUpdate
  ) {
    this.swUpdate.available.subscribe(event => {
      $('#versionModal').modal();
    });
    this.swUpdate.checkForUpdate();
    this.checkForUpdate();
    history.pushState(null, null, window.location.href);
    sessionStorage.setItem('userUrl', window.location.href);

    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
      return false;
    });
    this.isIframe = window !== window.parent && !window.opener;
  }

  ngOnInit() {
    this.authencticationService.getCCRPDetails();

    this.appInsightService.logEvent(GlobalInsights.Message, GlobalInsights.AppLoaded);
    if (localStorage.getItem('msal.error') === 'login_required') {
      localStorage.clear();
      this.router.navigate(['login']);
    } else if (sessionStorage.getItem('Error')) {
      sessionStorage.removeItem('Error');
      this.router.navigate(['login']);
    } else if (localStorage.getItem('LoggedInEmailId') && localStorage.getItem('msal.idtoken')
      && sessionStorage.getItem('userDataTemp') === undefined) {
      this.showLoader = true;
      this.authencticationService.getUserDetails(localStorage.getItem('LoggedInEmailId')).subscribe(Response => {
        sessionStorage.setItem('userData', JSON.stringify(Response));
        setTimeout(() => {
          const data = JSON.parse(localStorage.getItem('GraphData'));
          const ServIsInstallationId = sessionStorage.getItem('ServIsInstallationId') !== null ?
            decodeURI(JSON.parse(sessionStorage.getItem('ServIsInstallationId'))) : undefined;
          const ServIsAppId = sessionStorage.getItem('ServIsAppId') !== null ?
            decodeURI(JSON.parse(sessionStorage.getItem('ServIsAppId'))) : undefined;

          if (JSON.parse(sessionStorage.getItem('CCRPAppId')) === environment.CCRPAppId ||
            (JSON.parse(sessionStorage.getItem('ServIsInstallationId')) !== null &&
              JSON.parse(sessionStorage.getItem('ServIsAppId')) !== null)) {
            this.showLoader = true;

            if (this.isUserAllowed) {
              this.showLoader = true;
              this.authencticationService.getUserDetails(data.mail)
                .subscribe(res => {
                  this.loggedUser = res;
                  if (res.isActive === true) {
                    sessionStorage.setItem('userData', JSON.stringify(res));
                  } else {
                    this.showLoader = false;
                    this.showError = true;
                    this.errorMsg = this.GlobalMsg.inactiveUser;
                  }
                },
                  error => {
                    if (error.status === 404) {
                      this.errorMsg = this.GlobalMsg.userNotFound;
                    } else if (error.status === 500) {
                      this.errorMsg = this.GlobalMsg.serverFault;
                    } else {
                      this.authService.acquireTokenRedirect([environment.scopes[1]]);
                    }
                    this.showLoader = false;
                    this.showError = true;
                  });
            }

          } else {
            const path = sessionStorage.getItem('userUrl');
            if (path === null) {
              this.authencticationService.setInitialUsrInfo('', '');
            } else if (path !== null && path !== undefined &&
              path === (environment.redirectURi + '/#/login') ||
              path === (environment.redirectURi + '/#/') ||
              path === (environment.redirectURi + '/')
              || path.indexOf('access_token=') > -1
              || path.indexOf('id_token=') > -1) {
              this.authencticationService.setInitialUsrInfo('', '');
              setTimeout(() => {
                sessionStorage.removeItem('userUrl');
              }, 1000);
            } else {
              const fullPath = path;
              if (fullPath !== undefined) {
                try {
                  const path1 = fullPath.split(environment.redirectURi + '/#')[1].slice
                    (1, fullPath.split(environment.redirectURi + '/#')[1].length);
                  this.router.navigateByUrl(path1);
                } catch {
                  this.router.navigate(['/login']);
                }
                setTimeout(() => {
                  sessionStorage.removeItem('userUrl');
                }, 1000);
              }
            }

          }
          this.showLoader = false;
        }, 1000);
      },
        (error) => {
          localStorage.clear();
          this.router.navigate(['/login']);

        });
    } else if (sessionStorage.getItem('userDataTemp') === undefined) {
      this.tokenSuccessSubscription = this.broadcastService.subscribe('msal:loginSuccess', (payload) => {
        this.showLoader = true;
        this.authencticationService.getUserInfoFromGraph()
          .subscribe(data => {
            sessionStorage.setItem('LoggedInEmailId', data.userPrincipalName);
            localStorage.setItem('LoggedInEmailId', data.userPrincipalName);
            localStorage.setItem('GraphData', JSON.stringify(data));
            if (JSON.parse(sessionStorage.getItem('CCRPAppId')) === environment.CCRPAppId ||
              (JSON.parse(sessionStorage.getItem('ServIsInstallationId')) !== null &&
                JSON.parse(sessionStorage.getItem('ServIsAppId')) !== null)) {
              this.authencticationService.getUserDetails(data.mail)
                .subscribe(Response => {
                  this.redirectUser(data, data.mail);

                },
                  error => {
                    if (error.status === 404) {
                      data.mail = 'pg-ch-dps-rediracc@abb.com';
                      this.redirectUser(data, data.mail);

                    }

                  });
            }
            if (this.isUserAllowed) {
              this.showLoader = true;
              this.authencticationService.getUserDetails(data.mail)
                .subscribe(Response => {
                  this.loggedUser = Response;
                  this.showLoader = false;
                  if (Response.isActive === true) {
                    sessionStorage.setItem('userData', JSON.stringify(Response));
                  } else {
                    this.showError = true;
                    this.errorMsg = this.GlobalMsg.inactiveUser;
                  }
                },
                  error => {
                    if (error.status === 404) {
                      this.errorMsg = this.GlobalMsg.userNotFound;
                    } else if (error.status === 500) {
                      this.errorMsg = this.GlobalMsg.serverFault;
                    } else {
                      this.authService.acquireTokenRedirect([environment.scopes[1]]);
                    }
                    this.showLoader = false;
                    this.showError = true;
                  });
            }

          }, (error) => {
            this.showError = true;
            this.errorMsg = this.GlobalMsg.serverFault;
            this.showLoader = false;
          });
      });
    }
  }
  // Define a function to handle back button and use anywhere
  preventBackButton() {
    history.pushState(null, null, location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }
  ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      localStorage.clear();
      this.subscription.unsubscribe();
    }
  }

  get authenticated(): any {
    return this.authService.getUser();
  }
  redirectUser(data, LoggedInEmailId) {
    this.showLoader = true;
    if (this.isUserAllowed) {
      const ServIsInstallationId = sessionStorage.getItem('ServIsInstallationId') !== null ?
        decodeURI(JSON.parse(sessionStorage.getItem('ServIsInstallationId'))) : undefined;
      const ServIsSerialNo = sessionStorage.getItem('ServIsSerialNo') !== 'undefined' ?
        decodeURI(JSON.parse(sessionStorage.getItem('ServIsSerialNo'))) : 'null';
      const ServIsAppId = sessionStorage.getItem('ServIsAppId') !== null ?
        decodeURI(JSON.parse(sessionStorage.getItem('ServIsAppId'))) : undefined;

      this.authencticationService.getUserDetails(data.mail).subscribe(Response => {

        Response.firstName = data.givenName;
        Response.lastName = data.surname;
        sessionStorage.setItem('userData', JSON.stringify(Response));
        localStorage.setItem('userData', JSON.stringify(Response));

        this.loggedUser = Response;
        this.CCRPAppId = JSON.parse(sessionStorage.getItem('CCRPAppId'));

        if (ServIsInstallationId !== undefined && ServIsAppId !== undefined) {
          const data1 = {
            'SerialNumber': ServIsSerialNo,
            'InstallationId': ServIsInstallationId,
            'ServISAppId': ServIsAppId,
            'EmailId': data.mail,
            'SuplierGuid': null,
            'ArticleNumber': null,
            'LoggedInUser': LoggedInEmailId
          };

          this.homeService.saveServIsDetails(data1).subscribe((res) => {
            this.searchSerialNumber(res, '', '');
            this.showLoader = false;

          }, (error) => {
            this.showLoader = false;
            this.router.navigate(['/home']);

          });
        } else if (this.CCRPAppId === environment.CCRPAppId) {

          this.isCCRP = JSON.parse(sessionStorage.getItem('isCCRP'));
          if (sessionStorage.getItem('SerialNumber') !== 'undefined') {
            this.serialNumber = decodeURI(JSON.parse(sessionStorage.getItem('SerialNumber')));
          }
          if (sessionStorage.getItem('CCRPNumber') !== 'undefined') {
            this.CCRPNumber = decodeURI(JSON.parse(sessionStorage.getItem('CCRPNumber')));
          }
          if (sessionStorage.getItem('SupplierGuid') !== 'undefined') {
            this.suplierGuid = decodeURI(JSON.parse(sessionStorage.getItem('SupplierGuid')));
          }
          if (sessionStorage.getItem('ArticleNumber') !== 'undefined') {
            this.articleNumber = decodeURI(JSON.parse(sessionStorage.getItem('ArticleNumber')));
          }
          if (sessionStorage.getItem('EmailID') !== 'undefined') {
            this.EmailID = decodeURI(JSON.parse(sessionStorage.getItem('EmailID')));
          }
          if (sessionStorage.getItem('ProductGroup') !== 'undefined') {
            this.ProductGroup = decodeURI(JSON.parse(sessionStorage.getItem('ProductGroup')));
          }
          if (sessionStorage.getItem('CCRPAppId') !== 'undefined') {
            this.CCRPAppId = decodeURI(JSON.parse(sessionStorage.getItem('CCRPAppId')));
          }
          this.loggedInUser = localStorage.getItem('LoggedInEmailId');

          this.authencticationService.updateLoginDateForUser(Response.userId).subscribe(r1 => {
            this.dataToDisplay = this.authencticationService.getCCRPDetails();

            if (this.dataToDisplay.isCCRP === environment.isCCRP) {
              const data1 = {
                'SerialNumber': this.serialNumber,
                'CCRPNumber': this.CCRPNumber === '' ? null : this.CCRPNumber,
                'CCRPAppId': this.CCRPAppId,
                'EmailId': this.EmailID,
                'SuplierGuid': this.suplierGuid === '' ? null : this.suplierGuid,
                'ArticleNumber': this.articleNumber === '' ? null : this.articleNumber,
                'ProductGroupID': this.ProductGroup === '' ? null : this.ProductGroup,
                'LoggedInUser': this.loggedInUser
              };

              if (data1.SerialNumber && data1.CCRPNumber && data1.CCRPAppId && data1.ArticleNumber && data1.SuplierGuid) {
                this.homeService.saveCCRPDetails(data1).subscribe((res) => {
                  this.showLoader = false;
                  this.searchSerialNumber(this.serialNumber, this.articleNumber, this.suplierGuid);
                }, (error) => {
                  this.showLoader = false;
                  this.searchSerialNumber(this.serialNumber, this.articleNumber, this.suplierGuid);
                });
              } else {
                this.showLoader = false;
                this.searchSerialNumber(this.serialNumber, this.articleNumber, this.suplierGuid);
              }
            }
          },
            (error) => {
              console.log(error);
            });
        } else {
          if (Response.isActive === true) {
            this.authencticationService.updateLoginDateForUser(Response.userId).subscribe(r1 => {
              Response.firstName = data.givenName;
              Response.lastName = data.surname;
              sessionStorage.setItem('userData', JSON.stringify(Response));
              localStorage.setItem('userData', JSON.stringify(Response));
            },
              (error) => {
                console.log(error);
              });
          } else {
            this.showLoader = false;
            this.showError = true;
            this.errorMsg = this.GlobalMsg.inactiveUser;
          }
        }
        Response.firstName = data.givenName;
        Response.lastName = data.surname;
        sessionStorage.setItem('userData', JSON.stringify(Response));
        localStorage.setItem('userData', JSON.stringify(Response));
        if (this.dataToDisplay.isCCRP === environment.isCCRP || ServIsInstallationId !== undefined
          && ServIsSerialNo !== undefined && ServIsAppId !== undefined) {
          this.authencticationService.setInitialUsrInfo(this.data, data.mail);
        } else {
          this.authencticationService.setInitialUsrInfo('', '');
        }

      },
        error => {
          if (error.status === 404) {
            this.errorMsg = this.GlobalMsg.userNotFound;
          } else if (error.status === 500) {
            this.errorMsg = this.GlobalMsg.serverFault;
          } else {

            this.authService.acquireTokenRedirect([environment.scopes[1]]);
          }
          this.showLoader = false;
          this.showError = true;
        });
    }
  }

  searchSerialNumber(serialNumber, articleNumber, supplierGUID) {
    this.showLoader = true;
    this.homeService.searchOrderDetails(articleNumber, supplierGUID, serialNumber)
      .subscribe((Response) => {
        this.showLoader = false;
        this.data = Response;
        if (Response.status === 200) {
          const searchData = Response.body;
          {
            if (searchData.itemType === 'Component') {
              this.router.navigate([
                '/home/componentdetail',
                searchData.orderLineID,
                searchData.supplierGUID,
                searchData.serialNumber,
                searchData.traceabilityID,
                searchData.articleNumber,
                0,
                0
              ]);
            } else {
              this.router.navigate([
                '/home/productdetail',
                searchData.articleNumber,
                searchData.serialNumber,
                searchData.supplierGUID,
              ]);
            }
          }
        } else if (Response.status === 204) {
          this.showLoader = false;
          this.router.navigate(['/home']);
        } else if (Response.status === 202) {
          this.showLoader = false;
          sessionStorage.setItem('CCRPData', JSON.stringify(Response.body));
          this.router.navigate(['/home']);
        } else {
          this.showLoader = false;
          this.router.navigate(['/home']);
        }
      });
  }
  checkForUpdate() {
    if (this.swUpdate.isEnabled) {
      interval(30000).subscribe(() => this.swUpdate.checkForUpdate().then(() => {
        setTimeout(() => {
          this.swUpdate.available.subscribe((event) => {
            $('#versionModal').modal();
          });
        }, 1000);
      }));
    }
  }
  reloadThePage() {
    window.location.reload();
  }
}
