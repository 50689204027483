import { Component, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-scroll-totop',
  templateUrl: './scroll-totop.component.html',
  styleUrls: ['./scroll-totop.component.scss']
})
export class ScrollTotopComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  private _showBtn$ = fromEvent(document, 'scroll').pipe(
    debounceTime(50),
    map(() => window.scrollY > 500),
    tap(() => console.log())
  );
  public get showBtn$() {
    return this._showBtn$;
  }
  public set showBtn$(value) {
    this._showBtn$ = value;
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
