import { Injectable } from '@angular/core';
import { Observable } from 'node_modules/rxjs';
import { GlobalURL } from '../globalUrl';
import { HttpClient, HttpResponse } from 'node_modules/@angular/common/http';
import { SharedService } from '../shared.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderActionService {

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  // Impersonate user
  impersonateUser(params): Observable<any> {
    const encodeEmail = this.sharedService.encodeData(params.user_email_id);
    // Start, trim supplier guid, 1-Sep-2023
    const url: string = GlobalURL.IMPERSONATE_USER + encodeEmail + '/username/' +
      this.sharedService.encodeData(params.user_name) + '/userrole/' +
      this.sharedService.encodeData(params.user_role) + '/usertype/' +
      this.sharedService.encodeData(params.user_type)
      + '/supplier/' + this.sharedService.encodeData(params.supplier_guid.trim());
    return this.http.get(url);
  }

  getSupplierNotificationCount(supplierID): Observable<any> {
    const url = GlobalURL.NOTIFICATION_COUNT + '?supplierId=' + this.sharedService.encodeData(supplierID);
    return this.http.get(url);
  }

  getSupplierNotificationMessage(supplierID): Observable<any> {
    const url = GlobalURL.NOTIFICATION_MESSAGE + '?supplierId=' + this.sharedService.encodeData(supplierID);
    return this.http.get(url);
  }

  getAdvanceSearchNotificationMessage(): Observable<any> {
    const url = GlobalURL.NOTIFICATION_MESSAGE_AD_SEARCH;
    return this.http.get(url);
  }

  clearAdvanceSearchNotification(data): Observable<any> {
    const url = GlobalURL.CLEAR_NOTIFICATION_AD_SEARCH;
    return this.http.post(url, data);
  }


  clearSupplierNotification(data): Observable<any> {
    const url = GlobalURL.CLEAR_NOTIFICATION;
    return this.http.put(url, data);
  }
}
