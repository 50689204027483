import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector : '[appFormControlTrimDirective] '
})
export class FormControlTrimDirectiveDirective implements OnInit {
  constructor(private el: ElementRef, private ngControl: NgControl) {}

  ngOnInit() {
    if (this.ngControl.control) {
      this.ngControl.control.valueChanges
        .subscribe((changes: string) => {
          if (changes === null || changes === '') {
            return;
          }
          const trimmed = changes.trim();
          if (trimmed !== changes) {
            this.ngControl.control.setValue(trimmed);
          }
          const replacedData = trimmed.replace(/\r?\n|\r/g, '').replace(/\r?\t|\r/g, '').replace(/\r?\r|\r/g, '');
          if (replacedData !== changes) {
            this.ngControl.control.setValue(replacedData);
          }
        });
    } else {
      console.error('FormControlTrimDirective: FormControl must exist!');
    }
  }

}
