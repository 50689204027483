export const configurationData = {
    'testJobInformationControls': [
        {
            'controlName': 'singleTextBox',
            'label': 'Order number',
            'value': 'DPS Project',
            'backendLabel': 'OrderNumber'
        },
        {
            'controlName': 'singleTextBox',
            'label': 'Installation',
            'value': 'FUNLAND',
            'backendLabel': 'Anlage'
        },
        {
            'controlName': 'singleTextBox',
            'label': 'Purchaser',
            'value': 'Hitachi',
            'backendLabel': 'Besteller'
        },
        {
            'controlName': 'dropDown',
            'label': 'Language for protocol',
            'dropDownArrayValue': [
                'EN_DE',
                'FR_DE'
            ],
            'dropDownSelectedValue': 'EN_DE',
            'backendLabelDropdown': 'Language_for_protocol'
        },
    ],
    'technicalJobDataControls': [
        {
            'controlName': 'dropDown',
            'label': 'Test according to',
            'dropDownArrayValue': [
                'IEC 62271-1,-100,-203',
                'IEEE C37.04, C37.09, C37.122'
            ],
            'dropDownSelectedValue': 'IEC 62271-1,-100,-203',
            'backendLabelDropdown': 'Norm'
        },
        {
            'controlName': 'dropDown',
            'label': 'Rated voltage [kV]',
            'dropDownArrayValue': [
                '245',
                '300',
                '362',
                '420',
                '550',
                '800',
                '1100',
                '1200',
                '145'
            ],
            'dropDownSelectedValue': '145',
            'backendLabelDropdown': 'Nennspannung'
        },
        {
            'controlName': 'singleTextBox',
            'label': 'Rated current [A]',
            'value': '4000',
            'backendLabel': 'Nennstrom'
        },
        {
            'controlName': 'singleTextBox',
            'label': 'Rated frequency [Hz]',
            'value': '50',
            'backendLabel': 'Nennfrequenz'
        },
        {
            'controlName': 'singleTextBox',
            'label': 'Nominal Pressure [kP]',
            'value': '700 / 600',
            'backendLabel': 'Nennfulldruck_LS'
        },
        {
            'controlName': 'singleTextBox',
            'label': 'Phase Pattern',
            'value': 'L1-L2-L3',
            'backendLabel': 'Phase_Pattern'
        }
    ],
    'resistanceControls': [
        {
            'controlName': 'measurement',
            'label': 'Coil resistance ',
            'minvalue': '20.5',
            'maxvalue': '26.7',
            'unit': 'ohm',
            'backendLabelMinValue': 'Coil_Resistance_Min',
            'backendLabelMaxValue': 'Coil_Resistance_Max'
        },
        {
            'controlName': 'measurement',
            'label': 'Heating resistance CB ',
            'minvalue': '1613',
            'maxvalue': '2084',
            'unit': 'ohm',
            'backendLabelMinValue': 'Heating_Resistance_Min_CB',
            'backendLabelMaxValue': 'Heating_Resistance_Max_CB'
        },
        // {
        //     'controlName': 'measurement',
        //     'label': 'Heating resistance DS/ES ',
        //     'minvalue': '200',
        //     'maxvalue': '2000',
        //     'unit': 'ohm',
        //     'backendLabelMinValue': 'Heating_Resistance_Min_TE',
        //     'backendLabelMaxValue': 'Heating_Resistance_Max_TE'
        // },
        // {
        //     'controlName': 'measurement',
        //     'label': 'Heating resistance FAES ',
        //     'minvalue': '200',
        //     'maxvalue': '2000',
        //     'unit': 'ohm',
        //     'backendLabelMinValue': 'Heating_Resistance_Min_FAES',
        //     'backendLabelMaxValue': 'Heating_Resistance_Max_FAES'
        // },
    ],
    'voltageControls': [
        {
            'controlName': 'textbox dropdown',
            'label': 'Control voltage ',
            'value': '110',
            'unitArrayValue': [
                'AC',
                'DC'
            ],
            'selectedUnitValue': 'DC',
            'backendLabel': 'Steuerspannung',
            'backendLabelDropdown': 'Controlvoltage_AC_DC',
            'unit': 'V'
        }
    ],
    'voltageCircuitBreakerControls': [
        {
            'controlName': 'measurement',
            'label': 'Close coil voltage[min/max] ',
            'minvalue': '85',
            'maxvalue': '110',
            'unit': '%',
            'backendLabelMinValue': 'U_EinSpuleMin_U',
            'backendLabelMaxValue': 'U_EinSpuleMax_U'
        },
        {
            'controlName': 'measurement',
            'label': 'Open coil voltage[min/max]',
            'minvalue': '70',
            'maxvalue': '110',
            'unit': '%',
            'backendLabelMinValue': 'U_AusSpuleMin_U',
            'backendLabelMaxValue': 'U_AusSpuleMax_U'
        },
        {
            'controlName': 'textbox dropdown',
            'label': 'Motor voltage ',
            'value': '110',
            'unitArrayValue': [
                'AC',
                'DC'
            ],
            'selectedUnitValue': 'DC',
            'backendLabel': 'Motorspannung',
            'backendLabelDropdown': 'MotorspannungAC_DC',
            'unit': 'V'
        },
        {
            'controlName': 'measurement',
            'label': 'Motor voltage[min/max]',
            'minvalue': '85',
            'maxvalue': '110',
            'unit': '%',
            'backendLabelMinValue': 'U_MotorMin_U',
            'backendLabelMaxValue': 'U_MotorMax_U'
        },
        {
            'controlName': 'textbox dropdown',
            'label': 'Heating voltage ',
            'value': '230',
            'unitArrayValue': [
                'AC',
                'DC'
            ],
            'selectedUnitValue': 'AC',
            'backendLabel': 'HeizungsspannungPrufung',
            'backendLabelDropdown': 'Heating_AC_DC',
            'unit': 'V'

        }
    ],
    'circuitBreakerControls': [
        {
            'controlName': 'singleTextBox',
            'label': 'CB test instruction',
            'value': '2GHV088904',
            'backendLabel': 'CB_Prufanweisung'
        },
        {
            'controlName': 'singleTextBox',
            'label': 'CB wiring diagram',
            'value': '1HDZ349066R0287',
            'backendLabel': 'CB_WD'
        },
        {
            'controlName': 'dropDown',
            'label': 'O-CO-t-CO[s]',
            'dropDownArrayValue': [
                '15',
                '60',
                '180'
            ],
            'dropDownSelectedValue': '180',
            'backendLabelDropdown': 'CO_t_CO'
        },
        {
            'controlName': 'singleTextBox',
            'label': 'CB type',
            'value': '',
            'backendLabel': 'CB_Type'
        },
        {
            'controlName': 'dropDown',
            'label': 'Drive type',
            'dropDownArrayValue': [
                'HMB 1.6',
                'HMB 2.3',
                'HMB 2.3s'
            ],
            'dropDownSelectedValue': 'HMB 1.6',
            'backendLabelDropdown': 'DriveType'
        },
        {
            'controlName': 'dropDown',
            'label': 'Switching mechanism',
            'dropDownArrayValue': [
                '3 Pol 1 Valve (STR)',
                '3 Pol 3 Valves (STR)',
                'LS Line',
                'Repair Line'
            ],
            'dropDownSelectedValue': '3 Pol 1 Valve (STR)',
            'backendLabelDropdown': 'SwitchingMechanism'
        },
        {
            'controlName': 'dropDown',
            'label': 'Count Open Coil',
            'dropDownArrayValue': [
                '1',
                '2',
                '3'
            ],
            'dropDownSelectedValue': '3',
            'backendLabelDropdown': 'CountOpenCoil'
        },
        {
            'controlName': 'dropDown',
            'label': 'Count Close Coil',
            'dropDownArrayValue': [
                '1',
                '2',
                '3'
            ],
            'dropDownSelectedValue': '2',
            'backendLabelDropdown': 'CountCloseCoil'
        },
        {
            'controlName': 'measurement dropdown',
            'label': 'Closing speed ',
            'minvalue': '1.8',
            'maxvalue': '2.3',
            'selectedUnitValue': 'm/s',
            'unitArrayValue': [
                'm/s',
                '°/ms'
            ],
            'backendLabelMaxValue': 'Closingspeed_CB_Max',
            'backendLabelMinValue': 'Closingspeed_CB_Min',
            'backendLabelDropdown': 'SpeedUnit_CB'
        },
        {
            'controlName': 'measurement dropdown',
            'label': 'Opening speed (P_Full)',
            'minvalue': '3.1',
            'maxvalue': '3.7',
            'selectedUnitValue': '°/ms',
            'unitArrayValue': [
                'm/s',
                '°/ms'
            ],
            'backendLabelMaxValue': 'Openingspeed_CB_Max',
            'backendLabelMinValue': 'Openingspeed_CB_Min',
            'backendLabelDropdown': 'SpeedUnit_CB'
        },
        {
            'controlName': 'measurement dropdown',
            'label': 'Stroke ',
            'minvalue': '74.8',
            'maxvalue': '79',
            'selectedUnitValue': '°',
            'unitArrayValue': [
                'mm',
                'cm',
                '°'
            ],
            'backendLabelMinValue': 'Hub_Min',
            'backendLabelMaxValue': 'Hub_Max',
            'backendLabelDropdown': 'StrokeUnit_CB'
        },
        {
            'controlName': 'measurement',
            'label': 'Opening time ',
            'minvalue': '22',
            'maxvalue': '38',
            'unit': 'ms',
            'backendLabelMaxValue': 'Openingtime_CB_Max',
            'backendLabelMinValue': 'Openingtime_CB_Min',
            'backendLabelUnit': ''
        },
        {
            'controlName': 'measurement',
            'label': 'Closing time',
            'minvalue': '43',
            'maxvalue': '65',
            'unit': 'ms',
            'backendLabelMinValue': 'Closingtime_CB_Min',
            'backendLabelMaxValue': 'Closingtime_CB_Max',
            'backendLabelUnit': ''
        },
        {
            'controlName': 'measurement',
            'label': 'CO time 14',
            'minvalue': '',
            'maxvalue': '40',
            'unit': 'ms',
            'backendLabelMinValue': 'CB_CO_time_t4_min',
            'backendLabelMaxValue': 'CB_CO_time_t4_max',
            'backendLabelUnit': ''
        },
        {
            'controlName': 'measurement dropdown',
            'label': 'CO stroke ',
            'minvalue': '74.8',
            'maxvalue': '74.8',
            'selectedUnitValue': '°',
            'unitArrayValue': [
                'mm',
                'cm',
                '°'
            ],
            'backendLabelMinValue': 'CO_stroke_min',
            'backendLabelDropdown': 'StrokeUnit_CB'
        },
        {
            'controlName': 'measurement',
            'label': 'Complete Storage',
            'minvalue': '',
            'maxvalue': '35',
            'unit': 'ms',
            'backendLabelMinValue': 'LS_Motorruntime_min',
            'backendLabelMaxValue': 'LS_Motorruntime_max',
            'backendLabelUnit': ''
        },
        {
            'controlName': 'measurement',
            'label': 'CO asyncronity',
            'maxvalue': '6',
            'unit': 'ms',
            'backendLabelMaxValue': 'CO_async',
            'backendLabelUnit': ''
        },
        {
            'controlName': 'measurement',
            'label': 'C asyncronity',
            'maxvalue': '3',
            'unit': 'ms',
            'backendLabelMaxValue': 'Single_async_C',
            'backendLabelUnit': ''
        },
        {
            'controlName': 'measurement',
            'label': 'O asyncronity',
            'maxvalue': '3',
            'unit': 'ms',
            'backendLabelMaxValue': 'Single_async_O',
            'backendLabelUnit': ''
        },
        {
            'controlName': 'measurement',
            'label': 'Auxiliary contacts bouncing',
            'maxvalue': '',
            'unit': 'ms',
            'backendLabelMaxValue': 'AuxDebounceMax',
            'backendLabelUnit': ''
        },
        {
            'controlName': 'measurement',
            'label': 'Main contact OPEN bouncing',
            'maxvalue': '0.4',
            'unit': 'ms',
            'backendLabelMaxValue': 'MainContactOpenBounceMax',
            'backendLabelUnit': ''
        },
        {
            'controlName': 'delay test section',
            'label': 'Delay after test',
            'textLabel1': 'C',
            'backendTextLabel1': 'DelayAfterTest_CB_C',
            'valueLabel1': '10',
            'textLabel2': 'O',
            'backendTextLabel2': 'DelayAfterTest_CB_O',
            'valueLabel2': '12',
            'textLabel3': 'CO',
            'backendTextLabel3': 'DelayAfterTest_CB_CO',
            'valueLabel3': '15',
            'textLabel4': 'OCO',
            'backendTextLabel4': 'DelayAfterTest_CB_OCO',
            'valueLabel4': '25',
        }
    ],
    'circuitBreakerMeasurementPointsControl': [
        {
            controlName: 'measurement dropdown',
            heading: 'CLOSE Speed #1',
            headerForLP: 'Lower point',
            headerForUP: 'Upper point',
            maxvalueForLP: '',
            minvalueForLP: '48.1',
            maxvalueForUP: '',
            minvalueForUP: '38.5',
            unitArrayValueForLP: [
                'msec',
                'mm,degree',
                '% of stroke',
                '% of contact make',
                'mm,grad'
            ],
            selectedUnitValueForLP: 'mm,grad',
            unitArrayValueForUP: [
                'msec',
                'mm,degree',
                '% of stroke',
                '% of contact make',
                'mm,grad'
            ],
            selectedUnitValueForUP: 'mm,grad',
            dropDownArrayValueForLP: [
                'Contact make',
                'Stroke [absolute]',
                'Stroke [relative]',
                'Time from initiation',
                'Hub [absolut]'
            ],
            dropDownSelectedValueForLP: 'Hub [absolut]',
            dropDownArrayValueForUP: [
                'Contact make',
                'Stroke [absolute]',
                'Stroke [relative]',
                'Time from initiation',
                'Hub [absolut]'
            ],
            dropDownSelectedValueForUP: 'Hub [absolut]',
            backendLabelMaxValueUP: 'CB_C_V1_P1_W1',
            backendLabelMaxValueLP: 'CB_C_V1_P2_W1',
            backendLabelMinValueUP: 'CB_C_V1_P1_W2',
            backendLabelMinValueLP: 'CB_C_V1_P2_W2',
            backendLabelDopdownValueUP: 'CB_C_V1_P1_RM',
            backendLabelDopdownValueLP: 'CB_C_V1_P2_RM',
            backendLabelUnitArrayValueUP: 'CB_C_V1_P1_RM2',
            backendLabelUnitArrayValueLP: 'CB_C_V1_P2_RM2'
        },
        {
            controlName: 'measurement dropdown',
            heading: 'CLOSE Speed #2',
            headerForLP: 'Lower point',
            headerForUP: 'Upper point',
            maxvalueForLP: '',
            minvalueForLP: '',
            maxvalueForUP: '',
            minvalueForUP: '',
            unitArrayValueForLP: [
                'msec',
                'mm,degree',
                '% of stroke',
                '% of contact make',
                'mm,grad'
            ],
            selectedUnitValueForLP: '',
            unitArrayValueForUP: [
                'msec',
                'mm,degree',
                '% of stroke',
                '% of contact make',
                'mm,grad'
            ],
            selectedUnitValueForUP: '',
            dropDownArrayValueForLP: [
                'Contact make',
                'Stroke [absolute]',
                'Stroke [relative]',
                'Time from initiation',
                'Point 1',
                'Hub [absolut]'
            ],
            dropDownSelectedValueForLP: '',
            dropDownArrayValueForUP: [
                'Contact make',
                'Stroke [absolute]',
                'Stroke [relative]',
                'Time from initiation',
                'Hub [absolut]'
            ],
            dropDownSelectedValueForUP: '',
            backendLabelMaxValueUP: 'CB_C_V2_P1_W1',
            backendLabelMaxValueLP: 'CB_C_V2_P2_W1',
            backendLabelMinValueUP: 'CB_C_V2_P1_W2',
            backendLabelMinValueLP: 'CB_C_V2_P2_W2',
            backendLabelDopdownValueUP: 'CB_C_V2_P1_RM',
            backendLabelDopdownValueLP: 'CB_C_V2_P2_RM',
            backendLabelUnitArrayValueUP: 'CB_C_V2_P1_RM2',
            backendLabelUnitArrayValueLP: 'CB_C_V2_P2_RM2'
        },
        {
            controlName: 'measurement dropdown',
            heading: 'OPEN',
            headerForLP: 'Lower point',
            headerForUP: 'Upper point',
            maxvalueForLP: '',
            minvalueForLP: '34',
            maxvalueForUP: '',
            minvalueForUP: '70',
            unitArrayValueForLP: [
                'msec',
                'mm,degree',
                '% of stroke',
                '% of contact make',
                'mm,grad'
            ],
            selectedUnitValueForLP: 'mm,grad',
            unitArrayValueForUP: [
                'msec',
                'mm,degree',
                '% of stroke',
                '% of contact make',
                'mm,grad'
            ],
            selectedUnitValueForUP: 'mm,grad',
            dropDownArrayValueForLP: [
                'Contact break',
                'Stroke [absolute]',
                'Stroke [relative]',
                'Time from initiation',
                'Point 1',
                'Hub [absolut]'
            ],
            dropDownSelectedValueForLP: 'Hub [absolut]',
            dropDownArrayValueForUP: [
                'Contact break',
                'Stroke [absolute]',
                'Stroke [relative]',
                'Time from initiation',
                'Hub [absolut]'
            ],
            dropDownSelectedValueForUP: 'Hub [absolut]',
            backendLabelMaxValueUP: 'CB_O_V1_P1_W1',
            backendLabelMaxValueLP: 'CB_O_V1_P2_W1',
            backendLabelMinValueUP: 'CB_O_V1_P1_W2',
            backendLabelMinValueLP: 'CB_O_V1_P2_W2',
            backendLabelDopdownValueUP: 'CB_O_V1_P1_RM',
            backendLabelDopdownValueLP: 'CB_O_V1_P2_RM',
            backendLabelUnitArrayValueUP: 'CB_O_V1_P1_RM2',
            backendLabelUnitArrayValueLP: 'CB_O_V1_P2_RM2'
        }
    ],

    'fastActingEarthingSwicthControls': [
        {
            'controlName': 'singleTextBox',
            'label': 'FAES test instruction ',
            'value': '1HDI 912 301 B'
        },
        {
            'controlName': 'singleTextBox',
            'label': 'FAES wiring diagram',
            'value': '1HDZ349056R0301'
        },
        {
            'controlName': 'dropDown',
            'label': 'Limit filter',
            'dropDownArrayValue': [
                'Unom',
                'Unom1'
            ],
            'dropDownSelectedValue': ''
        },
        {
            'controlName': 'measurement',
            'label': 'Constant motor current',
            'minvalue': '',
            'maxvalue': '',
            'unit': ''
        },
        {
            'controlName': 'measurement',
            'label': 'Peak motor current',
            'minvalue': '',
            'maxvalue': '',
            'unit': ''
        },
        {
            'controlName': 'measurement',
            'label': 'Motor runtime ON',
            'minvalue': '',
            'maxvalue': '',
            'unit': ''
        },
        {
            'controlName': 'measurement',
            'label': 'Motor runtime OFF',
            'minvalue': '',
            'maxvalue': '',
            'unit': ''
        },
        {
            'controlName': 'measurement',
            'label': 'Closing time',
            'minvalue': '',
            'maxvalue': '',
            'unit': ''
        },
        {
            'controlName': 'measurement',
            'label': 'Contact asyncronity',
            'minvalue': '',
            'maxvalue': '',
            'unit': ''
        },
        {
            'controlName': 'measurement dropdown',
            'label': 'Stroke',
            'minvalue': '',
            'maxvalue': '',
            'selectedUnitValue': '',
            'unitArrayValue': [
                'mm',
                'cm'
            ]
        },
        {
            'controlName': 'measurement dropdown',
            'label': 'Stroke after contact touch',
            'minvalue': '',
            'maxvalue': '',
            'selectedUnitValue': '',
            'unitArrayValue': [
                'mm',
                'cm'
            ]
        },
        {
            'controlName': 'measurement dropdown',
            'label': 'Overtravel',
            'minvalue': '',
            'maxvalue': '',
            'selectedUnitValue': '',
            'unitArrayValue': [
                'mm',
                'cm'
            ]
        },
        {
            'controlName': 'measurement dropdown',
            'label': 'Close Speed',
            'minvalue': '',
            'maxvalue': '',
            'selectedUnitValue': '',
            'unitArrayValue': [
                'mm',
                'cm'
            ]
        },
        {
            'controlName': 'measurement dropdown',
            'label': 'Open Speed 1',
            'minvalue': '',
            'maxvalue': '',
            'selectedUnitValue': '',
            'unitArrayValue': [
                'mm',
                'cm'
            ]
        },
        {
            'controlName': 'measurement dropdown',
            'label': 'Open Speed 2',
            'minvalue': '',
            'maxvalue': '',
            'selectedUnitValue': '',
            'unitArrayValue': [
                'mm',
                'cm'
            ]
        },
        {
            'controlName': 'measurement',
            'label': 'Opening time',
            'minvalue': '',
            'maxvalue': '',
            'unit': ''
        },
        {
            'controlName': 'measurement',
            'label': 'Auxiliary contact 38 CLOSE bouncing',
            'minvalue': '',
            'maxvalue': '',
            'unit': ''
        },
        {
            'controlName': 'singleTextBox',
            'label': 'Delay after test',
            'value': ''
        }
    ],
    'disconnectorControls': [
        {
            'controlName': 'singleTextBox',
            'label': 'DS/ES test instruction',
            'value': ''
        },
        {
            'controlName': 'singleTextBox',
            'label': 'DS/ES wiring diagram',
            'value': ''
        },
        {
            'controlName': 'measurement dropdown',
            'label': 'Stroke ',
            'minvalue': '',
            'maxvalue': '',
            'selectedUnitValue': '',
            'unitArrayValue': [
                'mm',
                'cm'
            ]
        },
        {
            'controlName': 'measurement',
            'label': 'Opening Time ',
            'minvalue': '',
            'maxvalue': '',
            'unit': 'mm'
        },
        {
            'controlName': 'measurement',
            'label': 'Closing Time ',
            'minvalue': '',
            'maxvalue': '',
            'unit': 'mm'
        },
        {
            'controlName': 'measurement',
            'label': 'Constant motor current',
            'minvalue': '',
            'maxvalue': '',
            'unit': 'A'
        },
        {
            'controlName': 'measurement',
            'label': 'Peak motor current',
            'minvalue': '',
            'maxvalue': '',
            'unit': 'A'
        },
        {
            'controlName': 'measurement',
            'label': 'Motor runtime ON',
            'minvalue': '',
            'maxvalue': '',
            'unit': '$'
        },
        {
            'controlName': 'measurement',
            'label': 'Motor runtime OFF',
            'minvalue': '',
            'maxvalue': '',
            'unit': '$'
        },
    ],
    'dSESVoltageControls': [{
        'controlName': 'measurement',
        'label': 'Close Coil Voltage[min/max] ',
        'minvalue': '',
        'maxvalue': '',
        'unit': '%'
    },
    {
        'controlName': 'measurement',
        'label': 'Close Coil Voltage[min/max]',
        'minvalue': '',
        'maxvalue': '',
        'unit': '%'
    },
    {
        'controlName': 'measurement dropdown',
        'label': 'Motor Voltage ',
        'minvalue': '',
        'maxvalue': '',
        'unitArrayValue': [
            'AC',
            'DC'
        ],
        'selectedUnitValue': 'V',
    },
    {
        'controlName': 'measurement',
        'label': 'Motor Voltage[min/max]',
        'minvalue': '',
        'maxvalue': '',
        'unit': 'V'
    },
    ],
    'earthingSwitchMeasurementPointsControl': [
        {
            'controlName': 'measurement dropdown',
            'heading': 'CLOSE',
            'upperPoint': {
                'maxvalue': '',
                'backendLabelMaxValueUP': 'C_V1_P1_W1_FAES',
                'minvalue': '-5',
                'backendLabelMinValueUP': 'C_V1_P1_W2_FAES',
                'header': 'Upper point',
                'dropDownSelectedValue': 'Contack break',
                'dropDownArrayValue': [
                    'Contack break',
                    'Contack break1'
                ],
                'backendLabelDopdownValueUP': 'C_V1_P1_RM_FAES',
                'unitArrayValue': [
                    'msec',
                    'msec1'
                ],
                'backendLabelUnitArrayValueUP': 'C_V1_P1_RM2_FAES',
                'unitArraySelectedValue': 'msec',
            },
            'lowerPoint': {
                'maxvalue': '',
                'backendLabelMaxValueLP': 'C_V1_P2_W1_FAES',
                'minvalue': '10',
                'backendLabelMinValueLP': 'C_V1_P2_W2_FAES',
                'header': 'Lower point',
                'dropDownArrayValue': [
                    'Contack break',
                    'Contack break2'
                ],
                'backendLabelDopdownValueLP': 'C_V1_P2_RM_FAES',
                'dropDownSelectedValue': 'Contack break',
                'unitArrayValue': [
                    'msec',
                    'msec2'
                ],
                'backendLabelUnitArrayValueLP': 'C_V1_P2_RM2_FAES',
                'unitArraySelectedValue': 'msec',
            }

        },
        {
            'controlName': 'measurement dropdown',
            'heading': 'OPEN Speed #1',
            'upperPoint': {
                'maxvalue': '',
                'backendLabelMaxValueUP': 'O_V1_P1_W1_FAES',
                'minvalue': '10',
                'backendLabelMinValueUP': 'O_V1_P1_W2_FAES',
                'header': 'Upper point',
                'dropDownSelectedValue': '',
                'dropDownArrayValue': [
                    'Contack break',
                    'Contack break'
                ],
                'backendLabelDopdownValueUP': 'O_V1_P1_RM_FAES',
                'unitArrayValue': [
                    'msec',
                    'msec3'
                ],
                'backendLabelUnitArrayValueUP': 'O_V1_P1_RM2_FAES',
                'unitArraySelectedValue': '',
            },
            'lowerPoint': {
                'maxvalue': '',
                'backendLabelMaxValueLP': 'O_V1_P2_W1_FAES',
                'minvalue': '25',
                'backendLabelMinValueLP': 'O_V1_P2_W2_FAES',
                'header': 'Lower point',
                'dropDownArrayValue': [
                    'Contack break',
                    'Contack break3'
                ],
                'backendLabelDopdownValueLP': 'O_V1_P2_RM_FAES',
                'dropDownSelectedValue': '',
                'unitArrayValue': [
                    'msec',
                    'msec4'
                ],
                'backendLabelUnitArrayValueLP': 'O_V1_P2_RM2_FAES',
                'unitArraySelectedValue': '',
            }

        },
        {
            'controlName': 'measurement dropdown',
            'heading': 'OPEN Speed #2',
            'upperPoint': {
                'maxvalue': '70',
                'backendLabelMaxValueUP': 'O_V2_P1_W1_FAES',
                'minvalue': '',
                'backendLabelMinValueUP': 'O_V2_P1_W2_FAES',
                'header': 'Upper point',
                'dropDownSelectedValue': '',
                'dropDownArrayValue': [
                    'Contack make',
                    'Contack make1'
                ],
                'backendLabelDopdownValueUP': 'O_V2_P1_RM_FAES',
                'unitArrayValue': [
                    'mm,grad',
                    'cm,grad'
                ],
                'backendLabelUnitArrayValueUP': 'O_V2_P1_RM2_FAES',
                'unitArraySelectedValue': 'mm,grad',
            },
            'lowerPoint': {
                'maxvalue': '34',
                'backendLabelMaxValueLP': 'O_V2_P2_W1_FAES',
                'minvalue': '',
                'backendLabelMinValueLP': 'O_V2_P2_W2_FAES',
                'header': 'Lower point',
                'dropDownArrayValue': [
                    'Hub [absolute]4',
                    'Hub [absolute]5'
                ],
                'backendLabelDopdownValueLP': 'O_V2_P2_RM_FAES',
                'dropDownSelectedValue': '',
                'unitArrayValue': [
                    'mm,grad',
                    'cm,grad'
                ],
                'backendLabelUnitArrayValueLP': 'O_V2_P2_RM2_FAES',
                'unitArraySelectedValue': '',
            }

        }
    ]

};
