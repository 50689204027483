import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { GlobalURL } from 'src/app/globalModule/globalUrl';
import { SharedService } from 'src/app/globalModule/shared.service';

@Injectable({
  providedIn: 'root'
})
export class ActasActionService {

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  addACTASTemplate(data): Observable<any> {
    const url = GlobalURL.ACTAS_ADD_TEMPLATE;
    return this.http.post(url, data);
  }

  getAvailableActasTemplateList(): Observable<any> {
    const url = GlobalURL.ACTAS_GET_ALL_TEMPLATE_LIST;
    return this.http.get(url);
  }

  getTemplateDetails(templateId): Observable<any> {
    const url = GlobalURL.ACTAS_GET_TEMPLATE_DETAILS + this.sharedService.encodeData(templateId);
    return this.http.get(url);
  }

  updateTemplateDetails(templateId, data): Observable<any> {
    const url = GlobalURL.ACTAS_EDIT_TEMPLATE_DETAILS + this.sharedService.encodeData(templateId);
    return this.http.post(url, data);
  }

  deleteActasTemplate(templateId): Observable<any> {
    const url = GlobalURL.ACTAS_DELETE_TEMPLATE + this.sharedService.encodeData(templateId);
    return this.http.delete(url);
  }

  saveCircuitBreakerTemplate(data): Observable<any> {
    const url = GlobalURL.ACTAS_CIRCUIT_BREAKER_SAVE;
    return this.http.post(url, data);
  }

  getCircuitBreakerConfigurationDetails(data): Observable<any> {
    const url = GlobalURL.ACTAS_GET_CIRCUIT_BREAKER_CONFIGURATION + this.sharedService.encodeData(data.articleNumber) + '/' +
      this.sharedService.encodeData(data.factoryGisNumber) + '/' +
      this.sharedService.encodeData(data.serialNumber);
      // + '?productStepsId=' + data.productStepsId;
    return this.http.get(url);
  }
}
