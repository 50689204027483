import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { Router } from '../../../../node_modules/@angular/router';
import { MsalService, BroadcastService } from '../../../../node_modules/@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { SharedService } from 'src/app/globalModule/shared.service';
import { HomeActionService } from 'src/app/featureModules/home/home-action.service';
import { UserActionService } from 'src/app/featureModules/admin/user/user-action.service';
import { DPS_Roles } from '../user-info';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    dataToDisplay: { clientId: any; clientSecret: any; isCCRP: any; };
    data: any;
    userId: number;
    serialNumber = '';
    CCRPNumber = '';
    suplierGuid = '';
    articleNumber = '';
    isCCRP: any;
    EmailID: string;
    CCRPAppId: string;
    serverError: boolean;
    serverErrorText: any;
    ProductGroup = '';
    loggedInUser: string;
    isUserAllowed = true;
    emailID: string;
    loggedUser: any;
    GlobalMsg: any = {};
    showError = false;
    errorMsg;
    showLoader = false;
    tokenSuccessSubscription: any;
    tokenFailureSubscription: any;
    env;

    constructor(private msalService: MsalService, private authService: AuthenticationService,
        private router: Router, private broadcastService: BroadcastService,
        private sharedService: SharedService, private homeService: HomeActionService,
        private userService: UserActionService) { }


    ngOnInit() {
        this.env = environment.environment;
        this.dataToDisplay = this.authService.getCCRPDetails();
        if (sessionStorage.getItem('Error')) {
            this.showError = true;
            this.errorMsg = sessionStorage.getItem('Error');
            sessionStorage.removeItem('Error');
        } else if (localStorage.getItem('LoggedInEmailId') && localStorage.getItem('msal.idtoken')) {
            this.showLoader = true;
            this.authService.getUserDetails(localStorage.getItem('LoggedInEmailId')).subscribe(Response => {

                if (sessionStorage.getItem('userData')) {
                    // For process production screen to retain the factory settings
                    sessionStorage.setItem('userData', sessionStorage.getItem('userData'));
                } else {
                    sessionStorage.setItem('userData', JSON.stringify(Response));
                }
                setTimeout(() => {
                    const data = JSON.parse(localStorage.getItem('GraphData'));
                    const ServIsInstallationId = sessionStorage.getItem('ServIsInstallationId') !== null ?
                        decodeURI(JSON.parse(sessionStorage.getItem('ServIsInstallationId'))) : undefined;
                    const ServIsAppId = sessionStorage.getItem('ServIsAppId') !== null ?
                        decodeURI(JSON.parse(sessionStorage.getItem('ServIsAppId'))) : undefined;

                    if (this.dataToDisplay.isCCRP === environment.isCCRP || ServIsInstallationId !== undefined
                        && ServIsAppId !== undefined) {
                        this.showLoader = true;

                        this.authService.getUserDetails(data.mail).subscribe(res => {
                            this.showLoader = true;
                            this.redirectUser(data, data.mail);

                        },
                            error => {
                                if (error.status === 404) {
                                    data.mail = 'pg-ch-dps-rediracc@abb.com';
                                    this.redirectUser(data, data.mail);

                                }

                            });

                    } else {
                        const path = sessionStorage.getItem('userUrl');
                        if (path === null) {
                            this.showLoader = true;
                            this.authService.setInitialUsrInfo('', '');

                        } else if (path !== null && path !== undefined &&
                            path === (environment.redirectURi + '/#/login') ||
                            path === (environment.redirectURi + '/#/') ||
                            path === (environment.redirectURi + '/')
                            || path.indexOf('access_token=') > -1
                            || path.indexOf('id_token=') > -1) {
                            this.showLoader = true;
                            this.authService.setInitialUsrInfo('', '');
                            setTimeout(() => {
                                sessionStorage.removeItem('userUrl');
                            }, 2000);
                        } else {
                            const fullPath = path;
                            if (fullPath !== null && fullPath !== undefined) {
                                try {
                                    const path1 = fullPath.split(environment.redirectURi + '/#')[1].
                                        slice(1, fullPath.split(environment.redirectURi + '/#')[1].length);
                                    this.router.navigateByUrl(path1);
                                } catch {
                                    this.router.navigate(['/login']);
                                } setTimeout(() => {
                                    sessionStorage.removeItem('userUrl');
                                }, 2000);
                            }

                        }
                    }
                    this.showLoader = false;
                }, 2000);

            },
                (error) => {
                    const data = JSON.parse(localStorage.getItem('GraphData'));
                    const ServIsInstallationId = sessionStorage.getItem('ServIsInstallationId') !== null ?
                        decodeURI(JSON.parse(sessionStorage.getItem('ServIsInstallationId'))) : undefined;
                    const ServIsAppId = sessionStorage.getItem('ServIsAppId') !== null ?
                        decodeURI(JSON.parse(sessionStorage.getItem('ServIsAppId'))) : undefined;

                    if (this.dataToDisplay.isCCRP === environment.isCCRP || ServIsInstallationId !== undefined
                        && ServIsAppId !== undefined) {
                        this.showLoader = true;
                        data.mail = 'pg-ch-dps-rediracc@abb.com';
                        this.redirectUser(data, data.mail);
                    } else {
                        this.showLoader = false;
                        localStorage.clear();
                        sessionStorage.clear();
                        if (error.status === 404) {
                            this.showError = true;
                            this.errorMsg = this.GlobalMsg.userNotFound;
                        }
                    }
                }
            );
        } else {

            this.tokenFailureSubscription = this.broadcastService.subscribe('msal:loginFailure', (payload) => {
                this.router.navigate(['/login']);
                sessionStorage.clear();
                localStorage.clear();
                this.showError = true;
                this.errorMsg = this.GlobalMsg.inactiveUser;
            });

            this.tokenSuccessSubscription = this.broadcastService.subscribe('msal:loginSuccess', (payload) => {
                this.showLoader = true;

                this.authService.getUserInfoFromGraph().subscribe(data => {
                }, (error) => {
                    // console.log('grahapi-1 error', error);
                });
                setTimeout(() => {
                    this.authService.getUserInfoFromGraph().subscribe(data => {
                        localStorage.setItem('GraphData', JSON.stringify(data));
                        sessionStorage.setItem('LoggedInEmailId', data.userPrincipalName);
                        localStorage.setItem('LoggedInEmailId', data.userPrincipalName);

                        this.sharedService.getGlobalMsgLocale()
                            .subscribe(res => {
                                this.GlobalMsg = JSON.parse(res);
                                this.errorMsg = this.GlobalMsg.errorMsg;
                            }, (err) => {
                                console.log(err);
                            });

                        this.redirectUser(data, data.mail);
                    }, (error) => {
                        console.log(error);
                        this.showError = true;
                        this.errorMsg = this.GlobalMsg.serverFault;
                        this.showLoader = false;
                        sessionStorage.clear();
                        localStorage.clear();
                    });
                }, 3000);

            });
        }
    }

    ngOnDestroy() {
        this.broadcastService.getMSALSubject().next(1);
        if (this.tokenSuccessSubscription) {
            this.tokenSuccessSubscription.unsubscribe();
        }
        if (this.tokenFailureSubscription) {
            this.tokenFailureSubscription.unsubscribe();
        }
    }

    onSubmit() {
        this.dataToDisplay = this.authService.getCCRPDetails();
        if (sessionStorage.getItem('Error')) {
            this.showError = true;
            this.errorMsg = sessionStorage.getItem('Error');
            sessionStorage.clear();
            localStorage.clear();
        } else {
            this.msalService.loginRedirect(environment.scopes);
        }
    }

    redirectUser(data, LoggedInEmailId) {
        this.showLoader = true;
        if (this.isUserAllowed) {
            const ServIsInstallationId = sessionStorage.getItem('ServIsInstallationId') !== null ?
                decodeURI(JSON.parse(sessionStorage.getItem('ServIsInstallationId'))) : undefined;
            const ServIsSerialNo = sessionStorage.getItem('ServIsSerialNo') !== 'undefined' ?
                decodeURI(JSON.parse(sessionStorage.getItem('ServIsSerialNo'))) : 'null';
            const ServIsAppId = sessionStorage.getItem('ServIsAppId') !== null ?
                decodeURI(JSON.parse(sessionStorage.getItem('ServIsAppId'))) : undefined;

            this.authService.getUserDetails(data.mail).subscribe(Response => {
                this.showLoader = true;

                Response.firstName = data.givenName;
                Response.lastName = data.surname;
                sessionStorage.setItem('userData', JSON.stringify(Response));
                localStorage.setItem('userData', JSON.stringify(Response));

                this.loggedUser = Response;
                this.CCRPAppId = JSON.parse(sessionStorage.getItem('CCRPAppId'));

                if (ServIsInstallationId !== undefined && ServIsAppId !== undefined) {
                    const data1 = {
                        'SerialNumber': ServIsSerialNo,
                        'InstallationId': ServIsInstallationId,
                        'ServISAppId': ServIsAppId,
                        'EmailId': data.mail,
                        'SuplierGuid': null,
                        'ArticleNumber': null,
                        'LoggedInUser': LoggedInEmailId
                    };

                    this.homeService.saveServIsDetails(data1).subscribe((Res) => {
                        this.searchSerialNumber(Res, '', '');

                    }, (error) => {
                        this.showLoader = false;
                        this.router.navigate(['/home']);

                    });
                } else if (this.CCRPAppId === environment.CCRPAppId) {

                    this.isCCRP = JSON.parse(sessionStorage.getItem('isCCRP'));
                    if (sessionStorage.getItem('SerialNumber') !== 'undefined') {
                        this.serialNumber = decodeURI(JSON.parse(sessionStorage.getItem('SerialNumber')));
                    }
                    if (sessionStorage.getItem('CCRPNumber') !== 'undefined') {
                        this.CCRPNumber = decodeURI(JSON.parse(sessionStorage.getItem('CCRPNumber')));
                    }
                    if (sessionStorage.getItem('SupplierGuid') !== 'undefined') {
                        this.suplierGuid = decodeURI(JSON.parse(sessionStorage.getItem('SupplierGuid')));
                    }
                    if (sessionStorage.getItem('ArticleNumber') !== 'undefined') {
                        this.articleNumber = decodeURI(JSON.parse(sessionStorage.getItem('ArticleNumber')));
                    }
                    if (sessionStorage.getItem('EmailID') !== 'undefined') {
                        this.EmailID = decodeURI(JSON.parse(sessionStorage.getItem('EmailID')));
                    }
                    if (sessionStorage.getItem('ProductGroup') !== 'undefined') {
                        this.ProductGroup = decodeURI(JSON.parse(sessionStorage.getItem('ProductGroup')));
                    }
                    if (sessionStorage.getItem('CCRPAppId') !== 'undefined') {
                        this.CCRPAppId = decodeURI(JSON.parse(sessionStorage.getItem('CCRPAppId')));
                    }
                    this.loggedInUser = localStorage.getItem('LoggedInEmailId');

                    this.authService.updateLoginDateForUser(Response.userId).subscribe(r1 => {
                        if (this.dataToDisplay.isCCRP === environment.isCCRP) {
                            const data1 = {
                                'SerialNumber': this.serialNumber,
                                'CCRPNumber': this.CCRPNumber === '' ? null : this.CCRPNumber,
                                'CCRPAppId': this.CCRPAppId,
                                'EmailId': this.EmailID,
                                'SuplierGuid': this.suplierGuid === '' ? null : this.suplierGuid,
                                'ArticleNumber': this.articleNumber === '' ? null : this.articleNumber,
                                'ProductGroupID': this.ProductGroup === '' ? null : this.ProductGroup,
                                'LoggedInUser': this.loggedInUser
                            };

                            if (data1.SerialNumber && data1.CCRPNumber && data1.CCRPAppId && data1.ArticleNumber && data1.SuplierGuid) {
                                this.homeService.saveCCRPDetails(data1).subscribe((res) => {
                                    this.showLoader = false;
                                    this.searchSerialNumber(this.serialNumber, this.articleNumber, this.suplierGuid);
                                }, (error) => {
                                    this.showLoader = false;
                                    this.searchSerialNumber(this.serialNumber, this.articleNumber, this.suplierGuid);
                                });
                            } else {
                                this.showLoader = false;
                                this.searchSerialNumber(this.serialNumber, this.articleNumber, this.suplierGuid);
                            }
                        }
                    },
                        (error) => {
                            console.log(error);
                        });
                } else {
                    if (Response.isActive === true) {
                        this.authService.updateLoginDateForUser(Response.userId).subscribe(r1 => {
                            Response.firstName = data.givenName;
                            Response.lastName = data.surname;
                            sessionStorage.setItem('userData', JSON.stringify(Response));
                            localStorage.setItem('userData', JSON.stringify(Response));
                        },
                            (error) => {
                                console.log(error);
                            });
                    } else {
                        this.showLoader = false;
                        this.showError = true;
                        this.errorMsg = this.GlobalMsg.inactiveUser;
                    }
                }
                Response.firstName = data.givenName;
                Response.lastName = data.surname;
                sessionStorage.setItem('userData', JSON.stringify(Response));
                localStorage.setItem('userData', JSON.stringify(Response));
                if (this.dataToDisplay.isCCRP === environment.isCCRP || ServIsInstallationId !== undefined
                    && ServIsSerialNo !== undefined && ServIsAppId !== undefined) {
                    this.authService.setInitialUsrInfo(this.data, data.mail);
                } else {
                    this.authService.setInitialUsrInfo('', '');
                }
            },
                error => {
                    localStorage.clear();
                    if (error.status === 404) {
                        this.errorMsg = this.GlobalMsg.userNotFound;
                    } else if (error.status === 500) {
                        this.errorMsg = this.GlobalMsg.serverFault;
                    } else {

                        this.msalService.acquireTokenRedirect([environment.scopes[1]]);
                    }
                    this.showLoader = false;
                    this.showError = true;
                });
        }
    }


    searchSerialNumber(serialNumber, articleNumber, supplierGUID) {
        this.showLoader = true;
        this.homeService.searchOrderDetails(articleNumber, supplierGUID, serialNumber)
            .subscribe((Response) => {
                this.data = Response;
                if (Response.status === 200) {
                    const searchData = Response.body;
                    {
                        if (searchData.itemType === 'Component') {
                            this.router.navigate([
                                '/home/componentdetail',
                                searchData.orderLineID,
                                searchData.supplierGUID,
                                searchData.serialNumber,
                                searchData.traceabilityID,
                                searchData.articleNumber,
                                '0', '0'
                            ]);
                        } else {
                            // Changes for undefined issue during CCRP redirection
                            this.router.navigate([
                                '/home/productdetail',
                                searchData.articleNumber,
                                searchData.serialNumber,
                                searchData.supplierGUID
                            ], { queryParams: { pFactoryID: searchData.pFactoryID } });
                        }
                    }
                } else if (Response.status === 204) {
                    this.showLoader = false;
                    this.router.navigate(['/home']);
                } else if (Response.status === 202) {
                    this.showLoader = false;
                    sessionStorage.setItem('CCRPData', JSON.stringify(Response.body));
                    this.router.navigate(['/home']);
                } else {
                    this.showLoader = false;
                    this.router.navigate(['/home']);
                }
            });
    }

}

