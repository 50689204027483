
export class AllLocales {

    // Default locale to load
    public static DEFAULT_LOCALE = 'en-US';

    // All tranalated locales
    public static ALL_LOCALE = 'en-US, sv-SE, sv';


    constructor() { }
}
