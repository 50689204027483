import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './globalModule/login/login.component';
import { ActivateRouteGaurd } from './globalModule/authentication/route-gaurd';
import { NotfoundComponent } from './globalModule/notfound/notfound.component';
import { MsalGuard } from '@azure/msal-angular';
import { PrivacyPolicyComponent } from './globalModule/footer/privacy-policy/privacy-policy.component';
import { ServertimeoutComponent } from './globalModule/servertimeout/servertimeout.component';

const routes: Routes = [
  { path: 'orders', loadChildren: './featureModules/supplierOrder/order.module#OrderModule', canActivate: [ActivateRouteGaurd, MsalGuard] },
  { path: 'admin', loadChildren: './featureModules/admin/admin.module#AdminModule', canActivate: [ActivateRouteGaurd, MsalGuard] },
  { path: 'home', loadChildren: './featureModules/home/home.module#HomeModule', canActivate: [ActivateRouteGaurd, MsalGuard] },
  {
    path: 'customer', loadChildren: './featureModules/customer/customer.module#CustomerModule',
    canActivate: [ActivateRouteGaurd, MsalGuard]
  },
  { path: 'login', component: LoginComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'forbidden', component: NotfoundComponent },
  { path: 'serverError', component: ServertimeoutComponent },
  { path: 'access_token', redirectTo: '/login', pathMatch: 'full' },
  { path: 'id_token', redirectTo: '/home', pathMatch: 'full' },
  { path: '', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
