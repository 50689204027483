export class GlobalInsights {
    public static AppLoaded = 'Application Loaded';
    public static Message = 'Message';
    public static pageOrderView = 'appInsightOrderDetailPage';
    public static pageHomeView = 'appInsightOrderHomePage';
    public static pageListView = 'appInsightOrderListPage';
    public static pageBarcodeView = 'appInsightOrderBarcodePage';
    public static traceabilityList = 'Traceability list data';
    public static componentDetail = 'Component details';
    public static getSupplier = 'Get supplier details';
    public static getFactory = 'Get factory details';
    public static updateComponentDetail = 'Component detail updated';
    public static addComponent = 'Component added';
    public static componentList = 'Get Component list';
    public static customerInfo = 'Get Customer Information';
    public static saveCustomer = 'Save Customer Information';
    public static customerList = 'Customer list data';
    public static factoryList = 'Factory list data';
    public static editFactoryList = 'Edit factory list';
    public static addFactory = 'Add Factory';
    public static updateFactory = 'Update Factory';
    public static getStepData = 'Get step data';
    public static getAllSteps = 'Get All Step data';
    public static editStepData = 'Edit Step data';
    public static addStepData = 'Add Step data';
    public static validateProduct = 'Validate Product data';
    public static deleteStepData = 'Delete step data';
    public static getRegisterdProductList = 'Get registered product list';
    public static productList = 'Product list data';
    public static addProductToFactory = 'Product added to factory';
    public static shareConfiguration = 'Share Configuration';
    public static removeProduct = 'Remove Product';
    public static categoryList = 'Category list data';
    public static addProduct = 'Product added';
    public static editProduct = 'Product edited';
    public static supplierData = 'Get supplier Data';
    public static saveSupplier = 'Save supplier Data';
    public static supplierDetailInfo = 'Get supplier details';
    public static supplierList = 'Get Supplier List';
    public static getUserData = 'Get User data';
    public static saveUserData = 'Save User data';
    public static getUserDetails = 'Get User details data';
    public static editUserDetails = 'Edit User detail';
    public static userInfo = 'User Info List';
}
