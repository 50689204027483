import { Directive, HostBinding, HostListener, Output, EventEmitter} from '@angular/core';

@Directive({
  selector: '[appDragdrop]',
  exportAs: 'dragdrop'
})
export class DragdropDirective {

  @HostBinding('style.background') private background = '#eee';

  constructor() {  }

  fileStore: any;

  @Output() private filesUploadedEmiter: EventEmitter<any> = new EventEmitter();

  @HostListener('dragover', ['$event']) public onDragOver(evt) {

    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#999';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#eee';
  }

  @HostListener('drop', ['$event']) public onDrop(evt) {

    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#eee';
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.filesUploadedEmiter.emit(evt);
    }
  }


}
